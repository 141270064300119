<script>
export default {
  name: 'FooterComponent'
}
</script>

<template>
  <div>
    <div class="footer-bg"></div>
    <div style="position: relative; z-index: 1; width: 100%; background-color: white;">
      <div style="min-height: 250px; align-self: center;">
        <iframe id="mailjetIframe" frameborder="0" scrolling="no" src="https://app.mailjet.com/widget/iframe/4B8W/jOa"
                width="100%" height="100%" class="mj-w-res-iframe"
                style="min-height: 250px; overflow: hidden; height: 450px;">
        </iframe>
      </div>
    </div>
    <div style="width: 100%;box-shadow: gray 1px 3px 5px; height: 0.5px;"></div>
    <div class="container">
      <div class="container-half-circle">
        <div class="half-circle">
          <img src="@/views/wedding-provider/assets/images/logo.png" class="half-circle-logo" alt="" />
        </div>
      </div>

      <div class="container-menu-1">
        <ul class="container-horizontal-list">
          <li>ACCUEIL</li>
          <li>MAIRIES</li>
          <li>EVENT PLANNER</li>
          <li>JOY CARDS</li>
          <li>BLOG</li>
          <li>CONTACTS</li>
        </ul>
      </div>

      <div class="container-description">
        Jours de Joie, votre Digital Event Planner est l'assistant idéal
        pour organiser sereinement votre mariage. Plus besoin de vous
        déplacer, avec nos informations,nos prestataires de mariage et d'évenement heureux, nos conseils pratiques et nos
        offres de services, nous répondons à vos besoins en un clic.
      </div>

      <div class="container-menu-2">
        <ul class="container-horizontal-list">
          <li>CGU & POLITIQUE DE CONFIDENTIALITÉ</li>
          <li>FOIRES AUX QUESTIONS (F.A.Q)</li>
          <li>JOY VISIBILITY (OFFRE PROMOTIONNELLE)</li>
        </ul>
      </div>

      <div class="container-contact">
        <div class="container-contact-child">
          <font-awesome-layers class="fa-2x">
            <font-awesome-icon icon="envelope" class="_icon" />
          </font-awesome-layers>&nbsp;&nbsp;
          <span>info@joursdejoie.com</span>
        </div>
        <div class="container-contact-child">
          <font-awesome-layers class="fa-2x">
            <font-awesome-icon icon="mobile-alt" class="_icon" />
          </font-awesome-layers>&nbsp;&nbsp;
          <span>(+225) 75 44 73 73</span>
        </div>
        <div class="container-contact-child">
          <img src="@/views/wedding-provider/assets/images/facebook-icon.png" class="social-network-icon" alt="">
          <img src="@/views/wedding-provider/assets/images/instagram-icon.png" class="social-network-icon" alt="">
          <img src="@/views/wedding-provider/assets/images/youtube-icon.png" class="social-network-icon" alt="">
        </div>
      </div>

      <div class="container-copyright">
        Copyright © 2019. Tous droits reservés | Make with by
        <font-awesome-icon icon="heart" class="_icon" />
        Paraclet.
      </div>
    </div>
  </div>
</template>

<style scoped>
.footer-bg {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url('@/views/wedding-provider/assets/images/home.png');
  background-size: cover;
  background-repeat: repeat;
  opacity: .1;
}

._icon {
  color: rgb(183 28 28 / 100%);
}

.container {
  width: 100%;
  margin: 0 auto;
}

.container-half-circle {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding-bottom: 1.25rem;
}

.half-circle {
  height: 190px;
  width: 380px;
  background-color: #fff;
  border-bottom-left-radius: 200px;
  border-bottom-right-radius: 200px;
  box-shadow: 1px 15px 15px 0 #e2e2e2;
  position: relative;
  top: -50px;
}

.half-circle-logo {
  padding-top: 3rem;
  margin-left: auto;
  margin-right: auto;
  width: 200px;
}

.container-menu-1, .container-menu-2 {
  display: table;
  margin: 0 auto;
}

.container-horizontal-list {
  list-style: none;
  padding-top: 20px;
}

.container-menu-1>.container-horizontal-list li {
  display: inline;
  text-transform: uppercase;
  font-weight: 700;
  padding: 0 1.75rem;
}

.container-menu-2>.container-horizontal-list li {
  display: inline;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  padding: 0 1rem;
}

.container-contact {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 25px 0;
}

.container-contact-child {
  display: flex;
  padding: 0 15px;
  align-items: center;
}

.social-network-icon {
  height: 30px;
  width: auto;
  padding: 0 2.5px;
}

.container-description {
  text-align: center;
  padding: 1.25rem 23rem;
}

.container-copyright {
  text-align: center;
  padding-bottom: 15px;
}

@media screen and (max-width: 992px) {
  .container-menu-1, .container-menu-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    text-align: center;
  }

  .container-description {
    padding: 1.25rem 3.5rem;
  }

  .container-menu-2 > .container-horizontal-list > li {
    display: flex;
    flex-direction: column;
    padding: 0.25rem 0;
  }

  .container-contact {
    display: flex;
    flex-direction: column;
  }

  .container-contact-child {
    display: flex;
    padding: 0.25rem 0;
    align-items: center;
  }
}
</style>

