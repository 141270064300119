<template>
  <div
    v-if="slides.length == 0"
    id="carouselExampleIndicators"
    class="carousel slide"
    data-ride="carousel"
  >
    <ol class="carousel-indicators">
      <li
        data-target="#carouselExampleIndicators"
        data-slide-to="0"
        class="active"
      ></li>
    </ol>
    <div class="carousel-inner mt-70">
      <div class="carousel-item pr-0 pl-0 active">
        <div class="image-container">
          <img
            class="d-block w-100"
            src="/images/home/sliders/slide1.jpg"
            alt="Votre partenaire idéal pour un mariage réussi."
          />
        </div>
        <div class="carousel-caption d-none d-md-block">
          <h5>Jours de joie</h5>
          <p>
            Votre partenaire idéal pour un mariage réussi.
          </p>
          <div class="pb-3">
            <button
              @click="$router.push('/nuptial-notebook/marrieds')"
              class="primary-button"
            >
              CARNET NUPTIAL</button>&nbsp;
            <button @click="$router.push('/welcome')" class="secondary-button">
              VOIR PLUS
            </button>
          </div>
        </div>
      </div>
    </div>
    <a
      class="carousel-control-prev"
      href="#carouselExampleIndicators"
      role="button"
      data-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a
      class="carousel-control-next"
      href="#carouselExampleIndicators"
      role="button"
      data-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
  <div
    v-else
    id="carouselExampleIndicators"
    class="carousel slide"
    data-ride="carousel"
  >
    <ol class="carousel-indicators">
      <li
        v-for="(slide, key) in slides"
        :key="key"
        data-target="#carouselExampleIndicators"
        :data-slide-to="key"
        :class="key == 0 ? 'active' : ''"
      ></li>
    </ol>
    <div class="carousel-inner mt-70">
      <div
        v-for="(slide, key) in slides"
        :key="key"
        :class="
          key == 0
            ? 'carousel-item pr-0 pl-0 active'
            : 'carousel-item pr-0 pl-0'
        "
      >
        <div class="image-container">
          <img
            class="d-block w-100"
            :src="url + slide.image"
            alt="Votre partenaire idéal pour un mariage réussi."
          />
        </div>
        <div class="carousel-caption d-none d-md-block">
          <h5 style="color:#fff;" v-if="slide.description !== 'PUB'">
            {{ slide.title }}
          </h5>
          <p v-if="slide.description !== 'PUB'">
            {{ slide.description }}
          </p>
          <div class="pb-3" v-if="slide.btn1_text !== '' && slide.btn1_text !== null && slide.btn1_text !== 'null'">
            <button class="primary-button" @click="sliderInfo(slide)">
              {{ slide.btn1_text }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <a
      class="carousel-control-prev"
      href="#carouselExampleIndicators"
      role="button"
      data-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a
      class="carousel-control-next"
      href="#carouselExampleIndicators"
      role="button"
      data-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
</template>

<script>
import { CONTACT } from "@/config";
export default {
  name: "SliderComponent",
  props: ["slides"],
  data: function() {
    return {
      url: CONTACT.DEV_URL
    };
  },
  methods: {
    sliderInfo(slide) {
      this.$ua.trackEvent("Bannière", "Clique", "PUB - " + slide.title, null);
      window.location.href = slide.btn1_link;
    }
  }
};
</script>

<style scoped>
.image-container {
  position: relative;
}

.image-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  z-index: 1;
}

.carousel-caption {
  z-index: 2; 
}

.carousel-control-prev,
.carousel-control-next {
  z-index: 3; 
}

.carousel-indicators {
  z-index: 3; 
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.primary-button {
  border: none;
  padding: 10px 20px 10px 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  width: 250px;
  height: 70px;
  box-shadow: 0 0 17px rgba(126, 126, 126, 0.5);
  animation: bounce 2s infinite;
}
</style>