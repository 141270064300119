<template>
  <div class="container">
    <div v-if="!isLoading">
      <div class="header-section">
        <h2 class="main-title">INFORMATIONS INVITÉ</h2>
        <button class="add-invite-btn" @click="addGuestRow">
          <i style="color: white" class="fa fa-plus"></i> AJOUTER UN INVITÉ
        </button>
      </div>

      <div
        style="box-shadow: 2px 2px 93px -2px rgba(232, 232, 232, 0.75)"
        v-for="(guest, key) in guestsForms"
        :key="key"
        class="guest-form-container"
      >
        <div class="form-header">
          <button class="remove-btn" @click="deleteGuestRow(key)">
            <i style="color: #fff" class="fa fa-times"></i>
          </button>
        </div>



        <div class="form-row">
          
          <div class="form-group">
            <label>Civilité <span class="required">*</span></label>
            <select-input
              :name="'civility' + key"
              type="text"
              :rules="'required'"
              :isRequired="true"
              :options="civilities"
              v-model="guestsForms[key].civility"
              placeholder="Sélectionnez"
              class="form-control"
              :class="{ error: formErrors && !guestsForms[key].civility }"
            />
            <span
              v-if="formErrors && !guestsForms[key].civility"
              class="error-message"
              >Ce champ est requis</span
            >
          </div>
          <div class="form-group">
            <label>Nom <span class="required">*</span></label>
            <text-input
              :name="'last_name' + key"
              type="text"
              :rules="'required'"
              :isRequired="true"
              v-model="guestsForms[key].last_name"
              placeholder="Entrez le nom"
              class="form-control"
              :class="{ error: formErrors && !guestsForms[key].last_name }"
            />
            <span
              v-if="formErrors && !guestsForms[key].last_name"
              class="error-message"
              >Ce champ est requis</span
            >
          </div>
        </div>

        <div class="form-row">
          <div class="form-group">
            <label>Prénoms <span class="required">*</span></label>
            <text-input
              :name="'given_names' + key"
              type="text"
              :rules="'required'"
              :isRequired="true"
              v-model="guestsForms[key].given_names"
              placeholder="Entrez le prenom"
              class="form-control"
              :class="{ error: formErrors && !guestsForms[key].given_names }"
            />
            <span
              v-if="formErrors && !guestsForms[key].given_names"
              class="error-message"
              >Ce champ est requis</span
            >
          </div>
          <div class="form-group">
            <label>Email <span class="required">*</span></label>
            <text-input
              :name="'email' + key"
              type="email"
              :rules="'email'"
              :isRequired="true"
              v-model="guestsForms[key].email"
              placeholder="Email"
              class="form-control"
              :class="{ error: formErrors && !guestsForms[key].email }"
            />
            <span
              v-if="formErrors && !guestsForms[key].email"
              class="error-message"
              >Ce champ est requis</span
            >
          </div>
        </div>

        <div class="form-row">
          <div class="form-group">
            <label
              >N° de téléphone principal <span class="required">*</span></label
            >
            <masked-input
              :name="'phone_number_1' + key"
              type="text"
              :rules="'required|min:18|max:22'"
              :isRequired="true"
              v-model="guestsForms[key].phone_number_1"
              placeholder="(+225) 00 00 00 00"
              :mask="'+{(000)} 00 00 00 00 00'"
              class="form-control"
              :class="{ error: formErrors && !guestsForms[key].phone_number_1 }"
            />
            <span
              v-if="formErrors && !guestsForms[key].phone_number_1"
              class="error-message"
              >Ce champ est requis</span
            >
          </div>
          <div class="form-group">
            <label
              >N° de téléphone secondaire <span class="required">*</span></label
            >
            <masked-input
              :name="'phone_number_2' + key"
              type="text"
              :rules="'min:18|max:22'"
              :isRequired="true"
              v-model="guestsForms[key].phone_number_2"
              placeholder="(+225) 00 00 00 00"
              :mask="'+{(000)} 00 00 00 00 00'"
              class="form-control"
              :class="{ error: formErrors && !guestsForms[key].phone_number_2 }"
            />
            <span
              v-if="formErrors && !guestsForms[key].phone_number_2"
              class="error-message"
              >Ce champ est requis</span
            >
          </div>
        </div>

        <div class="form-row">
          
          <div class="form-group">
            <label>Statut <span class="required">*</span></label>
            <select-input
              :name="'status' + key"
              type="text"
              :rules="'required'"
              :isRequired="true"
              :options="guests"
              v-model="guestsForms[key].guest_id"
              placeholder="Sélectionnez"
              class="form-control"
              :class="{ error: formErrors && !guestsForms[key].guest_id }"
            />
            <span
              v-if="formErrors && !guestsForms[key].guest_id"
              class="error-message"
              >Ce champ est requis</span
            >
          </div>
          <div class="form-group">
            <!-- <label>Type d'évènement <span class="required">*</span></label>
            <text-input
              :name="'last_name' + key"
              type="text"
              :rules="'required'"
              :isRequired="true"
              v-model="guestsForms[key].last_name"
              placeholder="Entrez le nom"
              class="form-control"
              :class="{ error: formErrors && !guestsForms[key].last_name }"
            />
            <span
              v-if="formErrors && !guestsForms[key].last_name"
              class="error-message"
              >Ce champ est requis</span
            > -->
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <spinner />
    </div>

    <div class="action-buttons">
      <button class="btn-retour" @click="goToBack">
        <i class="fa fa-arrow-left"></i> Retour
      </button>
      <button class="btn-annuler" @click="cancel">Annuler</button>
      <button class="btn-enregistrer" @click="store">Enregistrer</button>
    </div>
  </div>
</template>

<script>
import { API_PREFIX, API_VERSION, NUPTIAL_NOTEBOOK } from "@/config";
import axios from "axios";
import { IMaskDirective } from "vue-imask";
import { useMeta } from "vue-meta";

export default {
  name: "GuestsCreate",
  setup() {
    useMeta({
      title:
        "Création d'un invité - Carnet Nuptial - Invités - Établissez en toute quiétude, la liste des invités de votre mariage",
      titleTemplate: "%s | Jours de Joie",
      htmlAttrs: {
        lang: "fr",
        amp: true,
      },
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content: "Comment faire sa liste d'invités à mon mariage",
        },
        {
          name: "keywords",
          content:
            "Mariage en Côte d'Ivoire, organisation de mariage, prestataire de mariage,combien d'invités à mon mariage, comment faire sa liste d'invités, agencement des invités dans la salle de réception, grand espace évènementiel, carnet nuptial, hôtesses, plan de table de mariage,",
        },
      ],
    });
  },
  data: function () {
    return {
      mask: {
        mask: "+{(225)} 00 00 00 00 00",
        lazy: true,
      },
      guest: {
        event_id: this.$route.params.eventId,
        guest_id: null,
        civility: null,
        last_name: null,
        given_names: null,
        email: null,
        phone_number_1: null,
        phone_number_2: null,
        has_confirmed: null,
      },
      civilities: [
        {
          id: "Monsieur",
          name: "Monsieur",
        },
        {
          id: "Madame",
          name: "Madame",
        },
        {
          id: "Mademoiselle",
          name: "Mademoiselle",
        },
      ],
      guests: null,
      guestsForms: [],
      isLoading: false,
      formErrors: false,
    };
  },
  mounted: function () {
    this.getGuests();
  },
  directives: {
    imask: IMaskDirective,
  },
  methods: {
    onAccept(e) {
      const maskRef = e.detail;
      this.value = maskRef.value;
    },
    onComplete(e) {
      const maskRef = e.detail;
    },
    goToBack: function () {
      this.$router.push(
        "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/invites-pour-le-mariage"
      );
    },
    cancel: function () {
      this.$router.go();
    },
    updatedPercentage(customerId) {
      axios
        .get(
          NUPTIAL_NOTEBOOK.DEV_URL +
            API_PREFIX +
            API_VERSION +
            "percentage/" +
            customerId,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {})
        .catch((error) => {
          console.log("Error percentage", error);
        });
    },
    archivated: function (customerId) {
      var app = this;
      this.$store
        .dispatch("archiveAccount", customerId)
        .then((response) => {})
        .catch((error) => {});
    },
    validateForm() {
      let isValid = true;

      for (let i = 0; i < this.guestsForms.length; i++) {
        const guest = this.guestsForms[i];
        if (
          !guest.civility ||
          !guest.last_name ||
          !guest.given_names ||
          !guest.email ||
          !guest.phone_number_1 ||
          !guest.phone_number_2
        ) {
          isValid = false;
          break;
        }
      }

      return isValid;
    },
    store: function () {
      let app = this;

      // Validate form before submission
      if (!this.validateForm()) {
        this.formErrors = true;

        this.$createToast(
          {
            title: "Attention !",
            description: "Veuillez remplir tous les champs obligatoires !",
          },
          {
            position: "top-center",
            type: "error",
            timeout: 5000,
            transition: "bounce",
          }
        );

        return;
      }

      this.isLoading = true;
      axios
        .post(
          NUPTIAL_NOTEBOOK.DEV_URL +
            API_PREFIX +
            API_VERSION +
            "guests-events-store",
          this.guestsForms,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          this.isLoading = false;
          app.updatedPercentage(response.data.customer_id);
          app.archivated(response.data.customer_id);
          this.$router.push(
            "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/invites-pour-le-mariage"
          );

          this.$createToast(
            {
              title: "Operation reussie !",
              description: "Informations ajouté avec succès !",
            },
            {
              position: "top-center",
              type: "success",
              timeout: 10000,
              transition: "bounce",
            }
          );
        })
        .catch((error) => {
          console.log(error.response.data);
          this.isLoading = false;
          this.$createToast(
            {
              title: "Attention !",
              description: "Erreur lors de l'ajout de l'invité !",
            },
            {
              position: "top-center",
              type: "error",
              timeout: 10000,
              transition: "bounce",
            }
          );
        });
    },
    addGuestRow: function () {
      this.guestsForms.push({
        event_id: this.$route.params.eventId,
        guest_id: null,
        civility: null,
        last_name: null,
        given_names: null,
        email: null,
        phone_number_1: null,
        phone_number_2: null,
      });
    },
    deleteGuestRow: function (key) {
      this.guestsForms.splice(key, 1);
    },
    getGuests: function () {
      this.isLoading = true;
      axios
        .get(NUPTIAL_NOTEBOOK.DEV_URL + API_PREFIX + API_VERSION + "guests", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          this.guests = response.data;
          this.addGuestRow();
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.header-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 20px;
}

.main-title {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
}

.add-invite-btn {
  background-color: #e94057;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.add-invite-btn i {
  margin-right: 8px;
}

.guest-form-container {
  background-color: white;
  border-radius: 4px;
  margin-bottom: 25px;
  padding: 20px;
  top: 28px;
  position: relative;
}

.form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.form-title {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

.remove-btn {
  background-color: #e94057;
  color: white;
  outline: none;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px 15px;
}

.form-group {
  flex: 1;
  min-width: 250px;
  padding: 0 10px;
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 14px;
}

.required {
  color: #e94057;
}

.form-control {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.form-control.error {
  border-color: #e94057;
}

.error-message {
  color: #e94057;
  font-size: 12px;
  margin-top: 5px;
  display: block;
}

.action-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 60px;
}

.btn-retour {
  background-color: white;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.btn-retour i {
  margin-right: 8px;
}

.btn-annuler {
  background-color: white;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
}

.btn-enregistrer {
  background-color: #e94057;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

@media (max-width: 768px) {
  .form-group {
    flex: 100%;
  }

  .action-buttons {
    flex-direction: column;
  }

  .btn-retour,
  .btn-annuler,
  .btn-enregistrer {
    width: 100%;
    margin-bottom: 10px;
  }
}
</style>
