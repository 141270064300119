<template>
  <div class="flex flex-col md:flex-row justify-between w-full">
    <div style="background-color: #F5F5F5;" class="flex flex-col md:flex-row items-center mr-5 px-3 py-2 rounded-lg">
      <span class="text-lg md:text-base pr-4 md:pr-4">{{ label }}</span>
      <span class="text-xl md:text-xl font-semibold" v-if="Number(value) > 0">{{ formatPrice(value) }} FCFA</span>
      <span v-else>Non spécifié</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "price-item",
  props: ['label', 'value'],
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(0).replace(' ', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
  }
}
</script>

<style scoped>
/* Vous pouvez ajouter des styles personnalisés ici si nécessaire */
</style>