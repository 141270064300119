<template>
    <div>
        <div class="form-group">
            <label class="control-label">{{ label }} <span v-show="isRequired" class="text-danger">*</span></label>
            <textarea
                :value="modelValue" @input="$emit('update:modelValue', $event.target.value)"
                class="form-control" 
                 :name="name" 
                :cols="cols" rows="5"
            ></textarea>
            <!-- <small class="invalid-feedback">{{ errors.first(name) }}</small> -->
        </div>
    </div>
</template>

<script>
    export default {
        name: 'textareaInput',
        props: ['label', 'name', 'modelValue', 'rules', 'isRequired', 'type','cols']
    }
</script>
