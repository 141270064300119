<template>
    <div
      class="py-2 flex flex-col w-full text-sm px-1 border-red-800 border-b-2 py-2"
    >
      <div class="py-2 flex flex-col w-full text-sm px-1">
        <button
          @click="isDisplay.filter_services = !isDisplay.filter_services"
          class="flex justify-between filter-header bg-white border-b pb-2 items-center cursor-pointer"
        >
          <span>Categories de services</span>
          <i
            :class="isDisplay.filter_services ? 'fa-angle-up' : 'fa-angle-down'"
            class="text-gray-600 fa"
          ></i>
        </button>
        <div
          class="filter-content collapse show"
          id="collapse_1"
          v-if="isDisplay.filter_services"
          style=""
        >
          <div class="py-1 " v-for="(event, index) in events">
            <button class="text-left bg-white" @click="filterServices(event)">
              <span class="hover:text-red">{{event.name}}</span>
              <i
                class="fa fa-close"
                v-if="this.filter.service_type_id.includes(event.id)"
              ></i>
            </button>
          </div>
          <!-- card-body.// -->
        </div>
      </div>
    </div>

    <div
      class="py-2 flex flex-col w-full text-sm px-1 border-red-800 border-b-2 py-2"
    >
      <div class="py-2 flex flex-col w-full text-sm px-1">
        <button
          @click="isDisplay.filter_event = !isDisplay.filter_event"
          class="flex justify-between filter-header bg-white border-b pb-2 items-center cursor-pointer"
        >
          <span>Evenements</span>
          <i
            :class="isDisplay.filter_event ? 'fa-angle-up' : 'fa-angle-down'"
            class="text-gray-600 fa"
          ></i>
          <!-- <i class="icon-control fa fa-arrow-up"></i> -->
        </button>
        <div
          class="filter-content collapse show"
          v-if="isDisplay.filter_event"
          style=""
        >
          <div class="py-1 hover:text-red" v-for="(cat, index) in categories">
            <button class="text-left bg-white" @click="filterEvents(cat)">
              {{ cat.name }}
              <i
                class="fa fa-close"
                v-if="this.filter.event_type_id.includes(cat.id)"
              ></i>
            </button>
          </div>
          <!-- card-body.// -->
        </div>
      </div>
    </div>

    <div
      class="py-2 flex flex-col w-full text-sm px-1 border-red-800 border-b-2 py-2"
    >
      <div class="py-2 flex flex-col w-full text-sm px-1">
        <button
          @click="isDisplay.price = !isDisplay.price"
          class="flex justify-between filter-header bg-white border-b pb-2 items-center cursor-pointer"
        >
          <span >Prix</span>
          <i
            :class="isDisplay.price ? 'fa-angle-up' : 'fa-angle-down'"
            class="text-gray-600 fa"
          ></i>
        </button>
        <div
          class="filter-content collapse show"
          id="collapse_1"
          v-if="isDisplay.price"
          style=""
        >
          <div class="py-1 hover:text-red-900">
            <div class="form-row">
              <div class="form-group col-md-6">
                <label>Min</label>
                <input
                  class="form-control"
                  placeholder="0"
                  type="number"
                  v-model="filter.min_price"
                />
              </div>
              <div class="form-group text-right col-md-6">
                <label>Max</label>
                <input
                  class="form-control"
                  placeholder="10000000"
                  type="number"
                  v-model="filter.max_price"
                />
              </div>
            </div>
            <button class="btn btn-block btn-primary" @click="applyFilter">
              Appliquer
            </button>
          </div>
          <!-- card-body.// -->
        </div>
      </div>
    </div>

    <!-- <button class="btn btn-block btn-primary" @click="applyFilter">
        Appliquer
      </button> -->
</template>
<script>
import { API_PREFIX, API_VERSION, PROVIDER } from "@/config";
export default {
  // name: '',
  components: {
    // 'filter-template': FilterTemplate
  },
  data() {
    return {
      events: null,
      categories: null,
      priceRange: null,
      filter: {
        event_type_id: [],
        service_type_id: [],
        min_price: null,
        max_price: null,
      },
      isDisplay: {
        filter_services: false,
        filter_event: false,
        filter_price: false,
      },
    };
  },
  mounted() {
    this.getEvents();
    this.getCategories();
  },
  methods: {
    getEvents() {
      let url = "wedding-provider-service-types";
      this.isLoading = true;
      let app = this;
      this.$http
        .get(PROVIDER.DEV_URL + API_PREFIX + API_VERSION + url)
        .then((response) => {
          this.events = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log("error", error.response.data);
          this.isLoading = false;
        });
    },
    getCategories() {
      let url = "get-event-types";
      let app = this;
      this.$http
        .get(PROVIDER.DEV_URL + API_PREFIX + API_VERSION + url)
        .then((response) => {
          this.categories = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log("error", error.response.data);
          this.isLoading = false;
        });
    },
    applyFilter() {
      this.$emit("filter-group", {
        event_type_id: JSON.stringify(this.filter.event_type_id),
        service_type_id: JSON.stringify(this.filter.service_type_id),
        min_price: this.filter.min_price,
        max_price: this.filter.max_price,
      });
    },
    toggleDisplay(index) {
      console.log(this.isDisplay[index]);
      this.isDisplay[index] = !this.isDisplay[index];
    },
    filterServices(cat) {
      if (this.filter.service_type_id.includes(cat.id)) {
        this.filter.service_type_id.splice(
          this.filter.service_type_id.indexOf(cat.id),
          1
        );
      } else {
        this.filter.service_type_id.push(cat.id);
      }
      console.log(this.filter.service_type_id);
      this.applyFilter();
    },
    filterEvents(cat) {
      if (this.filter.event_type_id.includes(cat.id)) {
        this.filter.event_type_id.splice(
          this.filter.event_type_id.indexOf(cat.id),
          1
        );
      } else {
        this.filter.event_type_id.push(cat.id);
      }
      console.log(this.filter.event_type_id);
      this.applyFilter();
    },
  },
};
</script>
<style></style>
