import { getProvidersAPI, getProviderAPI,makeBookingPackage } from "@/views/wedding-provider/apis";

export const state = () => ({
    providers: {},
    provider: {},
    isLoading: false,
    errors: {},
    currentServiceTypeIdSelected:null,
    currentTownIdSelected:null
})

export const actions = {
    async getProviders({ commit }, params = {}) {
        commit('setLoading', true);
        try {

            const response = await getProvidersAPI(params);
            console.log(response)
            commit('setLoading', false);
            commit('setProviders', response);
            if (params.service_type_id) {
                commit('currentServiceTypeIdSelected',params.service_type_id);
            }else{
                commit('currentServiceTypeIdSelected',null);
            }

            if (params.town_id) {
                commit('currentTownIdSelected', params.town_id);
            }else{
                commit('currentTownIdSelected', null);
            }
            
            
        } catch (e) {
            console.log('response incorrect', e)
            commit('setLoading', false);
            commit('setProviders', null);
            commit('setErrors', e.response);
            throw new Error(e)
        }
    },
    async getProvider({ commit }, { id }) {
        commit('setLoading', true);
        try {
            const response = await getProviderAPI(id);
            
            commit('setLoading', false);
            commit('setProvider', response.data);

        } catch (e) {
            console.log('response')
            commit('setLoading', false);
            commit('setProvider', null);
            commit('setErrors', e.response);
            throw new Error(e)
        }
    },
    async makeBookingPackage({commit},bookingPackage){
        commit('setLoading', true);
        try {
            const response = await makeBookingPackage(bookingPackage);
            return response;
            commit('setLoading', false);

        } catch (e) {
            console.log('response')
            commit('setLoading', false);
            commit('setErrors', e.response);
            throw new Error(e)
        }

    }
    
}

export const mutations = {
    setProviders(state, value) {
        state.providers = value
    },
    setLoading(state, value) {
        state.isLoading = value
    },
    setErrors(state, value) {
        state.errors = value
    },
    setProvider(state, value) {
        state.provider = value
    },
    currentServiceTypeIdSelected(state, value) {
        state.currentServiceTypeIdSelected = value
    },
    currentTownIdSelected(state, value) {
        state.currentTownIdSelected = value
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
}