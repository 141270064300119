<template>
    <div class="otp-container" :class="{ 'is-complete': isComplete }">
      <label v-if="label" :for="`otp-input-${_uid}-0`" class="otp-label">{{ label }}</label>
      <div class="otp-input-group">
        <div 
          v-for="(digit, index) in otpArray" 
          :key="index" 
          class="otp-input-wrapper"
        >
          <input
            :id="`otp-input-${_uid}-${index}`"
            ref="inputRefs"
            v-model="otpArray[index]"
            type="text"
            inputmode="numeric"
            maxlength="1"
            class="otp-input"
            :class="{ 
              'is-filled': otpArray[index], 
              'is-active': activeIndex === index 
            }"
            @input="handleInput(index)"
            @keydown="handleKeyDown($event, index)"
            @focus="activeIndex = index"
            @blur="activeIndex = -1"
            :disabled="disabled"
            :aria-label="`Digit ${index + 1} of ${length}`"
          />
        </div>
      </div>
      <p v-if="error" class="otp-error">{{ error }}</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'OtpInput',
    props: {
      length: {
        type: Number,
        default: 6,
        validator: (value) => value > 0
      },
      value: {
        type: String,
        default: ''
      },
      label: {
        type: String,
        default: ''
      },
      disabled: {
        type: Boolean,
        default: false
      },
      error: {
        type: String,
        default: ''
      },
      autoFocus: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        otpArray: Array(this.length).fill(''),
        activeIndex: -1
      }
    },
    computed: {
      isComplete() {
        return this.otpArray.every(digit => digit !== '') && this.otpArray.length === this.length
      },
      otpValue() {
        return this.otpArray.join('')
      }
    },
    watch: {
      value: {
        immediate: true,
        handler(newValue) {
          if (newValue) {
            const valueArray = newValue.split('').slice(0, this.length)
            this.otpArray = Array(this.length).fill('').map((_, index) => valueArray[index] || '')
          }
        }
      },
      otpValue: {
        handler(newValue) {
          this.$emit('input', newValue)
          this.$emit('update:modelValue', newValue)
          
          if (this.isComplete) {
            this.$emit('complete', newValue)
          }
        }
      },
      length: {
        handler(newLength) {
          // Adjust array size when length prop changes
          if (newLength > this.otpArray.length) {
            this.otpArray = [...this.otpArray, ...Array(newLength - this.otpArray.length).fill('')]
          } else if (newLength < this.otpArray.length) {
            this.otpArray = this.otpArray.slice(0, newLength)
          }
        }
      }
    },
    mounted() {
      if (this.autoFocus && !this.disabled) {
        this.$nextTick(() => {
          this.focusInput(0)
        })
      }
    },
    methods: {
      handleInput(index) {
        // Ensure only numeric input
        this.otpArray[index] = this.otpArray[index].replace(/[^0-9]/g, '')
        
        // Move to next input if current one is filled
        if (this.otpArray[index] && index < this.length - 1) {
          this.focusInput(index + 1)
        }
      },
      handleKeyDown(event, index) {
        switch (event.key) {
          case 'Backspace':
            if (!this.otpArray[index] && index > 0) {
              // If current field is empty and backspace is pressed, move to previous field
              this.focusInput(index - 1)
              // Optionally clear the previous field
              // this.otpArray[index - 1] = ''
            }
            break
          case 'ArrowLeft':
            if (index > 0) {
              event.preventDefault()
              this.focusInput(index - 1)
            }
            break
          case 'ArrowRight':
            if (index < this.length - 1) {
              event.preventDefault()
              this.focusInput(index + 1)
            }
            break
          case 'Delete':
            // Clear current field on delete
            this.otpArray[index] = ''
            break
          case ' ':
            // Prevent spaces
            event.preventDefault()
            break
          default:
            // For mobile: if a user pastes a number, distribute it across fields
            if (event.key === 'Unidentified' || event.key === 'Process') {
              // This is likely a mobile input event, handle in the input event
              return
            }
            
            // For desktop: if a user types a number, put it in the current field and move to next
            if (/^[0-9]$/.test(event.key)) {
              event.preventDefault()
              this.otpArray[index] = event.key
              if (index < this.length - 1) {
                this.focusInput(index + 1)
              }
            } else {
              // Prevent non-numeric input
              event.preventDefault()
            }
        }
      },
      focusInput(index) {
        if (index >= 0 && index < this.length && this.$refs.inputRefs) {
          this.$nextTick(() => {
            this.$refs.inputRefs[index].focus()
          })
        }
      },
      reset() {
        this.otpArray = Array(this.length).fill('')
        this.focusInput(0)
      },
      setValue(value) {
        const valueArray = value.toString().split('').slice(0, this.length)
        this.otpArray = Array(this.length).fill('').map((_, index) => valueArray[index] || '')
      }
    }
  }
  </script>
  
  <style scoped>
  .otp-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }
  
  .otp-label {
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
    color: #374151;
    font-weight: 500;
  }
  
  .otp-input-group {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    width: 100%;
  }
  
  @media (min-width: 640px) {
    .otp-input-group {
      gap: 0.75rem;
    }
  }
  
  .otp-input-wrapper {
    flex: 1;
    max-width: 4rem;
  }
  
  .otp-input {
    width: 100%;
    height: 3rem;
    border: 1px solid #d1d5db;
    border-radius: 0.375rem;
    text-align: center;
    font-size: 1.25rem;
    font-weight: 600;
    color: #1f2937;
    background-color: #fff;
    transition: all 0.2s ease;
    -webkit-appearance: none;
    -moz-appearance: textfield;
  }
  
  .otp-input::-webkit-outer-spin-button,
  .otp-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  .otp-input:focus {
    outline: none;
    border-color: #3b82f6;
    box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.25);
  }
  
  .otp-input.is-filled {
    border-color: #10b981;
    background-color: rgba(16, 185, 129, 0.05);
  }
  
  .otp-input.is-active {
    border-color: #3b82f6;
    box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.25);
  }
  
  .otp-input:disabled {
    background-color: #f3f4f6;
    cursor: not-allowed;
    opacity: 0.7;
  }
  
  .otp-error {
    color: #ef4444;
    font-size: 0.875rem;
    margin-top: 0.5rem;
  }
  
  .is-complete .otp-input {
    border-color: #10b981;
  }
  </style>