<template>

  <div class="flex flex-col md:flex-row w-full gap-8 mt-6 ">
    
    <!-- Address column -->
    <div class="w-full md:w-1/3 mr-4 " v-if="addresses">
      <div class="flex flex-col  py-4 mb-4 pr-4 bg-gray-50 rounded-lg shadow-sm ">
        <address-item-component 
          v-for="(address, key) in addresses" 
          :key="key" 
          :address="address"
          :class="{'active-address': isActiveAddress(address)}"
          @on-address-click="onAddressClick(address)"
          class="address-item transition-all duration-200 p-3 rounded-md hover:bg-gray-100 cursor-pointer"
        ></address-item-component>
      </div>
    </div>
    
    <!-- Map column -->

    <div class="w-full md:w-2/3 maps">
      <div v-if="currentAddress" class="h-full min-h-[450px] rounded-lg overflow-hidden shadow-md transition-all duration-300">
        <iframe 
          width="100%" 
          height="100%" 
          style="border:0; min-height:450px;" 
          :src="`https://www.google.com/maps/embed/v1/place?q=${currentAddress}&key=AIzaSyC4YnvQ9y2DwnKKX0U7YCDDSkLlz-aBhKg`" 
          allowfullscreen
          frameborder="0"
        ></iframe>
      </div>
      <div class="w-full h-full flex items-center justify-center min-h-[450px] bg-gray-100 rounded-lg shadow-md text-gray-500" v-else>
        <div class="flex flex-col items-center">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 mb-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
          </svg>
          Adresse non definie
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddressListComponent",
  props: {
    addresses: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      currentAddress: null,
      selectedAddressId: null
    }
  },
  mounted() {
    console.log(this.addresses)
    if (this.addresses.length > 0) {
      this.selectedAddressId = this.addresses[0].id || 0;
      if (this.addresses[0].latitude) {
        this.currentAddress = this.addresses[0].latitude + '%2C' + this.addresses[0].longitude;
      } else {
        this.currentAddress = this.addresses[0].town_name + ',' + this.addresses[0].district_name;
      }
    }
  },
  methods: {
    onAddressClick(address) {
      this.selectedAddressId = address.id || this.addresses.indexOf(address);
      
      if (address.street && address.town_name && address.district_name) {
        if (address.latitude) {
          this.currentAddress = address.latitude + '%2C' + address.longitude;
        } else {
          this.currentAddress = address.town_name + ',' + address.district_name;
        }
      }
    },
    isActiveAddress(address) {
      return this.selectedAddressId === (address.id || this.addresses.indexOf(address));
    }
  }
}
</script>

<style scoped>
.maps iframe {
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease;
}

.address-item {
  border-left: 3px solid transparent;
  transition: all 0.3s ease;
}

.address-item:hover {
  transform: translateX(5px);
  opacity: 0.8;
}

.address-item.active-address {
  border-left: 3px solid #4f46e5;
  background-color: rgba(79, 70, 229, 0.1);
}
.address-item {
  border-left: 3px solid #525252;
  background-color: rgb(233, 233, 233);
  outline: none;
}



@media (max-width: 768px) {
  .maps, .address-item {
    margin-bottom: 1rem;
  }
}
</style>