<template>
  <button v-if="address" class="flex flex-col px-2 py-3 my-1" @click="onAddressClick(address)">
    <div>{{ address.name }}</div>
    <div>{{ address.street }}</div>
    <div>{{ `${address.town_name}, ${address.district_name}` }}</div>
  </button>
</template>

<script>
export default {
  name: "AddressItemComponent",
  props: {
    address: {
      type: Object,
      required: true,
    }
  },
  mounted() {
  },
  methods: {
    onAddressClick(address) {
      this.$emit('on-address-click', address);
    }
  }
}
</script>

<style scoped>
.address {
  padding: 1rem 2rem 1rem 0;
  display: flex;
  flex-direction: column;
}
</style>
