<template>
  <div class="container p-3">
    <big-title label="JOY CARDS" />
    <br />
    <br />
    <div class="payment-container p-3">
      <div class="row">
        <div class="col-md-12">
          <sub-title label="PAIEMENT DU FORFAIT" gender="F" />
        </div>
      </div>
      <div class="row" v-if="sendingLoading == null">
        <div class="col-md-6">
          <ul class="list-group">
            <li
              class="list-group-item text-white font-weight-bold"
              style="background-color: #b71c1c"
            >
              Vous avez different moyen de paiement
            </li>
            <li class="list-group-item">
              <div class="form-group">
                <label for="orange-money">
                  <input
                    value="ORANGE_MONEY"
                    type="radio"
                    name="paymentMethod"
                    id="orange-money"
                    @change="changePaymentMethod('ORANGE_MONEY')"
                  />&nbsp;
                  <label
                    for="orange-money"
                    style="font-size: 16px; font-weight: 900"
                  >
                    ORANGE MONEY </label
                  >&nbsp;
                  <img
                    src="/payment-methods/orange-money.png"
                    style="height: 50px"
                    alt=""
                    srcset=""
                  />
                </label>
              </div>
            </li>
            <li class="list-group-item">
              <div class="form-group">
                <label for="mtn-money">
                  <input
                    value="MTN_MONEY"
                    type="radio"
                    name="paymentMethod"
                    id="mtn-money"
                    @change="changePaymentMethod('MTN_MONEY')"
                  />&nbsp;
                  <label
                    for="mtn-money"
                    style="font-size: 16px; font-weight: 900"
                  >
                    MTN MONEY
                  </label>
                  &nbsp;
                  <img
                    src="/payment-methods/mtn-money.png"
                    style="height: 50px"
                    alt=""
                    srcset=""
                  />
                </label>
              </div>
            </li>
            <li class="list-group-item">
              <div class="form-group">
                <label for="moov-money">
                  <input
                    value="MOOV_MONEY"
                    type="radio"
                    name="paymentMethod"
                    id="moov-money"
                    @change="changePaymentMethod('MOOV_MONEY')"
                  />&nbsp;
                  <label
                    for="moov-money"
                    style="font-size: 16px; font-weight: 900"
                  >
                    MOOV MONEY </label
                  >&nbsp;
                  <img
                    src="/payment-methods/moov-money.png"
                    style="height: 50px"
                    alt=""
                    srcset=""
                  />
                </label>
              </div>
            </li>
            <li class="list-group-item">
              <div class="form-group">
                <label for="wave">
                  <input
                    value="WAVE"
                    type="radio"
                    name="paymentMethod"
                    id="wave"
                    @change="changePaymentMethod('WAVE')"
                  />&nbsp;
                  <label for="wave" style="font-size: 16px; font-weight: 900">
                    WAVE </label
                  >&nbsp;
                  <img
                    src="/payment-methods/wave.png"
                    style="height: 50px"
                    alt=""
                    srcset=""
                  />
                </label>
              </div>
            </li>
            <!-- <div> -->
            <!-- <img src="https://cinetpay.com/dev/images/logo/latest_ci1.png" alt="Logo CinetPay"> -->
            <!-- </div> -->
          </ul>
        </div>
        <div class="col-md-6">
          <ul class="list-group">
            <li
              class="list-group-item text-white font-weight-bold"
              style="background-color: #b71c1c"
            >
              Récapitulatif de l'achat
            </li>
            <li
              class="list-group-item"
              v-if="
                message &&
                message.channel &&
                message.channel.id == 3 &&
                message.peopleCount !== null &&
                message.messageNumber !== null &&
                message.sellingPrice !== null
              "
            >
              <!-- <div class="alert alert-danger text-center">
                Vous aurez à payer :
                <span class="badge badge-secondary">{{
                  message.channel.selling_price +
                    " FCFA x " +
                    message.messageNumber +
                    " message(s) x " +
                    message.tab.length +
                    " invité(s) = " +
                    message.sellingPrice +
                    " FCFA"
                }}</span>
              </div> -->
              <div class="alert alert-danger text-center">
                Vous aurez à payer :
                <span class="badge badge-secondary">{{
                  message.channel.selling_price +
                  " FCFA x " +
                  (Number(message.messageNumber) * message.tab.length -
                    Number(message.customerChannel.pivot.remaining_messages)) +
                  " message(s) " +
                  "  = " +
                  sellingPrice +
                  " FCFA"
                }}</span>
              </div>
            </li>
            <li class="list-group-item text-right" v-if="sellingPrice !== null">
              <!-- <strong>Prix HT: {{ sellingPrice }} FCFA</strong> -->
            </li>
            <li
              class="list-group-item text-right"
              v-if="sellingPriceTTC !== null"
            >
              <h3>Coût: {{ sellingPrice }} FCFA</h3>
            </li>
          </ul>
        </div>
      </div>
      <div v-else>
        <div
          class="alert alert-primary"
          v-if="sendingLoading == true"
          role="alert"
        >
          <div class="text-center py-2 px-5">
            <div class="form-group">
              <label for="exampleInputEmail1">Après votre paiment</label>
              <!-- <input type="email" v-model="paymentRef" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="exemple: ref210220221125208"> -->
              <small id="emailHelp" class="form-text text-muted"
                >Cliquez sur <strong> retourner sur site</strong></small
              >
            </div>
          </div>
        </div>
        <div
          class="alert alert-warning"
          v-if="sendingLoading == false"
          role="alert"
        >
          <label for="exampleInputEmail1">Si votre paiment est effectué,</label>
          <!-- <input type="email" v-model="paymentRef" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="exemple: ref210220221125208"> -->
          <small id="emailHelp" class="form-text text-muted"
            >Cliquez sur <strong> retourner sur le site</strong></small
          >
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <button
            class="secondary-button pull-left"
            @click="returnToTargetChoice"
          >
            <i class="fa fa-chevron-left" style="color: #fff"></i> PRÉCÉDENT
          </button>
          <div v-if="isLoading == true"><spinner /><br /><br /></div>
          <button v-else @click="payment" class="primary-button pull-right">
            <i class="fa fa-shopping-cart" style="color: #fff"></i> PAYER
          </button>
        </div>
      </div>
    </div>
    <!-- <div>
      <modal
        style="margin-top:3%;"
        :scrollable="true"
        height="auto"
        name="hello"
        :resizable="true"
        :adaptive="true"
        :clickToClose="true"
      >
        <div class="text-center h3 py-3">
          
        </div>
        <hr />
        <div class="text-center text-danger h4 py-2">
          {{messageStatus}}
        </div>
        <div class="text-center text-danger h6 py-2">
          {{paymentMessage}}
        </div>
        <div class="text-center py-2 px-5" v-if="paymentLoading === 201 ">
          <div class="form-group">
            <label for="exampleInputEmail1">Référence de paiement (ID Transaction)</label>
            <input type="email" v-model="paymentRef" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="exemple: ref210220221125208">
            <small id="emailHelp" class="form-text text-muted">Le numero de référence est valable qu'une seule fois et après paiement</small>
          </div>
        </div>
        <button type="submit" class="btn  m-2 btn-primary float-right" v-if="paymentLoading === '1000' || paymentLoading === 201 " @click="validate">Valider</button>
      </modal>
    </div> -->
  </div>
</template>
<script>
import { API_PREFIX, API_VERSION, COMMUNICATION, PAYMENT } from "@/config";
import axios from "axios";
import moment from "moment";
import { createToast } from "mosha-vue-toastify";

export default {
  name: "PaymentComponent",
  data() {
    return {
      messageTest: this.$store.state.message,
      paymentLoading: null,
      paymentMethod: null,
      paymentMessage: null,
      message: this.$store.state.message,
      telNumber: null,
      reference: null,
      orderId: null,
      paymentRef: null,
      clientcounter: 0,
      countDown: 1,
      messageStatus: null,
      customer_id: Number(this.$store.state.customer_id),
      user: JSON.parse(this.$store.state.user),
      isLoading: false,
      sendingLoading: null,
    };
  },
  mounted() {
    this.countDownTimer();
  },
  computed: {
    sellingPrice: {
      get: function () {
        if (this.message) {
          return (
            Number(this.message.channel.selling_price) *
            (Number(this.message.messageNumber) * this.clientcounter -
              Number(this.message.customerChannel.pivot.remaining_messages))
          );
        } else {
          return 0;
        }
      },
      set: function (message) {
        this.clientcounter = message.tab.length;
      },
    },
    // sellingPrice() {
    //   if (
    //     this.message &&
    //     this.message.channel &&
    //     this.message.channel.id == 3 &&
    //     this.message.peopleCount !== null &&
    //     this.message.messageNumber !== null
    //   ) {
    //     return (
    //       Number(this.message.channel.selling_price) *
    //       Number(this.message.messageNumber) *
    //       Number(this.message.tab.length)
    //     );
    //   } else {
    //     return null;
    //   }
    // },
    sellingPriceTTC() {
      if (this.sellingPrice !== null) {
        return Math.ceil(
          this.sellingPrice + Number(this.sellingPrice * 18) / 100
        );
      } else {
        return null;
      }
    },
  },
  methods: {
    returnToTargetChoice() {
      this.$emit("return-to-message-summary");
    },
    changePaymentMethod(paymentMethod) {
      this.paymentMethod = paymentMethod;
    },
    payment() {
      let app = this;
      let date = new Date();
      // if (this.telNumber) {
      //     this.telNumber = this.telNumber.replace(/\s/g, '');
      // }
      this.isLoading = true;
      let targetIds = [];
      app.message.targets.forEach((target) => {
        targetIds.push(target.id);
      });

      let ref = moment(date).format("DDMMYYYYHHmsS");
      this.reference = ref;
      let ord = moment(date).format("HHmsSDDMMYYYY");
      this.orderId = ord;
      this.createdMessage = {
        channel_id: app.message.channel.id,
        label: app.message.label,
        message_category_id: app.message.category.id,
        message_model_id: app.message.model.id,
        message_design_id: app.message.layout.id,
        from: app.message.from,
        subject: app.message.subject,
        body: app.message.body,
        tab: app.message.tab,
        target: targetIds.join(";"),
      };
      axios
        .post(
          COMMUNICATION.DEV_URL + API_PREFIX + API_VERSION + "message-save",
          this.createdMessage,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          if (response.data.success == true) {
            if (this.paymentMethod == "WAVE") {
              app.paiementWave(response.data.data.id);
            } else {
              app.paiementInit(response.data.data.id);
            }
          } else {
            app.paymentMessage = response.data.messages;
            this.$createToast(
              {
                title: "Error",
                description: "Erreur détectée lors de l'envoie du message  !",
              },
              {
                position: "top-center",
                type: "error",
                timeout: 10000,
                transition: "bounce",
              }
            );
          }
        })
        .catch((error) => {});
    },
    validate() {
      let app = this;
      let remaining_message =
        Number(this.message.messageNumber) * this.clientcounter -
        Number(this.message.customerChannel.pivot.remaining_messages);
      axios
        .post(
          PAYMENT.DEV_URL + API_PREFIX + API_VERSION + "payment/validate",
          {
            paymentRef: this.paymentRef,
            channel_id: this.message.channel.id,
            customer_id: this.customer_id,
            remaining_message: remaining_message,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          if (response.data.success == true) {
            app.sendMessage();
          } else {
            app.paymentMessage = response.data.messages;
            this.$createToast(
              {
                title: "Error",
                description: "Erreur détectée lors de l'envoie du message  !",
              },
              {
                position: "top-center",
                type: "error",
                timeout: 10000,
                transition: "bounce",
              }
            );
          }
        })
        .catch((error) => {});
    },
    show() {
      if (this.paymentLoading == 641) {
        this.$modal.show("hello");
        this.messageStatus = "Montant minimum 100Fcfa";
      }
      if (this.paymentLoading == 201) {
        this.$modal.show("hello");
        this.messageStatus = "Paiement en attente";
      }
      if (this.paymentLoading == 642) {
        this.$modal.show("hello");
        this.messageStatus = "Montant trop élevé";
      }
      if (this.paymentLoading == 100) {
        this.$modal.show("hello");
        this.messageStatus = "ERREUR";
      }
      if (this.paymentLoading == 515) {
        this.$modal.show("hello");
        this.messageStatus = "Numero Incorrect";
      }
    },
    momoInit() {
      let app = this;
      axios
        .post(
          PAYMENT.DEV_URL + API_PREFIX + API_VERSION + "payment/momo",
          {
            MSISDN: "225" + this.telNumber,
            Reference: this.reference,
            Amount: this.sellingPriceTTC,
            MetaData: "joycard",
            Channel_id: this.message.channel.id,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          app.paymentLoading = response.data.ResponseCode;
          app.show();
        })
        .catch((error) => {});
    },
    xiccaInit() {
      axios
        .post(
          PAYMENT.DEV_URL + API_PREFIX + API_VERSION + "payment/xikka",
          {
            reference: this.reference,
            montant: this.sellingPriceTTC,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          window.open(
            "https://preprod.xikka.net/paiement_marchands/confirm_from_api?api_key=Z9-wngVJ0hctK625j8ORWQ&request_content=" +
              response.data +
              ""
          );
          this.paymentLoading = response.data.ResponseCode;
          this.show();
        })
        .catch((error) => {});
    },
    omInit() {
      let app = this;
      axios
        .post(
          PAYMENT.DEV_URL + API_PREFIX + API_VERSION + "om-payment",
          {
            reference: "ref" + this.reference,
            amount: this.sellingPriceTTC,
            order_id: this.orderId,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          window.open(response.data.payment_url);
          app.paymentLoading = response.data.response_code;
          app.show();
        })
        .catch((error) => {});
    },
    paiementInit(messageId) {
      let app = this;
      let remaining_messages =
        Number(this.message.messageNumber) * this.clientcounter -
        Number(this.message.customerChannel.pivot.remaining_messages);
      axios
        .post(PAYMENT.DEV_URL + API_PREFIX + API_VERSION + "cinetpay-payment", {
          transaction_id: "ref" + this.reference,
          amount: this.sellingPrice,
          customer_id: this.customer_id,
          customer_first_name: this.user.first_name,
          customer_last_name: this.user.last_name,
          channel_id: this.message.channel.id,
          remaining_messages: remaining_messages,
          message_id: messageId,
        })
        .then((response) => {
          // window.open(response.data.data.payment_url)
          // app.paymentLoading = response.data.response_code
          // app.show()

          app.paymentLoading = Number(response.data.code);
          app.isLoading = false;
          if (response.data.code == "201") {
            app.sendingLoading = true;
            window.open(response.data.data.payment_url, "_bank");
            setTimeout(() => {
              app.sendingLoading = false;
            }, 120000);
          } else {
            this.$createToast(
              {
                title: "Attention",
                description: response.data.description,
              },
              {
                position: "top-center",
                type: "warning",
                timeout: 10000,
                transition: "bounce",
              }
            );
          }
        })
        .catch((error) => {
          app.isLoading = false;
        });
    },
    paiementWave(messageId) {
      let app = this;
      let remaining_messages =
        Number(this.message.messageNumber) * this.clientcounter -
        Number(this.message.customerChannel.pivot.remaining_messages);
      axios
        .post(PAYMENT.DEV_URL + API_PREFIX + API_VERSION + "wave-payment", {
          transaction_id: "ref" + this.reference,
          amount: this.sellingPrice,
          customer_id: this.customer_id,
          channel_id: this.message.channel.id,
          remaining_messages: remaining_messages,
          message_id: messageId,
        })
        .then((response) => {
          // window.open(response.data.data.payment_url)
          // app.paymentLoading = response.data.response_code
          // app.show()

          // app.paymentLoading = Number(response.data.code)
          app.isLoading = false;
          if (response.data.status) {
            app.sendingLoading = true;
            window.open(response.data.data.payment_url, "_bank");
            setTimeout(() => {
              app.sendingLoading = false;
            }, 120000);
          } else {
            this.$createToast(
              {
                title: "Attention",
                description: "Erreur lors du lancement de paiement",
              },
              {
                position: "top-center",
                type: "warning",
                timeout: 10000,
                transition: "bounce",
              }
            );
          }
        })
        .catch((error) => {
          app.isLoading = false;
        });
    },
    sendMessage: function () {
      let app = this;
      app.isLoading = true;
      let targetIds = [];
      app.message.targets.forEach((target) => {
        targetIds.push(target.id);
      });
      app.createdMessage = {
        channel_id: app.message.channel.id,
        label: app.message.label,
        message_category_id: app.message.category.id,
        message_model_id: app.message.model.id,
        message_design_id: app.message.layout ? app.message.layout.id : null,
        from: app.message.from,
        subject: app.message.subject,
        body: app.message.body,
        tab: app.message.tab,
        target: targetIds.join(";"),
      };
      app.message = {
        channel_id: app.message.channel.id,
        label: app.message.label,
        message_category_id: app.message.category.id,
        message_model_id: app.message.model.id,
        message_design_id: app.message.layout ? app.message.layout.id : null,
        from: app.message.from,
        subject: app.message.subject,
        body: app.message.body,
        tab: app.message.tab,
        target: targetIds.join(";"),
      };
      // app.$emit("message-sent", this.message);
      axios
        .post(
          COMMUNICATION.DEV_URL + API_PREFIX + API_VERSION + "messages",
          app.createdMessage,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          app.isLoading = false;
          let result = response.data;
          if (result.data.channel_id == 2) {
            let url =
              "https://joursdejoie.com/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/wedding-card/" +
              result.data.id;
            window.open(
              "https://www.facebook.com/sharer/sharer.php?app_id=548126212259891&sdk=joey&u=" +
                url,
              "_blank"
            );
          }
          createToast(
            {
              title: "Succès",
              description: "Message envoyé avec succès !",
            },
            {
              position: "top-center",
              type: "warning",
              timeout: 10000,
              transition: "bounce",
            }
          );
          app.$router.push(
            "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/carte-d-invitation-mariage"
          );
        })
        .catch((error) => {
          app.isLoading = false;
          createToast(
            {
              title: "Error",
              description: "Erreur détectée lors de l'envoie du message  !",
            },
            {
              position: "top-center",
              type: "error",
              timeout: 10000,
              transition: "bounce",
            }
          );
        });
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown += 1;
          this.countDownTimer();
        }, 1000);
        if (this.message) {
          this.clientcounter = this.message.tab.length;
        }
      }
    },
  },
};
</script>

<style scoped>
.payment-container {
  -webkit-box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
  -moz-box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
  box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
}
</style>
