<template>
  <div>
    <div class="container p-3">
      <big-title label="JOY CARDS" />
      <br />
      <br />
      <div class="message-builder-container p-3">
        <div class="row">
          <div class="col-md-12">
            <sub-title label="COMPOSEZ VOTRE MESSAGE" gender="F" />
          </div>
        </div>
        <div v-if="true">
          <!-- <div
            class="row"
            style="position:relative;z-index:1;"
            v-if="channel.id == 3"
          >
            <div
              :style="
                'position:absolute;z-index:-1;top:-5%;bottom:0;left:70%;right:0;background: url(/images/communication/paper-plane.png) no-repeat;background-size:300px;background-repeat:no-repeat;opacity:0.2;'
              "
            ></div>

            <div class="col-md-12">
              <div class="form-group">
                <label>Type de message</label>
                <br />
                <vue-toggle
                  :title=" personnalise ? 'Personnalisé':'Prédéfini'"
                  name="nom"
                  :toggled="false"
                  fontSize="12px"
                  v-model="personnalise"
                  @toggle="(value) => personnalise = value"
                />
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label for>Catégorie de message *</label>
                <select
                  v-model="message.category"
                  @change="onCategoryChange(message.category)"
                  class="form-control"
                  required
                >
                  <option value>--</option>
                  <option
                    v-for="(category, key) in categories"
                    :key="key"
                    :value="category"
                    >{{ category.name }}</option
                  >
                </select>
              </div>
            </div>

            <div v-if="!personnalise" class="col-md-6">
              <div class="form-group">
                <label for>Modèle *</label>
                <select
                  v-model="message.model"
                  @change="onModelChange(message.model)"
                  id
                  class="form-control"
                  required
                >
                  <option value>--</option>
                  <option
                    v-for="(model, key) in models"
                    :key="key"
                    :value="model"
                    >{{ model.name }}</option
                  >
                </select>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label for>Expéditeur *</label>
                <input
                  type="text"
                  :value="message.from"
                  maxlength="11"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
            <div v-if="personnalise" class="col-md-12">
              <div class="form-group">
                <label for>Message *</label>&nbsp;
                <span class="badge badge-danger">{{
                  messageNumber > 1
                    ? messageNumber + " messages"
                    : messageNumber + " message"
                }}</span
                >&nbsp;
                <span class="badge badge-primary">{{
                  messageLength > 1
                    ? messageLength + " caractères"
                    : messageLength + " caractère"
                }}</span>
                <textarea
                  class="form-control"
                  @keyup="personBody()"
                  rows="10"
                  maxlength="456"
                  v-model="messagePerso"
                  placeholder="Votre message"
                  required
                ></textarea>
              </div>
            </div>
            <div v-else class="col-md-12">
              <div class="form-group">
                <label for>Message *</label>&nbsp;
                <span class="badge badge-danger">{{
                  messageNumber > 1
                    ? messageNumber + " messages"
                    : messageNumber + " message"
                }}</span
                >&nbsp;
                <span class="badge badge-primary">{{
                  messageLength > 1
                    ? messageLength + " caractères"
                    : messageLength + " caractère"
                }}</span>
                <br />
                <small class="text-danger">
                  <i>
                    (Toutes les informations personnelles que vous verrez ne
                    sont que des valeurs de tests. Lors de l'envoi du message à
                    votre invité ce sont les valeurs renseignées au préalable
                    dans votre carnet nuptial qui apparaitront.)
                  </i>
                </small>
                <br />
                <div
                  class="alert alert-info text-center"
                  v-if="message.body == null"
                >
                  <p>
                    Veuillez s'il vous plait choisir une catégorie de message et
                    un modèle !
                  </p>
                </div>
                <div
                  v-else
                  class="text-center"
                  style="border-style: ridge;"
                  v-html="displayMessage"
                ></div>
              </div>
            </div>

            <div class="col-md-12">
              <div v-if="personnalise">
                <button
                  class="primary-button pull-right"
                  v-if="
                    message.from != null &&
                      message.category != null &&
                      message.body != null
                  "
                  @click="goToTargetChoice"
                >
                  SUIVANT
                  <i class="fa fa-chevron-right" style="color:#fff;"></i>
                </button>

                <button class="primary-button pull-right" v-else disabled>
                  SUIVANT
                  <i class="fa fa-chevron-right" style="color:#fff;"></i>
                </button>
              </div>
              <div v-else>
                <button
                  class="primary-button pull-right"
                  v-if="
                    message.from != null &&
                      message.category != null &&
                      message.model != null &&
                      message.body != null
                  "
                  @click="goToTargetChoice"
                >
                  SUIVANT
                  <i class="fa fa-chevron-right" style="color:#fff;"></i>
                </button>

                <button class="primary-button pull-right" v-else disabled>
                  SUIVANT
                  <i class="fa fa-chevron-right" style="color:#fff;"></i>
                </button>
              </div>
            </div>
          </div> -->
          <div class="row py-5 mx-5 px-5 " style="position: relative; z-index: 1 ;box-shadow: 10px 12px 71px -24px rgba(189,189,189,1); margin: 30px 0;
  ">
            <div
              :style="'position:absolute;z-index:-1;top:-5%;bottom:0;left:70%;right:0;background-size:300px;background-repeat:no-repeat;opacity:0.2;'"
            ></div>

            <div  class=" ">
              <div  class="form-group  flex items-center pb-4">
                <label style="font-size: 15px; font-weight: normal;" class="pr-4" >Type de message</label>
                <br />
                <vue-toggle 
                  :title="personnalise ? 'Personnalisé' : 'Prédéfini'"
                  name="nom"
                  :toggled="false"
                  fontSize="12px"
                  v-model="personnalise"
                  @toggle="(value) => (personnalise = value)"
                />
              </div>
            </div>
<div class="flex flex-col w-full">


  <div class="w-full">
              <div class="form-group">
                <label for>Catégorie de message <span style="color: #D00000;"> *</span> </label>
                <select
                  v-model="message.category"
                  @change="onCategoryChange(message.category)"
                  class="form-control"
                  required
                >
                  <option value>--</option>
                  <option
                    v-for="(category, key) in categories"
                    :key="key"
                    :value="category"
                  >
                    {{ category.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="">
              <div v-if="!personnalise" class="form-group">
                <label for>Modèle <span style="color: #D00000;"> *</span></label>
                <select
                  v-model="message.model"
                  @change="onModelChange(message.model)"
                  id
                  class="form-control"
                  required
                >
                  <option value>--</option>
                  <option
                    v-for="(model, key) in models"
                    :key="key"
                    :value="model"
                  >
                    {{ model.name }}
                  </option>
                </select>
              </div>
            </div>

</div>
            <div v-if="personnalise" class="">
              <div class="form-group">
                <label for>Message *</label>
                <!-- <textarea
                  class="form-control"
                  @keydown="personBody()"
                  rows="10"
                  v-model="messagePerso"
                  placeholder="Votre message"
                  required
                ></textarea> -->
                <ckeditor
                  :editor="editor"
                  v-model="messagePerso"
                  :config="editorConfig"
                ></ckeditor>
              </div>
            </div>
            <div v-else class="">
              <div class="form-group">
                <label for>Message <span style="color: #D00000;"> *</span></label>
                <br />
                <p class="text-danger py-4">
                  <i>
                    (Toutes les informations personnelles que vous verrez ne
                    sont que des valeurs de tests. Lors de l'envoi du message à
                    votre invité ce sont les valeurs renseignées au préalable
                    dans votre carnet nuptial qui apparaitront.)
                  </i>
                </p>
                <br />
                <div
                  class="alert alert-info text-center"
                  v-if="message.body == null"
                >
                  <p>
                    Veuillez s'il vous plait choisir une catégorie de message et
                    un modèle !
                  </p>
                </div>
                <div

                  v-else
                  class="text-center mt-4 text-black"
                  style="border-style: ridge; background-color: #F9F9F9; padding: 22px; margin-top: 10px; font-size: 15px;"
                  v-html="displayMessage"
                ></div>
              </div>
            </div>
            <div class="col-md-12">
              <button
                style="border-radius: 5px; margin-top: 14px;"
                class="primary-button pull-right"
                @click="goToDesignMaker"
                :disabled="!isFormValid"
              >
                SUIVANT
                <i class="fa fa-chevron-right" style="color: #fff; padding-left: 4px;"></i>
              </button>
            </div>
          </div>
          <!-- <div
            class="row"
            style="position:relative;z-index:1;"
           
          >
            <div
              :style="
                'position:absolute;z-index:-1;top:-5%;bottom:0;left:70%;right:0;background: url(/images/communication/paper-plane.png) no-repeat;background-size:300px;background-repeat:no-repeat;opacity:0.2;'
              "
            ></div>
            <div class="col-md-12">
              <div class="form-grou">
                <label>Type de message</label>
                <br />
                <vue-toggle
                  :title=" personnalise ? 'Personnalisé':'Prédéfini'"
                  name="nom"
                  :toggled="false"
                  fontSize="12px"
                  v-model="personnalise"
                  @toggle="(value) => personnalise = value"
                />

              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label for>Catégorie de message *</label>
                <select
                  v-model="message.category"
                  @change="onCategoryChange(message.category)"
                  class="form-control"
                  required
                >
                  <option value>--</option>
                  <option
                    v-for="(category, key) in categories"
                    :key="key"
                    :value="category"
                    >{{ category.name }}</option
                  >
                </select>
              </div>
            </div>

            <div class="col-md-6">
              <div v-if="!personnalise" class="form-group">
                <label for>Modèle *</label>
                <div>
                  <select
                    v-model="message.model"
                    @change="onModelChange(message.model)"
                    id
                    class="form-control"
                    required
                  >
                    <option value>--</option>
                    <option
                      v-for="(model, key) in models"
                      :key="key"
                      :value="model"
                      >{{ model.name }}</option
                    >
                  </select>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label for>Expéditeur *</label>
                <input
                  type="text"
                  v-model="message.from"
                  id
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for>Sujet *</label>
                <input
                  type="text"
                  v-model="message.subject"
                  id
                  class="form-control"
                />
              </div>
            </div>
            <div v-if="personnalise" class="col-md-12">
              <div class="form-group">
                <label for>Message *</label>
                <ckeditor
                  class="form-control"
                  :editor="editor"
                  v-model="messagePerso"
                  :config="editorConfig"
                ></ckeditor>
              </div>
            </div>
            <div v-else class="col-md-12">
              <div class="form-group">
                <label for>Message *</label>
                <br />
                <small class="text-danger">
                  <i>
                    (Toutes les informations personnelles que vous verrez ne
                    sont que des valeurs de tests. Lors de l'envoi du message à
                    votre invité ce sont les valeurs renseignées au préalable
                    dans votre carnet nuptial qui apparaitront.)
                  </i>
                </small>
                <br />
                <div
                  class="alert alert-info text-center"
                  v-if="message.body == null"
                >
                  <p>
                    Veuillez s'il vous plait choisir une catégorie de message et
                    un modèle !
                  </p>
                </div>
                <div
                  v-else
                  class="text-center"
                  style="border-style: ridge;"
                  v-html="displayMessage"
                ></div>
              </div>
            </div>
            <br />
            <br />
            <div class="col-md-12">

              <button
                class="primary-button pull-right"
                v-if="
                  message.label != null &&
                    message.from != null &&
                    message.subject != null &&
                    message.category != null &&
                    message.model != null &&
                    message.body != null
                "
                @click="goToDesignMaker"
              >
                SUIVANT
                <i class="fa fa-chevron-right" style="color:#fff;"></i>
              </button>

              <button class="primary-button pull-right" v-else disabled>
                SUIVANT
                <i class="fa fa-chevron-right" style="color:#fff;"></i>
              </button>
            </div>
          </div> -->
        </div>
        <div class="row" v-else>
          <div class="col-md-12">
            <p style="color: #a33; text-align: center">
              Chargement en cours ...
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/*
.message-builder-container {
  
  -webkit-box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.1);
}
*/
</style>

<script>
import {
  API_PREFIX,
  API_VERSION,
  COMMUNICATION,
  NUPTIAL_NOTEBOOK,
} from "@/config";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import moment from "moment";
import VueToggle from "vue-toggle-component";

export default {
  name: "MessageBuilderComponent",
  // props: ["channel"],
  components: {
    VueToggle,
  },
  data: function () {
    return {
      categories: null,
      category: null,
      models: null,
      model: null,
      channel: { id: 2 },
      message: {
        channel: null,
        label: "My message",
        category: null,
        model: null,
        layout: null,
        from: "JoursdeJoie",
        subject: null,
        body: null,
        targets: [],
        sellingPrice: null,
      },
      editor: ClassicEditor,
      editorConfig: {
        // The configuration of the editor.
      },
      customerChannels: null,
      customerChannel: null,
      eventId: null,
      displayMessage: null,
      messagePerso: null,
      personnalise: false,
      messageLength: 0,
      messageNumber: 0,
      marrieds: [],
      dates: [],
    };
  },
  mounted: function () {
    this.getMessageCategories();
    this.getCustomerInfos();
    this.getChannels();
  },
  watch: {
    personnalise(value) {
      if (value == true) {
        this.messagePerso = this.message.body;
      }
    },
  },
  methods: {
    countCaracters(message) {
      return message.length;
    },
    togglePersonnalise: function (response) {
      this.message.body = null;
      if (response == false) {
        this.messagePerso = null;
      }
      this.message.model = 8;
      this.message.channel = this.channel.id;
      this.displayMessage = this.message.body;
    },
    personBody() {
      if (this.messagePerso !== null && this.messagePerso !== "") {
        this.message.body = this.messagePerso;
        this.messageLength = this.messagePerso.length;
        this.messageNumber = Math.ceil(this.messageLength / 160);
      }
    },
    getCustomerInfos: function () {
      let app = this;
      this.isLoading = true;
      axios
        .get(
          NUPTIAL_NOTEBOOK.DEV_URL +
            API_PREFIX +
            API_VERSION +
            "customers/" +
            app.$store.state.customer_id,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          app.eventId = response.data.id;
          app.marrieds = response.data.event.marrieds;
          app.dates = response.data.event.dates_places;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getMessageCategories: function () {
      let app = this;
      axios
        .get(
          COMMUNICATION.DEV_URL +
            API_PREFIX +
            API_VERSION +
            "categories?category_for_customer=true",
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then(function (response) {
          app.categories = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    onCategoryChange: function (category) {
      let app = this;
      app.models = category.models;
    },
    onModelChange: function (model) {
      let app = this;

      let bodyFacebook = model.content_for_facebook
        .replaceAll("DATE_ENTERREMENT_DE_VIE_DE_JEUNE_FILLE", "01/12/2020")
        .replaceAll("HEURE_ENTERREMENT_DE_VIE_DE_JEUNE_FILLE", "20h00")
        .replaceAll(
          "LIEU_ENTERREMENT_DE_VIE_DE_JEUNE_FILLE",
          "Angré, Star 11, Abidjan, Côte d'ivoire"
        )

        .replaceAll("DATE_ENTERREMENT_DE_VIE_DE_JEUNE_GARCON", "01/12/2020")
        .replaceAll("HEURE_ENTERREMENT_DE_VIE_DE_JEUNE_GARCON", "20h00")
        .replaceAll(
          "LIEU_ENTERREMENT_DE_VIE_DE_JEUNE_GARCON",
          "Angré, Star 11, Abidjan, Côte d'ivoire"
        )

        .replaceAll("DATE_RECEPTION_DE_MARIAGE_CIVIL", "01/12/2020")
        .replaceAll("HEURE_RECEPTION_DE_MARIAGE_CIVIL", "08h00")
        .replaceAll("LIEU_RECEPTION_DE_MARIAGE_CIVIL", "Mairie de Cocody")

        .replaceAll("DATE_RECEPTION_DE_DOT", "01/12/2020")
        .replaceAll("HEURE_RECEPTION_DE_DOT", "10h00")
        .replaceAll(
          "LIEU_RECEPTION_DE_DOT",
          "Angré, Star 11, Abidjan, Côte d'ivoire"
        )

        .replaceAll("DATE_RECEPTION_DE_MARIAGE_RELIGIEUX", "01/12/2020")
        .replaceAll("HEURE_RECEPTION_DE_MARIAGE_RELIGIEUX", "12h00")
        .replaceAll(
          "LIEU_RECEPTION_DE_MARIAGE_RELIGIEUX",
          "Angré, Star 14, Abidjan, Côte d'ivoire"
        )

        .replaceAll("DATE_CEREMONIE_DE_MARIAGE_RELIGIEUX", "01/12/2020")
        .replaceAll("HEURE_CEREMONIE_DE_MARIAGE_RELIGIEUX", "12h00")
        .replaceAll(
          "LIEU_CEREMONIE_DE_MARIAGE_RELIGIEUX",
          "Paroisse Ste Cécile, Vallons"
        )

        .replaceAll("DATE_CEREMONIE_DE_DOT", "01/12/2020")
        .replaceAll("HEURE_CEREMONIE_DE_DOT", "08h30")
        .replaceAll(
          "LIEU_CEREMONIE_DE_DOT",
          "Angré, Star 14, Abidjan, Côte d'ivoire"
        );

      // Vérification avant d'accéder à app.dates.find
      let mariageCivil = app.dates?.find(
        (element) => element.name === "Cérémonie de Mariage Civil"
      );
      let receptionAnniversaire = app.dates?.find(
        (element) => element.name === "RÉCEPTION DE L'ANNIVERSAIRE"
      );
      let receptionBapteme = app.dates?.find(
        (element) => element.name === "RÉCEPTION DE BAPTEME"
      );

      bodyFacebook = bodyFacebook
        .replaceAll(
          "DATE_CEREMONIE_DE_MARIAGE_CIVIL",
          moment(mariageCivil?.pivot?.wedding_date).format(
            "dddd, MMMM Do YYYY"
          ) || ""
        )
        .replaceAll(
          "HEURE_CEREMONIE_DE_MARIAGE_CIVIL",
          mariageCivil?.pivot?.wedding_time || ""
        )
        .replaceAll(
          "LIEU_CEREMONIE_DE_MARIAGE_CIVIL",
          mariageCivil?.pivot?.wedding_location || ""
        )

        .replaceAll("CIVILITE_PCO", "M.")
        .replaceAll("NOM_PCO", "Doe")
        .replaceAll("PRENOMS_PCO", "Cedric")
        .replaceAll("EMAIL_PCO", "cedric.doe@gmail.com")
        .replaceAll("CONTACT_1_PCO", "(+225) 01 00 00 01")
        .replaceAll("CONTACT_2_PCO", "(+225) 01 00 00 01")

        .replaceAll("CIVILITE_FIANCEE", "Mlle")
        .replaceAll(
          "NOM_FIANCEE",
          app.marrieds?.find(
            (element) => element.name === "Informations de la fiancée"
          )?.pivot?.last_name || ""
        )
        .replaceAll(
          "PRENOMS_FIANCEE",
          app.marrieds?.find(
            (element) => element.name === "Informations de la fiancée"
          )?.pivot?.given_names || ""
        )
        .replaceAll(
          "EMAIL_FIANCEE",
          app.marrieds?.find(
            (element) => element.name === "Informations de la fiancée"
          )?.pivot?.email || ""
        )
        .replaceAll("CONTACT_1_FIANCEE", "(+225) 01 00 10 01")
        .replaceAll("CONTACT_2_FIANCEE", "(+225) 01 00 11 01")

        .replaceAll("CIVILITE_FIANCE", "M")
        .replaceAll(
          "NOM_FIANCE",
          app.marrieds?.find(
            (element) => element.name === "Informations du fiancé"
          )?.pivot?.last_name || ""
        )
        .replaceAll(
          "PRENOMS_FIANCE",
          app.marrieds?.find(
            (element) => element.name === "Informations du fiancé"
          )?.pivot?.given_names || ""
        )
        .replaceAll(
          "EMAIL_FIANCE",
          app.marrieds?.find(
            (element) => element.name === "Informations du fiancé"
          )?.pivot?.email || ""
        )
        .replaceAll("CONTACT_1_FIANCE", "(+225) 01 10 10 01")
        .replaceAll("CONTACT_2_FIANCE", "(+225) 01 01 11 01")

        .replaceAll(
          "PRENOMS_CELEBRE",
          app.marrieds?.find(
            (element) => element.name === "INFORMATION DU / DE LA CÉLÉBRÉE"
          )?.pivot?.given_names || ""
        )
        .replaceAll(
          "NOM_CELEBRE",
          app.marrieds?.find(
            (element) => element.name === "INFORMATION DU / DE LA CÉLÉBRÉE"
          )?.pivot?.last_name || ""
        )

        .replaceAll(
          "DATE_LIEU_DE_CÉRÉMONIE_ANNIVERSAIRE",
          moment(receptionAnniversaire?.pivot?.wedding_date).format(
            "dddd, MMMM Do YYYY"
          ) || ""
        )
        .replaceAll(
          "HEURE_LIEU_DE_CÉRÉMONIE_ANNIVERSAIRE",
          receptionAnniversaire?.pivot?.wedding_time || ""
        )
        .replaceAll(
          "LIEU_LIEU_DE_CÉRÉMONIE_ANNIVERSAIRE",
          receptionAnniversaire?.pivot?.wedding_location || ""
        )

        .replaceAll(
          "DATE_LIEU_DE_CÉRÉMONIE_BAPTEME",
          moment(receptionBapteme?.pivot?.wedding_date).format(
            "dddd, MMMM Do YYYY"
          ) || ""
        )
        .replaceAll(
          "HEURE_LIEU_DE_CÉRÉMONIE_BAPTEME",
          receptionBapteme?.pivot?.wedding_time || ""
        )
        .replaceAll(
          "LIEU_LIEU_DE_CÉRÉMONIE_BAPTEME",
          receptionBapteme?.pivot?.wedding_location || ""
        );

      let body = model.content
        .replaceAll("DATE_ENTERREMENT_DE_VIE_DE_JEUNE_FILLE", "01/12/2020")
        .replaceAll("HEURE_ENTERREMENT_DE_VIE_DE_JEUNE_FILLE", "20h00")
        .replaceAll(
          "LIEU_ENTERREMENT_DE_VIE_DE_JEUNE_FILLE",
          "Angré, Star 11, Abidjan, Côte d'ivoire"
        )

        .replaceAll("DATE_ENTERREMENT_DE_VIE_DE_JEUNE_GARCON", "01/12/2020")
        .replaceAll("HEURE_ENTERREMENT_DE_VIE_DE_JEUNE_GARCON", "20h00")
        .replaceAll(
          "LIEU_ENTERREMENT_DE_VIE_DE_JEUNE_GARCON",
          "Angré, Star 11, Abidjan, Côte d'ivoire"
        )

        .replaceAll("DATE_RECEPTION_DE_MARIAGE_CIVIL", "01/12/2020")
        .replaceAll("HEURE_RECEPTION_DE_MARIAGE_CIVIL", "08h00")
        .replaceAll("LIEU_RECEPTION_DE_MARIAGE_CIVIL", "Mairie de Cocody")

        .replaceAll("DATE_RECEPTION_DE_DOT", "01/12/2020")
        .replaceAll("HEURE_RECEPTION_DE_DOT", "10h00")
        .replaceAll(
          "LIEU_RECEPTION_DE_DOT",
          "Angré, Star 11, Abidjan, Côte d'ivoire"
        )

        .replaceAll("DATE_RECEPTION_DE_MARIAGE_RELIGIEUX", "01/12/2020")
        .replaceAll("HEURE_RECEPTION_DE_MARIAGE_RELIGIEUX", "12h00")
        .replaceAll(
          "LIEU_RECEPTION_DE_MARIAGE_RELIGIEUX",
          "Angré, Star 14, Abidjan, Côte d'ivoire"
        )

        .replaceAll("DATE_CEREMONIE_DE_MARIAGE_RELIGIEUX", "01/12/2020")
        .replaceAll("HEURE_CEREMONIE_DE_MARIAGE_RELIGIEUX", "12h00")
        .replaceAll(
          "LIEU_CEREMONIE_DE_MARIAGE_RELIGIEUX",
          "Paroisse Ste Cécile, Vallons"
        )

        .replaceAll("DATE_CEREMONIE_DE_DOT", "01/12/2020")
        .replaceAll("HEURE_CEREMONIE_DE_DOT", "08h30")
        .replaceAll(
          "LIEU_CEREMONIE_DE_DOT",
          "Angré, Star 14, Abidjan, Côte d'ivoire"
        );

      // Vérification avant d'accéder à app.dates.find
      mariageCivil = app.dates?.find(
        (element) => element.name === "Cérémonie de Mariage Civil"
      );
      receptionAnniversaire = app.dates?.find(
        (element) => element.name === "RÉCEPTION DE L'ANNIVERSAIRE"
      );
      receptionBapteme = app.dates?.find(
        (element) => element.name === "RÉCEPTION DE BAPTEME"
      );

      body = body
        .replaceAll(
          "DATE_CEREMONIE_DE_MARIAGE_CIVIL",
          moment(mariageCivil?.pivot?.wedding_date).format(
            "dddd, MMMM Do YYYY"
          ) || ""
        )
        .replaceAll(
          "HEURE_CEREMONIE_DE_MARIAGE_CIVIL",
          mariageCivil?.pivot?.wedding_time || ""
        )
        .replaceAll(
          "LIEU_CEREMONIE_DE_MARIAGE_CIVIL",
          mariageCivil?.pivot?.wedding_location || ""
        )

        .replaceAll("CIVILITE_INVITE", "M.")
        .replaceAll("NOM_INVITE", "Guépié")
        .replaceAll("PRENOMS_INVITE", "Freddy")
        .replaceAll("EMAIL_INVITE", "freddy.guepie@gmail.com")
        .replaceAll("CONTACT_1_INVITE", "(+225) 02 00 00 01")
        .replaceAll("CONTACT_2_INVITE", "(+225) 02 00 00 02")

        .replaceAll("CIVILITE_PCO", "M.")
        .replaceAll("NOM_PCO", "Doe")
        .replaceAll("PRENOMS_PCO", "Cedric")
        .replaceAll("EMAIL_PCO", "cedric.doe@gmail.com")
        .replaceAll("CONTACT_1_PCO", "(+225) 01 00 00 01")
        .replaceAll("CONTACT_2_PCO", "(+225) 01 00 00 01")

        .replaceAll("CIVILITE_FIANCEE", "Mlle")
        .replaceAll(
          "NOM_FIANCEE",
          app.marrieds?.find(
            (element) => element.name === "Informations de la fiancée"
          )?.pivot?.last_name || ""
        )
        .replaceAll(
          "PRENOMS_FIANCEE",
          app.marrieds?.find(
            (element) => element.name === "Informations de la fiancée"
          )?.pivot?.given_names || ""
        )
        .replaceAll(
          "EMAIL_FIANCEE",
          app.marrieds?.find(
            (element) => element.name === "Informations de la fiancée"
          )?.pivot?.email || ""
        )
        .replaceAll("CONTACT_1_FIANCEE", "(+225) 01 00 10 01")
        .replaceAll("CONTACT_2_FIANCEE", "(+225) 01 00 11 01")

        .replaceAll("CIVILITE_FIANCE", "M")
        .replaceAll(
          "NOM_FIANCE",
          app.marrieds?.find(
            (element) => element.name === "Informations du fiancé"
          )?.pivot?.last_name || ""
        )
        .replaceAll(
          "PRENOMS_FIANCE",
          app.marrieds?.find(
            (element) => element.name === "Informations du fiancé"
          )?.pivot?.given_names || ""
        )
        .replaceAll(
          "EMAIL_FIANCE",
          app.marrieds?.find(
            (element) => element.name === "Informations du fiancé"
          )?.pivot?.email || ""
        )
        .replaceAll("CONTACT_1_FIANCE", "(+225) 01 10 10 01")
        .replaceAll("CONTACT_2_FIANCE", "(+225) 01 01 11 01")

        .replaceAll(
          "PRENOMS_CELEBRE",
          app.marrieds?.find(
            (element) => element.name === "INFORMATION DU / DE LA CÉLÉBRÉE"
          )?.pivot?.given_names || ""
        )
        .replaceAll(
          "NOM_CELEBRE",
          app.marrieds?.find(
            (element) => element.name === "INFORMATION DU / DE LA CÉLÉBRÉE"
          )?.pivot?.last_name || ""
        )

        .replaceAll(
          "DATE_LIEU_DE_CÉRÉMONIE_ANNIVERSAIRE",
          moment(receptionAnniversaire?.pivot?.wedding_date).format(
            "dddd, MMMM Do YYYY"
          ) || ""
        )
        .replaceAll(
          "HEURE_LIEU_DE_CÉRÉMONIE_ANNIVERSAIRE",
          receptionAnniversaire?.pivot?.wedding_time || ""
        )
        .replaceAll(
          "LIEU_LIEU_DE_CÉRÉMONIE_ANNIVERSAIRE",
          receptionAnniversaire?.pivot?.wedding_location || ""
        )

        .replaceAll(
          "DATE_LIEU_DE_CÉRÉMONIE_BAPTEME",
          moment(receptionBapteme?.pivot?.wedding_date).format(
            "dddd, MMMM Do YYYY"
          ) || ""
        )
        .replaceAll(
          "HEURE_LIEU_DE_CÉRÉMONIE_BAPTEME",
          receptionBapteme?.pivot?.wedding_time || ""
        )
        .replaceAll(
          "LIEU_LIEU_DE_CÉRÉMONIE_BAPTEME",
          receptionBapteme?.pivot?.wedding_location || ""
        );

      app.displayMessage =
        app.channel.id == 3
          ? bodySMS
          : app.channel.id == 2
          ? bodyFacebook
          : body;

      app.message.body =
        app.channel.id == 3
          ? bodySMS
          : app.channel.id == 2
          ? bodyFacebook
          : body;

      if (
        app.channel.id == 3 &&
        app.message.body !== null &&
        app.message.body !== ""
      ) {
        app.messageLength = app.message.body.length;
        app.messageNumber = Math.ceil(app.messageLength / 160);
      }
    },

    goToDesignMaker: function () {
      let app = this;
      if (app.messagePerso) {
        app.message = {
          channel: app.channel,
          label: app.message.label,
          category: app.message.category,
          model: app.message.model,
          layout: null,
          from: app.message.from,
          subject: app.message.subject,
          body: app.messagePerso,
          targets: [],
          tab: [],
          messageNumber: 1,
          displayMessage: app.messagePerso,
        };
      } else {
        app.message = {
          channel: app.channel,
          label: app.message.label,
          category: app.message.category,
          model: app.message.model,
          layout: null,
          from: app.message.from,
          subject: app.message.subject,
          body: app.message.body,
          targets: [],
          tab: [],
          messageNumber: 1,
          displayMessage: app.displayMessage,
        };
      }
      // this.$store.state.message = app.message;
      this.$store.dispatch("updateMessage", app.message);
      app.$emit("go-to-design-maker");
    },
    // goToTargetChoice() {
    //   let app = this;
    //   if (app.messagePerso) {
    //     app.message = {
    //       channel: app.channel,
    //       label: app.message.label,
    //       category: app.message.category,
    //       model: app.message.model,
    //       layout: null,
    //       from: app.message.from,
    //       subject: app.message.subject,
    //       subject: app.message.subject,
    //       body: app.message.body,
    //       targets: [],
    //       tab: [],
    //       displayMessage: app.messagePerso,
    //       messageLength: app.channel.id === 3 ? app.messageLength : 1,
    //       messageNumber: app.channel.id === 3 ? app.messageNumber : 1,
    //       peopleCount: null,
    //       customerChannel : app.customerChannel
    //     };
    //   } else {
    //     app.message = {
    //       channel: app.channel,
    //       label: app.message.label,
    //       category: app.message.category,
    //       model: app.message.model,
    //       layout: null,
    //       from: app.message.from,
    //       subject: app.message.subject,
    //       body: app.message.body,
    //       targets: [],
    //       tab: [],
    //       displayMessage: app.displayMessage,
    //       messageLength: app.channel.id === 3 ? app.messageLength : 1,
    //       messageNumber: app.channel.id === 3 ? app.messageNumber : 1,
    //       peopleCount: null,
    //       customerChannel : app.customerChannel
    //     };
    //   }

    //   this.$emit("go-to-target-choice", app.message);
    // },
    getChannels() {
      let app = this;
      axios
        .get(
          COMMUNICATION.DEV_URL +
            API_PREFIX +
            API_VERSION +
            "customer-channels",
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then(function (response) {
          app.customerChannels = response.data;
          console.log(app.customerChannels);
          console.log(app.message);
          if (app.message.body) {
            app.customerChannels.forEach((item) => {
              if (item.id == app.message.channel.id) {
                app.customerChannel = item;
                app.message.customerChannel = app.customerChannel;
              }
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  computed: {
    isFormValid() {
      if (this.personnalise) {
        return this.message.category && this.messagePerso;
      } else {
        return this.message.category && this.message.model && this.message.body;
      }
    }
  }
};
</script>

<style>

.primary-button {
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
}

.primary-button:hover {
  background-color: #ffffff; /* Changement de couleur de fond */
  color: #ec0000; /* Changement de couleur du texte */
  transform: scale(1.02); 
  border: #b71c1c 1px solid;
  /* Légère augmentation de la taille */
}

</style>
