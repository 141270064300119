

const baseUrls = {
  DEV_URL: process.env.VUE_APP_PROVIDER_URL || "https://wedding-providers.joursdejoie.com/",
  TEST_URL: "https://wedding-providers-joursdejoie.paraclet-bk.com/",
  PROD_URL: "https://wedding-providers.joursdejoie.com/",
}
export const BASE_URL = baseUrls.DEV_URL;

const authBaseUrls = {
    DEV_URL: process.env.VUE_APP_AUTH_URL || "https://auth.joursdejoie.com/" ,
    TEST_URL: "https://authentication.joursdejoie.paraclet-bk.com/",
    PROD_URL: "https://auth.joursdejoie.com/",
};

export const AUTH_BASE_URL = authBaseUrls.DEV_URL;

const appBaseUrls = {
  DEV_URL: process.env.VUE_APP_PROVIDER_URL || "https://wedding-providers.joursdejoie.com/",
  TEST_URL: "https://wedding-providers-joursdejoie.paraclet-bk.com/",
  PROD_URL: "https://wedding-providers.joursdejoie.com/",
};

const APP_BASE_URL = appBaseUrls.DEV_URL;

export const TOWN_HALL = {
  DEV_URL: process.env.VUE_APP_TOWN_HALL_URL || "https://town-hall.joursdejoie.com/",
  TEST_URL: "https://town-hall.joursdejoie.paraclet-bk.com/",
  PROD_URL: "https://town-hall.joursdejoie.com/",
};


export const TOWN_HALL_BASE_URL = TOWN_HALL.DEV_URL;

export const STORAGE_BASE_URL = `${BASE_URL}/storage/`;

const reviewMock = (key) => ({
  name: "john doe " + key,
  path: "photos/default.png",
  mark: 5,
  title: "Lorem ipsum dolor sit amet",
  description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.",
  created_at: "2021-09-09"
})
export const reviewsMock = [1, 2, 3].map((key) => ({...reviewMock(key)}));

export const QUOTE_REQUEST_TYPE = {
  MESSAGE: 'MESSAGE',
  PHONE: 'PHONE',
  WEBSITE: 'WEBSITE',
  WHATSAPP: 'WHATSAPP',
};

export const LOGIN_URL = `${AUTH_BASE_URL}/login?app_id=CLIENT_SPACE&redirect=${APP_BASE_URL}/callback`