<template>
  <div id="app">
    <metainfo>
      <template v-slot:title="{ content }">{{ content ? `${content} | Jours De Joie` : `- Assistant digital d'organisation d'évènement heureux en Côte d'Ivoire, organisation de mariage à abidjan` }}</template>
    </metainfo>
    <component :is="layout" >
      <router-view></router-view>
    </component>
  </div>
</template>
<script>
// import './index.css'
const default_layout = "nuptial-notebook";

export default {
  computed : {
    layout(){
      
      return this.$route.meta.layout || default_layout;
    }
  },mounted(){
    
  },
  methods(){
    

    
  }
}
</script>
