<template>
  <div style="" class="w-full">
    <div v-if="medias && displayModal">
      <portfolio-modal-component
          @display-portfolio-modal="setDisplayModal"
          @quote-request-form-modal="sendRequestQuote"
          :provider="provider"
          :photos="photos"
          :videos="videos"
          :currentType="currentTypevalue"
          :medias="medias"></portfolio-modal-component>
    </div>
    <div class="w-full" @click.stop="setDisplayModal(true)">
      <div class="flex w-full" v-if="medias">
        <portfolio-preview-component :medias="medias" @quote-current-type="currentType"></portfolio-preview-component>
      </div>
    </div>
    
    <!-- <blockquote class="tiktok-embed" cite="https://www.tiktok.com/@cedric_anani/video/7276028507245776133" data-video-id="7276028507245776133" data-embed-from="oembed" style="max-width: 405px;min-width: 325px;" > <section> <a target="_blank" title="@cedric_anani" href="https://www.tiktok.com/@cedric_anani?refer=embed">@cedric_anani</a> <p>sois l'intercesseur d'une personne ou dun système  <a title="jesus" target="_blank" href="https://www.tiktok.com/tag/jesus?refer=embed">#jesus</a> <a title="niger" target="_blank" href="https://www.tiktok.com/tag/niger?refer=embed">#niger</a> <a title="abidjan" target="_blank" href="https://www.tiktok.com/tag/abidjan?refer=embed">#abidjan</a> <a title="cedricanani" target="_blank" href="https://www.tiktok.com/tag/cedricanani?refer=embed">#cedricanani</a> </p> <a target="_blank" title="♬ son original - Cedric Kouadio Anani" href="https://www.tiktok.com/music/son-original-7276028541643737862?refer=embed">♬ son original - Cedric Kouadio Anani</a> </section> </blockquote> <script async src="https://www.tiktok.com/embed.js"></script>" -->
  </div>
</template>

<script>
import PortfolioPreviewComponent from "@/views/wedding-provider/components/portfolio/porfolio-preview";
import PortfolioModalComponent from "@/views/wedding-provider/components/portfolio/portfolio-modal";

export default {
  name: "PortfolioComponent",
  components: {PortfolioPreviewComponent, PortfolioModalComponent},
  props: ['medias', 'provider', 'displayModalProps','photos','videos'],
  computed: {
  },
  data() {
    return {
      displayModal: false,
      currentTypevalue:null
    }
  },
  watch: {
    displayModalProps(next) {
      if(next) {
        this.setDisplayModal(next)
        this.$emit('portfolio-modal-opened')
      }
    }
  },
  mounted() {},
  methods: {
    setDisplayModal(value) {
      if (value) {
        document.getElementById("head").style.display = "none";
      } else {
        document.getElementById("head").style.display = "block";
      }
       
      this.displayModal = value
    },
    currentType(value) {
      
      this.currentTypevalue = value
    },
    sendRequestQuote(data) {
      this.$emit('quote-request', data)
    }
  }
}
</script>
