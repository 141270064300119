import { Vue3ProgressPlugin } from '@marcoschulte/vue3-progress';
import axios from 'axios';
import 'moment/locale/fr';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
const app = createApp(App);
const token = localStorage.getItem("token");
if (token) {
    axios.defaults.headers.common["Authorization"] = 'Bearer '+token;
}
//layout
import Auth from "@/layouts/Auth.vue";
import Error404 from "@/layouts/Error404.vue";
import JoyVisibility from "@/layouts/JoyVisibility.vue";
import Mairie from "@/layouts/Mairie.vue";
import NotitlePage from "@/layouts/NoTitlePage.vue";
import NuptialNotebook from "@/layouts/NuptialNotebook.vue";
import Provider from "@/layouts/Provider.vue";
import Shopping from "@/layouts/Shopping.vue";
import WeddingCard from "@/layouts/WeddingCard.vue";


//layouts
app.component("error404", Error404);
app.component("auth", Auth);
app.component("marketplace", Shopping);
app.component("mairie", Mairie);
app.component("nuptial-notebook", NuptialNotebook);
app.component("wedding-card", WeddingCard);
app.component("joyvisibility", JoyVisibility);
app.component("provider", Provider);
app.component("no-title-page", NotitlePage);

//common components
import ImageListInput from "@/components/common/inputs/imageListInput";
import MaskedInput from "@/components/common/inputs/maskedInput";
import SelectInput from "@/components/common/inputs/selectInput";
import TextInput from "@/components/common/inputs/textInput";
import TextareaInput from "@/components/common/inputs/textareaInput";
// common components
app.component("masked-input", MaskedInput);
app.component("select-input", SelectInput);
app.component("textarea-input", TextareaInput);
app.component("text-input", TextInput);
app.component("image-list-input", ImageListInput);

// import Toasted from "vue-toasted";
const toastedOptions = {
    position: "bottom-right",
    duration: 8000,
    action: {
        text: "Fermer",
        onClick: (e, toastObject) => {
            toastObject.goAway(0);
        }
    },
    className: "toastColor"
};

// app.use(Toasted, toastedOptions);
// app.toasted.register(
//     "brown",
//     playload => {
//         return playload.message;
//     },
//     toastedOptions
// );
// Titles
import SubTitle from "@/components/common/titles/SubTitle";
import Title from "@/components/common/titles/Title";
// Titles
app.component("big-title", Title);
app.component("sub-title", SubTitle);

import SavedModal from '@/components/common/ui/modal.vue';

app.component("saved-modal", SavedModal);

import MoonLoader from '@/components/common/ui/moonloader';
import spinner from '@/components/common/ui/spinner';
app.component("spinner", spinner);
app.component('moonloader', MoonLoader);
const progressOptions = {
    color: "#b71c1c",
    failedColor: "#874b4b",
    thickness: "3px",
    transition: {
        speed: "0.2s",
        opacity: "0.6s",
        termination: 400
    },
    autoRevert: true,
    location: "top",
    inverse: false,
    autoFinish: false
};
app.use(Vue3ProgressPlugin, progressOptions);
app.use(store)
app.use(router)

import DataTablesCore from 'datatables.net';
import DataTable from 'datatables.net-vue3';
import moshaToast, { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
import MoneySpinner from 'v-money-spinner';
app.use(moshaToast)
app.config.globalProperties.$createToast = createToast;
app.use(MoneySpinner)

DataTable.use(DataTablesCore);

// WEDDING PROVIDER
// import {
//     faStar,
//     faBars,
//     faEnvelope,
//     faMobileAlt,
//     faHeart as faHeartSolid,
//     faList,
//     faTh,
//     faSearch,
//     faXmark,
//     faCommentDollar,
//     faAngleLeft,
//     faAngleRight,
//     faShareNodes,
//     faThLarge,
//     faAngleUp,
//     faAngleDown,
//     faLocation,
//     faPlusCircle,
//     faUser,
//     faPaperPlane,
//     faPhone,
//     faEarthAfrica,
// } from '@fortawesome/free-solid-svg-icons'
// import {
//     faHeart as faHeartRegular,
// } from '@fortawesome/free-regular-svg-icons'
import Banner from '@/views/wedding-provider/components/banner';
import Breadcrumb from '@/views/wedding-provider/components/breadcrumb';
import Spinner from '@/views/wedding-provider/components/common/spinner';
import Footer from '@/views/wedding-provider/components/footer';
import Nav from '@/views/wedding-provider/components/nav/nav';
import NavItem from '@/views/wedding-provider/components/nav/nav-list';
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/fontawesome-free';
// import { vModelSelect } from 'vue';
// import { vModelSelect } from 'vue';


import AddressItem from '@/views/wedding-provider/components/address/address-item';
import AddressList from '@/views/wedding-provider/components/address/address-list';

import CommentForm from '@/views/wedding-provider/components/comment/comment-form';
import CommentItem from '@/views/wedding-provider/components/comment/comment-item';
import CommentList from '@/views/wedding-provider/components/comment/comment-list';

import CardItem from '@/views/wedding-provider/components/card/card-item';
import CardList from '@/views/wedding-provider/components/card/card-list';
import CardListHorizontal from '@/views/wedding-provider/components/card/card-list-horizontal.vue'

import FilterItemPrice from '@/views/wedding-provider/components/filter/filter-item-price';
import FilterItemReview from '@/views/wedding-provider/components/filter/filter-item-review';
import FilterItemServiceType from "@/views/wedding-provider/components/filter/filter-item-service-type";
import FilterItemTown from '@/views/wedding-provider/components/filter/filter-item-town';
import FilterList from '@/views/wedding-provider/components/filter/filter-list';

import FavPillComponent from "@/views/wedding-provider/components/common/fav-pill.vue";
import PillComponent from "@/views/wedding-provider/components/pill/pill.vue";

import ReviewList from '@/views/wedding-provider/components/review/review-list';
import ReviewMarkList from '@/views/wedding-provider/components/review/review-mark-list';
import ReviewStars from '@/views/wedding-provider/components/review/review-stars';

import ContactForm from '@/views/wedding-provider/components/contact-form';
import Pagination from '@/views/wedding-provider/components/pagination';
import Portfolio from '@/views/wedding-provider/components/portfolio/portfolio';
// library.add(
//     faStar,
//     faBars,
//     faEnvelope,
//     faMobileAlt,
//     faHeartRegular,
//     faHeartSolid,
//     faList,
//     faTh,
//     faSearch,
//     faXmark,
//     faCommentDollar,
//     faAngleLeft,
//     faAngleRight,
//     faAngleUp,
//     faAngleDown,
//     faShareNodes,
//     faThLarge,
//     faLocation,
//     faPlusCircle,
//     faTh,
//     faUser,
//     faPaperPlane,
//     faPhone,
//     faEarthAfrica,
// );

import VueAnalytics from "vue-ua";

app.use(VueAnalytics, {
  appName: "Jours de Joie", // Mandatory
  appVersion: "1.2", // Mandatory
  trackingId: "UA-149591621-5", // Mandatory
  debug: false, // Whether or not display console logs debugs (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  ignoredViews: [], // If router, you can exclude some routes name (case insensitive) (optional)
  trackPage: true // Whether you want page changes to be recorded as pageviews (website) or screenviews (app), default: false
});

import { createMetaManager } from 'vue-meta';
app.use(createMetaManager());


app.component('font-awesome-icon', FontAwesomeIcon);
app.component('breadcrumb-component', Breadcrumb);
app.component('banner-component', Banner);
app.component('contact-form-component', ContactForm);
app.component('footer-component', Footer);
app.component('nav-component', Nav);
app.component('nav-list-component', NavItem);
app.component('portfolio-component', Portfolio);
app.component('address-list-component', AddressList);
app.component('address-item-component', AddressItem);
app.component('card-list-horizontal-component', CardListHorizontal);
app.component('card-list-component', CardList);
app.component('card-item-component', CardItem);
app.component('comment-list-component', CommentList);
app.component('comment-item-component', CommentItem);
app.component('comment-form-component', CommentForm);
app.component('filter-list-component', FilterList);
app.component('filter-item-town-component', FilterItemTown);
app.component('filter-item-review-component', FilterItemReview);
app.component('filter-item-price-component', FilterItemPrice);
app.component('filter-item-service-type-component', FilterItemServiceType);
app.component('review-stars-component', ReviewStars);
app.component('review-list-component', ReviewList);
app.component('review-mark-list-component', ReviewMarkList);
app.component('pagination-component', Pagination);
app.component('font-awesome-layers', FontAwesomeLayers);
app.component('spinner-component', Spinner);
app.component('pill-component', PillComponent);
app.component('fav-pill-component', FavPillComponent);

import ChannelChoiceComponent from "@/components/communication/ChannelChoiceComponent.vue";
import DesignMakerComponent from "@/components/communication/DesignMakerComponent.vue";
import MessageBuilderComponent from "@/components/communication/MessageBuilderComponent.vue";
import MessageSummaryComponent from "@/components/communication/MessageSummaryComponent.vue";
// import ModeChoiceComponent from "@/components/communication/ModeChoiceComponent.vue";
// import ModelCardChoiceComponent from "@/components/communication/ModelCardChoiceComponent.vue";
import PaymentComponent from "@/components/communication/PaymentComponent.vue";
import TargetChoiceComponent from "@/components/communication/TargetChoiceComponent.vue";

app.component('channel-choice-component', ChannelChoiceComponent);
app.component('message-builder-component', MessageBuilderComponent);
app.component('design-maker-component', DesignMakerComponent);
app.component('target-choice-component', TargetChoiceComponent);
app.component('message-summary-component', MessageSummaryComponent);
app.component('payment-component', PaymentComponent);
// app.component('mode-choice-component',ModeChoiceComponent);
// app.component('model-choice-component',ModelCardChoiceComponent);

import CKEditor from '@ckeditor/ckeditor5-vue';
app.use(CKEditor)
app.config.globalProperties.$http = axios;
app.mount('#app')