<template>
  <div style="width: 100%; background-color: #fff;">
    <form
      style="
        margin: 0 auto;
        padding: 0 40px;
        height: 100vh;
        background-image: url('/images/backgrounds/auth/auth.png');
        background-size: contain;
        z-index: -999;
      "
      class=""
      id="form-input"
      @submit.prevent="onSubmit"
    >
      <div class="row" style="background-color: #fff; z-index: 999;padding: 0 40px" >
        <div class="col-md-12">
          <div style="text-align: center" class="pt-5 pb-4">
            <h3 class="font-weight-light" v-if="!btnInscriptionClicked">
              Inscription
            </h3>
            <h3 class="font-weight-light" v-else>
              VERIFICATION OTP
            </h3>
          </div>
        </div>

        <div
          class="col-md-12"
          v-if="loginType === 'telephone' && btnInscriptionClicked"
        >
          <div class="col-md-12">
            <div class="col-md-12">
              <OtpInput @complete="handleOtpComplete"></OtpInput>
            </div>

            <div class="pt-4 col-md-12 pb-3" v-if="otp_success">
              <OtpCountdownTimer @time-up="handleOtpTimeout"></OtpCountdownTimer>
            </div>
            
            <div class="pt-4 col-md-12 pb-3">
              <div class="form-group text-center">
                <button
                  v-if="!isLoading && !otp_success"
                  class="btn btn-primary btn-block"
                  style="border-radius: 30px; padding: 10px"
                  @click="resendOtpCode"
                >
                  Renvoyer Code OTP
                </button>
                <button
                  v-if="isLoading"
                  class="btn btn-primary btn-block"
                  style="border-radius: 30px; padding: 10px"
                  disabled
                >
                  <spinner size="small" line-fg-color="#fff" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-if="btnInscriptionClicked == false">
          <div class="col-md-12">
            <validation-errors
              :errors="validationErrors"
              v-if="validationErrors"
            ></validation-errors>
          </div>

          <div class="col-md-12">
            <ul
              class="nav nav-pills mb-3 justify-content-center"
              id="pills-tab"
              style="color: gray"
              role="tablist"
            >
              <div
                class="d-flex"
                id="pills-tab"
                role="tablist"
                style="background-color: #f4f4f5; border-radius: 5px"
              >
                <li class="nav-item">
                  <a
                    @click="changeLoginType('email')"
                    class="nav-link active"
                    id="pills-home-tab"
                    data-toggle="pill"
                    href="#pills-home"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    <i class="fa fa-envelope" aria-hidden="true"></i>
                    Authentification par Email</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    @click="changeLoginType('telephone')"
                    class="nav-link"
                    id="pills-profile-tab"
                    data-toggle="pill"
                    href="#pills-profile"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    <i class="fa fa-mobile" aria-hidden="true"></i>
                    Authentification par No telephone</a
                  >
                </li>
              </div>
            </ul>
          </div>

          <div class="col-md-12 pb-3">
            <div class="form-group">
              <select
                class="col-12 auth-input"
                v-model="user.civility"
                required
              >
                <option value="" selected disabled>Civilité *</option>
                <option value="Monsieur">Monsieur</option>
                <option value="Madame">Madame</option>
                <option value="Mademoiselle">Mademoiselle</option>
              </select>
            </div>
          </div>
          <div class="col-md-6 pb-3">
            <div class="form-group">
              <input
                v-model="user.given_names"
                class="col-12 auth-input"
                type="text"
                placeholder="Prénoms *"
                required
              />
            </div>
          </div>
          <div class="col-md-6 pb-3">
            <div class="form-group">
              <input
                v-model="user.last_name"
                class="col-12 auth-input"
                type="text"
                placeholder="Nom *"
                required
              />
            </div>
          </div>

          <div class="col-md-12 pb-3">
            <div class="d-flex col-md-12">
              <label for=""></label>
              <multiselect
                :multiple="false"
                v-model="codeselected"
                :close-on-select="true"
                placeholder="-- Pays --"
                :options="codes"
                track-by="name"
                label="name"
                class="col-md-6"
                :show-labels="false"
                :allow-empty="true"
              >
                <template>
                  <img :src="option.flag" width="5%" />
                  <span>
                    {{ option.name }}
                  </span>
                </template>
              </multiselect>
              <div>
                <masked-input
                  label=""
                  :name="'phone_number_2' + key"
                  type="text"
                  :rules="'min:8|max:10'"
                  :isRequired="true"
                  v-model="user.phone_number_1"
                  placeholder="No de telephone"
                  :mask="'00 00 00 00 00'"
                />
              </div>
            </div>
          </div>
          <div class="col-md-6 pb-3">
            <div class="form-group">
              <input
                v-model="user.email"
                class="auth-input"
                type="email"
                name="email"
                @blur="validateEmail"
                placeholder="Adresse email "
              />
              <!-- <span v-if="error" class="text-danger">Email incorrect</span> -->
            </div>
          </div>
          <div class="col-md-6 pb-3">
            <div class="form-group">
              <input
                v-model="user.password"
                class="col-11 auth-input"
                :type="showPassword ? 'text' : 'password'"
                placeholder="Mot de passe *"
                required
              />
              <button
                class="col-1 btn btn-sm btn-link"
                type="button"
                @click="passwordInputManagement"
              >
                <i v-if="showPassword" class="fa fa-eye-slash"></i>
                <i v-else class="fa fa-eye"></i>
              </button>
            </div>
          </div>
          <div class="col-md-12 row pb-4">
            <div class="col-md-6">
              <label for=""
                >Date de naissance <span class="text-danger">*</span></label
              >
              <input
                v-model="user.dob"
                class="auth-input"
                type="date"
                placeholder="Date de naissance *"
                :max="moment().subtract(18, 'year').format('YYYY-MM-DD')"
                required
              />
            </div>
            <div class="col-md-6 pt-3">
              <multiselect
                :multiple="false"
                v-model="providerselected"
                :close-on-select="true"
                placeholder="-- Evènement --"
                :options="eventTypes"
                track-by="name"
                label="name"
                :show-labels="false"
                :allow-empty="true"
              >
              </multiselect>
            </div>
          </div>

          <div class="col-md-6 pb-3">
            <div class="col-12 form-group">
              <label for="">
                Date de d'évènement <span class="text-danger">*</span>&nbsp;
                <!-- (mariage,anniversaire,bapteme,brunch,ceremonie) -->
                <vue-toggle
                  :title="exactDateType ? ' exacte' : 'approximative'"
                  name="nom"
                  toggled="true"
                  fontSize="11px"
                  v-model="exactDateType"
                  @toggle="(value) => (exactDateType = value)"
                />
              </label>
              <input
                v-if="exactDateType"
                v-model="user.wedding_date"
                class="auth-input"
                type="date"
                placeholder="Date de d'évènement *"
                :min="moment().format('YYYY-MM-DD')"
                :max="moment().add(5, 'year').format('YYYY-MM-DD')"
                required
              />
              <div v-else>
                <div class="input-group">
                  <input
                    v-model="user_wedding_date_number"
                    type="number"
                    placeholder="xx"
                    class="col-md-3 auth-input"
                    required
                  />
                  <select
                    v-model="user_wedding_date_surfix"
                    name=""
                    id=""
                    placeholder="jour,mois,semaine,année"
                    class="col-md-9 auth-input"
                    required
                  >
                    <option value="weeks">Semaine(s)</option>
                    <option value="months">Mois</option>
                    <option value="years" selected>Année(s)</option>
                  </select>
                </div>
                <small class="form-text text-muted"> Exemple: 6 Mois </small>
              </div>
            </div>
          </div>
          <div class="col-md-12 pb-3">
            <div class="form-group">
              <label for="cgu-checkbox">
                <input id="cgu-checkbox" type="checkbox" required />&nbsp;
                J'accepte toutes les
                <a
                  href="/cgu-organisation-de-mariage-cote-d-ivoire"
                  target="_blank"
                  class="text-danger"
                  >conditions générales d'utilisation</a
                >
                de Jours de Joie.
              </label>
              <label for="newsletter-checkbox">
                <input type="checkbox" id="newsletter-checkbox" />&nbsp; Je
                souhaite m'inscrire à la newsletter de Jours de Joie.
              </label>
            </div>
          </div>

          <div class="col-md-12 pb-3">
            <div class="form-group text-center">
              <button
                type="submit"
                class="btn btn-primary btn-block"
                style="border-radius: 5px; padding: 10px"
                v-if="!isLoading"
              >
                INSCRIPTION
              </button>
              <button
                type="submit"
                class="btn btn-primary btn-block"
                style="border-radius: 30px; padding: 10px"
                disabled
                v-else
              >
                <spinner size="small" line-fg-color="#fff" />
              </button>
            </div>
          </div>
          <div class="col-md-12 text-center pb-3">
            Retourner à la page de
            <a
              href="javascript:void(0);"
              style="color: #b71c1c; font-weight: 900"
              @click="$router.push('/connexion')"
              >Connexion</a
            >
            ?
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import ValidationErrors from "@/components/common/ValidationErrors";
import { API_PREFIX, API_VERSION, NUPTIAL_NOTEBOOK } from "@/config";
import axios from "axios";
import moment from "moment";
import multiselect from "vue-multiselect";
import VueToggle from "vue-toggle-component";
// import "vue-multiselect/dist/vue-multiselect.min.css";
import { IMaskDirective } from "vue-imask";
// import VeeValidate from 'vee-validate';
// Vue.use(VeeValidate);
import OtpInput from "@/components/otp-input.vue";
import OtpCountdownTimer from "@/components/otp-timer.vue";

export default {
  name: "Register",
  components: {
    ValidationErrors,
    multiselect,
    VueToggle,
    OtpInput,
    OtpCountdownTimer,
  },
  data: function () {
    return {
      moment: moment,
      mask: {
        mask: "{8} 00 00 00 00 00",
        lazy: false,
      },
      loginType: "email",
      btnInscriptionClicked: false,
      user: {
        civility: "",
        last_name: null,
        given_names: null,
        phone_number_1: null,
        phone_number_formatted: null,
        email: null,
        password: null,
        dob: null,
        event_type_id: null,
        wedding_date: null,
        type: "customer",
      },
      otp_success: false,
      codes: [],
      eventTypes: [
        {
          id: "Mariage",
          name: "Mariage",
        },
        {
          id: "Anniversaire",
          name: "Anniversaire",
        },
        {
          id: "Bapteme",
          name: "Bapteme",
        },
        {
          id: "Brunch",
          name: "Brunch",
        },
        {
          id: "Baby Shower",
          name: "Baby Shower",
        },
        {
          id: "Fête religieuse",
          name: "Fête religieuse",
        },
        {
          id: "Divertissement",
          name: "Divertissement",
        },
      ],
      providerselected: null,
      codeselected: {
        name: "Côte d'Ivoire",
        flag: "https://flagcdn.com/ci.svg",
        callingCodes: "225",
      },
      isLoading: false,
      validationErrors: "",
      exactDateType: true,
      user_wedding_date_number: null,
      user_wedding_date_surfix: null,
      showPassword: false,
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      error: false,
      // countTime:120,
    };
  },
  mounted() {
    this.codeCountries();
    this.getEventType();
  },
  computed: {},
  directives: {
    imask: IMaskDirective,
  },
  methods: {
    getEventType() {
      this.isLoading = true;
      axios
        .get(
          NUPTIAL_NOTEBOOK.DEV_URL + API_PREFIX + API_VERSION + "get-event-type"
        )
        .then((response) => {
          console.log("Event Type : ", response.data);
          this.eventTypes = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log("error", error.response.data);
          this.isLoading = false;
        });
    },
    handleOtpTimeout() {
      this.otp_success = false;
    },
    
    handleOtpComplete(otpCode) {
      this.user.code = otpCode;
      this.registerNow();
    },
    
    validateEmail() {
      if (this.reg.test(this.user.email)) {
        this.error = false;
      } else {
        this.error = true;
      }
    },
    registerNow() {
      this.$store
        .dispatch("register", this.user)
        .then((response) => {
          if (this.user.type == "customer") {
            this.$store
              .dispatch("login", this.user)
              .then(() => {
                this.isLoading = false;
                this.$createToast(
                  {
                    title: "Operation reussie !",
                    description: "Inscription effectuée avec succès !",
                  },
                  {
                    type: "success",
                    timeout: 10000,
                    hideProgressBar: "true",
                    transition: "bounce",
                  }
                );
                window.location.href =
                  "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/en-bref";
              })
              .catch(() => {});
          } else {
            window.location.href = "https://joursdejoie.com/connexion";
          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
          if (error.response == undefined) {
            this.$createToast(
              {
                title: "Erreur lors de l'inscription!",
                description: error,
              },
              {
                // position: 'top-center',
                type: "danger",
                timeout: 10000,
                hideProgressBar: "true",
                transition: "bounce",
              }
            );
            return;
          } else {
            if (error.response.status == 422) {
              this.validationErrors = error.response.data.errors;
            } else {
              this.errors = error.response.data.errors;
            }
          }
        });
    },
    weddingDateFormatted() {
      let myDate = null;
      if (this.exactDateType == false) {
        let futureDate = moment()
          .add(
            Number(this.user_wedding_date_number),
            this.user_wedding_date_surfix
          )
          .calendar();

        myDate = moment(futureDate).format("YYYY-MM-DD");
      }
      return myDate;
    },
    onAccept(e) {
      const maskRef = e.detail;
      this.value = maskRef.value;
    },
    onComplete(e) {
      const maskRef = e.detail;
    },
    resendOtpCode() {
      let app = this;
      this.otp_success = false;
      this.isLoading = true;
      this.$store
        .dispatch("sendOtp", this.user)
        .then((response) => {
          app.isLoading = false;
          app.otp_success = true;
          console.log("Otp success ", app.otp_success);

          this.$createToast(
            {
              title: "Code OTP envoyé !",
              description: "Veuillez vérifier votre téléphone",
            },
            {
              showIcon: "true",
              showCloseButton: "true",
              hideProgressBar: "true",
              swipeClose: "true",
            }
          );
        })
        .catch(() => {
          app.isLoading = false;
          app.otp_success = false;
          console.log("Otp fail ", app.otp_success);
        });
    },
    passwordInputManagement() {
      this.showPassword = !this.showPassword;
    },

    // onSubmit() {
    //   this.isLoading = true;
    //   let app = this;
    //   const regexPhone = /^0[1-9]\d{8}$/;
    //   const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    //   if (this.user.phone_number_1 != null) {
    //     this.user.phone_number_1 = this.user.phone_number_1.replace(/\s/g, "");
    //   }
    //   if((this.user.phone_number_1==null || this.user.phone_number_1.length==0) && (this.user.email==null ||this.user.email.length==0)){

    //     this.$createToast(
    //         {
    //           title: "Informations incorrectes",
    //           description: "Email ou téléphone requis",
    //         },
    //         {
    //           showIcon: "true",
    //           showCloseButton: "true",
    //           hideProgressBar: "true",
    //           swipeClose: "true",
    //         }
    //       );
    //   }
    //   console.log(this.user.phone_number_1, this.user.email);
    //   if (
    //     (regexPhone.test(this.user.phone_number_1) == false &&
    //       this.user.phone_number_1 != null) ||
    //     (regexEmail.test(this.user.email) == false && this.user.email != null)
    //   ) {
    //     if (
    //       regexEmail.test(this.user.email) == false &&
    //       this.user.email != null
    //     ) {
    //       this.$createToast(
    //         {
    //           title: "Informations incorrectes",
    //           description: "Vérifiez votre Email",
    //         },
    //         {
    //           showIcon: "true",
    //           showCloseButton: "true",
    //           hideProgressBar: "true",
    //           swipeClose: "true",
    //         }
    //       );
    //       this.isLoading = false;

    //       return;
    //     }
    //     if (
    //       regexEmail.test(this.user.phone_number_1) == false &&
    //       this.user.phone_number_1 != null
    //     ) {
    //       this.$createToast(
    //         {
    //           title: "Informations incorrectes",
    //           description: "Vérifiez votre numéro de téléphone",
    //         },
    //         {
    //           showIcon: "true",
    //           showCloseButton: "true",
    //           hideProgressBar: "true",
    //           swipeClose: "true",
    //         }
    //       );
    //       this.isLoading = false;

    //       return;
    //     }
    //     this.isLoading = false;
    //   } else {
    //     console.log(this.providerselected);
    //     this.validationErrors = null;

    //     if (this.providerselected == null) {
    //       this.$createToast(
    //         {
    //           title: "Informations manquantes",
    //           description: "Veuillez selectionner un type d'evenement!",
    //         },
    //         {
    //           showIcon: "true",
    //           showCloseButton: "true",
    //           hideProgressBar: "true",
    //           swipeClose: "true",
    //         }
    //       );
    //       this.isLoading = false;
    //       return;
    //     } else {
    //       this.user.event_type_id = this.providerselected.id;
    //       this.user.phone_number_formatted =
    //         "+" +
    //         this.codeselected.callingCodes +
    //         "" +
    //         this.user.phone_number_1;
    //         this.user.phone_number_1 = this.user.phone_number_formatted
    //       this.user.wedding_date =
    //         this.exactDateType == true
    //           ? this.user.wedding_date
    //           : this.weddingDateFormatted();

    //       if (this.loginType == "telephone") {
    //         app.btnInscriptionClicked = true;
    //         app.user.code="";
    //         app.otp_success=false
    //         app.isLoading = true;
    //         console.log('Otp sending ',app.otp_success)

    //         this.$store
    //           .dispatch("sendOtp", this.user)
    //           .then((response) => {
    //             app.isLoading = false;
    //             app.otp_success=true;
    //             console.log('Otp success ',app.otp_success)

    //             this.$createToast(
    //               {
    //                 title: "Code OTP envoyé !",
    //                 description: "Veuillez vérifier votre téléphone",
    //               },
    //               {
    //                 showIcon: "true",
    //                 showCloseButton: "true",
    //                 hideProgressBar: "true",
    //                 swipeClose: "true",
    //               }
    //             );
    //           })
    //           .catch(() => {
    //             app.isLoading = false;
    //             app.otp_success=false;
    //             console.log('Otp fail ',app.otp_success)

    //           });
    //           return;
    //       }

    //       this.$store
    //         .dispatch("register", this.user)
    //         .then((response) => {
    //           if (this.user.type == "customer") {
    //             this.$store
    //               .dispatch("login", this.user)
    //               .then(() => {
    //                 this.isLoading = false;
    //                 this.$createToast(
    //                   {
    //                     title: "Operation reussie !",
    //                     description: "Inscription effectuée avec succès !",
    //                   },
    //                   {
    //                     type: "success",
    //                     timeout: 10000,
    //                     hideProgressBar: "true",
    //                     transition: "bounce",
    //                   }
    //                 );
    //                 window.location.href =
    //                   "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/en-bref";
    //               })
    //               .catch(() => {});
    //           } else {
    //             window.location.href = "https://joursdejoie.com/connexion";
    //           }
    //         })
    //         .catch((error) => {
    //           this.isLoading = false;
    //           console.log(error);
    //           if (error.response == undefined) {
    //             this.$createToast(
    //               {
    //                 title: "Erreur lors de l'inscription!",
    //                 description: error,
    //               },
    //               {
    //                 // position: 'top-center',
    //                 type: "danger",
    //                 timeout: 10000,
    //                 hideProgressBar: "true",
    //                 transition: "bounce",
    //               }
    //             );
    //             return;
    //           } else {
    //             if (error.response.status == 422) {
    //               this.validationErrors = error.response.data.errors;
    //             } else {
    //               this.errors = error.response.data.errors;
    //             }
    //           }
    //         });
    //     }
    //   }
    // },

    onSubmit() {
      this.isLoading = true;
      let app = this;

      // Remove spaces from phone number if exists
      if (this.user.phone_number_1 != null) {
        this.user.phone_number_1 = this.user.phone_number_1.replace(/\s/g, "");
      }

      // Regular expressions for validation
      const regexPhone = /^0[1-9]\d{8}$/;
      const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const regexPassword =/^0[1-9]/;

      // Check if at least one of email or phone is provided
      if (
        (!this.user.phone_number_1 || this.user.phone_number_1.length === 0) &&
        (!this.user.email || this.user.email.length === 0)
      ) {
        this.showErrorToast(
          "Email ou téléphone requis",
          "Informations manquantes"
        );
        this.isLoading = false;
        return;
      }

      // Validate email if provided
      if (this.user.email && !regexEmail.test(this.user.email)) {
        this.showErrorToast(
          "Veuillez entrer une adresse email valide",
          "Email invalide"
        );
        this.isLoading = false;
        return;
      }

      // Validate phone if provided
      if (
        this.user.phone_number_1 &&
        !(this.user.phone_number_1.length>=10)
      ) {
        this.showErrorToast(
          "Veuillez entrer un numéro de téléphone valide (ex: 0787032109)",
          "Téléphone invalide"
        );
        this.isLoading = false;
        return;
      }

      // Check required fields
      if (!this.user.civility) {
        this.showErrorToast(
          "Veuillez sélectionner une civilité",
          "Civilité manquante"
        );
        this.isLoading = false;
        return;
      }

      if (!this.user.given_names) {
        this.showErrorToast("Veuillez entrer votre prénom", "Prénom manquant");
        this.isLoading = false;
        return;
      }

      if (!this.user.last_name) {
        this.showErrorToast("Veuillez entrer votre nom", "Nom manquant");
        this.isLoading = false;
        return;
      }

      if (!this.user.password) {
        this.showErrorToast(
          "Veuillez entrer un mot de passe",
          "Mot de passe manquant"
        );
        this.isLoading = false;
        return;
      }

      // Enhanced password validation
      if ((this.user.password.length<3)) {
        this.showErrorToast(
          "Le mot de passe doit contenir au moins 3 caractères",
          "Mot de passe faible"
        );
        this.isLoading = false;
        return;
      }

      if (!this.user.dob) {
        this.showErrorToast(
          "Veuillez entrer votre date de naissance",
          "Date de naissance manquante"
        );
        this.isLoading = false;
        return;
      }

      // Check if user is at least 18 years old
      const dob = moment(this.user.dob);
      const age = moment().diff(dob, "years");
      if (age < 18) {
        this.showErrorToast(
          "Vous devez avoir au moins 18 ans pour vous inscrire",
          "Âge insuffisant"
        );
        this.isLoading = false;
        return;
      }

      if (!this.providerselected) {
        this.showErrorToast(
          "Veuillez sélectionner un type d'événement",
          "Événement manquant"
        );
        this.isLoading = false;
        return;
      }

      // Validate wedding date
      if (this.exactDateType && !this.user.wedding_date) {
        this.showErrorToast(
          "Veuillez sélectionner une date d'événement",
          "Date d'événement manquante"
        );
        this.isLoading = false;
        return;
      }

      if (
        !this.exactDateType &&
        (!this.user_wedding_date_number || !this.user_wedding_date_surfix)
      ) {
        this.showErrorToast(
          "Veuillez spécifier la période approximative de votre événement",
          "Période manquante"
        );
        this.isLoading = false;
        return;
      }

      // Check CGU checkbox
      const cguCheckbox = document.getElementById("cgu-checkbox");
      if (!cguCheckbox.checked) {
        this.showErrorToast(
          "Veuillez accepter les conditions générales d'utilisation",
          "CGU non acceptées"
        );
        this.isLoading = false;
        return;
      }

      // If all validations pass, proceed with form submission
      this.validationErrors = null;
      this.user.event_type_id = this.providerselected.id;
      this.user.phone_number_formatted =
        "+" + this.codeselected.callingCodes + this.user.phone_number_1;
      this.user.phone_number_1 = this.user.phone_number_formatted;
      this.user.wedding_date = this.exactDateType
        ? this.user.wedding_date
        : this.weddingDateFormatted();

      // Handle phone verification flow
      if (this.loginType === "telephone") {
        app.btnInscriptionClicked = true;
        app.user.code = "";
        app.otp_success = false;
        app.isLoading = true;

        this.$store
          .dispatch("sendOtp", this.user)
          .then((response) => {
            app.isLoading = false;
            app.otp_success = true;
            this.showSuccessToast(
              "Code OTP envoyé !",
              "Veuillez vérifier votre téléphone"
            );
          })
          .catch(() => {
            app.isLoading = false;
            app.otp_success = false;
            this.showErrorToast("Échec de l'envoi du code OTP", "Erreur");
          });
        return;
      }

      // Handle email registration flow
      this.$store
        .dispatch("register", this.user)
        .then((response) => {
          if (this.user.type === "customer") {
            this.$store
              .dispatch("login", this.user)
              .then(() => {
                this.isLoading = false;
                this.showSuccessToast(
                  "Inscription effectuée avec succès !",
                  "Opération réussie"
                );
                window.location.href =
                  "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/en-bref";
              })
              .catch(() => {});
          } else {
            window.location.href = "https://joursdejoie.com/connexion";
          }
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response && error.response.status === 422) {
            this.validationErrors = error.response.data.errors;
          } else {
            this.showErrorToast(
              error.response?.data?.message || "Erreur lors de l'inscription",
              "Erreur"
            );
          }
        });
    },

    // Helper method to show error toasts
    showErrorToast(description, title) {
      this.$createToast(
        { title, description },
        {
          showIcon: true,
          showCloseButton: true,
          hideProgressBar: true,
          swipeClose: true,
          type: "danger",
        }
      );
    },

    // Helper method to show success toasts
    showSuccessToast(description, title) {
      this.$createToast(
        { title, description },
        {
          showIcon: true,
          showCloseButton: true,
          hideProgressBar: true,
          swipeClose: true,
          type: "success",
        }
      );
    },
    goToLogin: function () {
      this.$router.push("/connexion");
    },

    codeCountries() {
      let app = this;
      axios
        .get("https://restcountries.com/v3.1/all")
        .then((response) => {
          response.data.forEach((element) => {
            element.name = element.name.common;
            element.flag = element.flags.svg;
            if (element.idd.suffixes) {
              element.callingCodes = element.idd.root + element.idd.suffixes[0];
            } else {
              element.callingCodes = element.idd.root + element.idd;
            }
            // element.callingCodes =element.idd.root+element.idd.suffixes[0]
          });

          app.codes = response.data;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    changeLoginType(type) {
      this.loginType = type;
    },
  },
};
</script>
<style scoped>
.nav-pills .nav-link.active {
  background-color: rgba(212, 53, 53, 0.9);
}

.nav-item {
  padding: 5px;
  background-color: #f4f4f5;
  border-radius: 5px;
}

p.category {
  display: none;
}

.wizard-title {
  display: none;
}
.wizard-header {
  display: none;
}
.wizard-tab-content {
  padding: 0px !important;
}
body {
  overflow-y: auto;
}
</style>
