<template>
  <div class="mt-6">
    <div class="container p-3" v-if="isLoading == false && isSending == false">
      <big-title label="JOY CARDS" />
      <br />
      <br />
      <div style="box-shadow: 10px 12px 71px -23px rgba(176,176,176,0.6); margin: 15px 0 40px 0;
" class="message-summary-container p-4" v-if="this.$store.state.message.file == null">
        <div class="row">
          <div class="col-md-12">
            <sub-title label="Résumé de votre envoi" gender="F" />
          </div>
        </div>
        <div class="row px-3 mt-5" style="position: relative; z-index: 1" v-if="message != null || isLoading == true">
          <!-- <div
            :style="'position:absolute;z-index:-1;top:-15%;bottom:0;left:75%;right:0;background: url(/images/communication/wedding-planning.png) no-repeat;background-size:300px;background-repeat:no-repeat;opacity:0.2;'">
          </div> -->
          <div class="col-md-6">
            <div class="col-md-12">
              <div class="form-group" v-if="message.customerChannel != null">
                <label for>Canal</label>
                <input type="text" class="form-control" :value="message.channel.name" readonly />
              </div>
            </div>

            <div class="col-md-12" v-if="message.targets && message.targets.length > 0">
              <div  class="form-group">
                <label for>Cible</label>
                <br />
                <span v-for="(target, key) in message.targets" :key="key" style="margin-left: 5px; margin-right: 5px; background-color: #EFF6FE;"
                  class="badge badge-primary">
                  <div style="margin: 10px; color: #355EDA; font-size: 13px;">{{ target.name }}</div>
                </span>
              </div>
            </div>

            <div class="col-md-12">
              <div  class="form-group">
                <label for>Catégorie de message</label>
                <input style="background-color: #F9FAFB; border: #C6C6C6 1px solid;" type="text" class="form-control" :value="message.category.id != null
                    ? message.category.name
                    : 'Aucun'
                  " readonly />
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label for>Modèle</label>
                <input style="background-color: #F9FAFB; border: #C6C6C6 1px solid;"  type="text" class="form-control" :value="message.model.id != null ? message.model.name : 'Aucun'
                  " readonly />
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group">
                <label for>Expéditeur</label>
                <input style="background-color: #F9FAFB; border: #C6C6C6 1px solid;"  type="text" class="form-control" v-if="message.from" :value="message.from" readonly />
                <input style="background-color: #F9FAFB; border: #C6C6C6 1px solid;"  type="text" class="form-control" v-else value="Moi même" readonly />
              </div>
            </div>

            <div class="col-md-12" v-if="message.subject">
              <div class="form-group">
                <label for>Sujet</label>
                <input type="text" class="form-control" :value="message.subject" readonly />
              </div>
            </div>
          </div>
          <div class="col-md-6 ">
            <div v-if="message.customerChannel != undefined">
              <div class="col-md-12" v-if="
                message &&
                message.channel &&
                message.channel.id == 3 &&
                message.peopleCount !== null &&
                message.messageNumber !== null
              ">
                <div class="alert alert-danger text-center">
                  Le nombre total de message à envoyer est :
                  <span class="badge badge-secondary">{{
                    message.messageNumber +
                    " message(s)" +
                    " x " +
                    message.tab.length +
                    " invité(s) = " +
                    Number(message.messageNumber) * message.tab.length +
                    " message(s)"
                  }}</span>
                </div>
              </div>
              <div class="col-md-12" v-if="
                message &&
                message.channel &&
                message.peopleCount !== null &&
                message.messageNumber !== null &&
                message.customerChannel.selling_price > 0
              ">
                <div class="alert alert-danger text-center">
                  Vous aurez à payer :
                  <span class="badge badge-secondary" v-if="messageSellingPrice == 0">{{
                    message.channel.selling_price +
                    " FCFA x " +
                    Number(message.messageNumber) * message.tab.length +
                    " message(s) " +
                    " = " +
                    messageSellingPrice +
                    " FCFA"
                  }}</span>
                  <span class="badge badge-secondary" v-else>{{
                    message.channel.selling_price +
                    " FCFA x " +
                    (Number(message.messageNumber) * message.tab.length -
                      Number(
                        message.customerChannel.pivot.remaining_messages
                      )) +
                    " message(s) " +
                    " = " +
                    messageSellingPrice +
                    " FCFA"
                  }}</span>
                </div>
              </div>
            </div>

            <!-- <small class="col-md-12 text-danger"> -->
            
            <!-- </small> -->
            <div v-if="message && message != null">
              <!-- <div v-if="message.channel != null && message.channel.id == 3">
                <div
                  style="border: 1px solid;padding: 20px;background-color: #fff;"
                >
                  {{ message.displayMessage }}
                </div>
              </div> -->
              <div>
                <div style="position: relative; text-align: center" v-if="
                  message.layout &&
                  message.layout != null &&
                  message.displayMessage != null
                ">
                  <img :src="base_url + message.layout.image" style="width: 100%; height: auto" alt />
                  <div class="dm-centered" :style="'color:' + message.channel.color_code"
                    v-html="message.displayMessage"></div>
                </div>
              </div>
            </div>
            <div v-else>Chargement en cours ...</div>
          </div>

          <div style="margin-top: 55px; margin-bottom: 15px;" class="col-md-12" v-if="message.customerChannel != undefined">
            <button style="background-color: #fff; color: #000;border:1px solid gray;" class="secondary-button pull-left" @click="returnToChannelChoice">
              <i class="fa fa-chevron-left" style="color: #000;padding-right: 7px;"></i> PRÉCÉDENT
            </button>
            <!-- <button
              v-if="
                (message.file == null &&
                  message.customerChannel.pivot.remaining_messages >=
                    Number(message.messageNumber) * message.tab.length) ||
                message.customerChannel.selling_price == 0
              "
              class="primary-button pull-right"
              @click="sendMessage"
            >
              <i class="fa fa-check" style="color: #fff"></i> ENVOYER
            </button> -->

            <button class="primary-button pull-right" data-toggle="modal" data-target="#exampleModalCenter1"
              data-dismiss="modal" v-if="
                message.file == null &&
                message.customerChannel.selling_price == 0 &&
                message.customerChannel.id == 1
              ">
              Envoyer<i class="fa fa-send" style="color: #fff; padding-left: 7px;"></i>
            </button>

            <button class="primary-button pull-right" @click="sendMessage" v-if="
              message.file == null &&
              message.customerChannel.selling_price == 0 &&
              message.customerChannel.id != 1
            ">
             Envoyer <i class="fa fa-send" style="color: #fff; padding-left: 7px;"></i>
            </button>

            <button v-if="
              message.file == null &&
              message.customerChannel.selling_price > 0 &&
              message.customerChannel.id != 1
            " class="primary-button pull-right" @click="goToPayment">
              <i class="fa fa-cart" style="color: #fff"></i> Passer au paiement
            </button>
          </div>
          <div class="col-md-12 mt-5" v-else>
            <button style="background-color: white;border: 1px solid gray; color: black; " class="secondary-button pull-left" @click="returnToChannelChoice">
              <i class="fa fa-chevron-left" style="color: #000; padding-right: 7px;"></i> PRÉCÉDENT
            </button>
            <button class="primary-button pull-right" @click="goToChannelChoice">
              SUIVANT <i class="fa fa-chevron-right" style="color: #fff; padding-left: 7px ;"></i>
            </button>
          </div>
        </div>
        <div v-else>
          <spinner />
        </div>
      </div>
      <div class="message-summary-container p-3" v-else>
        <div class="row">
          <div class="col-md-12">
            <sub-title label="Résumé de votre envoi" gender="F" />
          </div>
        </div>
        <div class="row" style="position: relative; z-index: 1" v-if="message != null">
          <div
            :style="'position:absolute;z-index:-1;top:-15%;bottom:0;left:75%;right:0;background: url(/images/communication/wedding-planning.png) no-repeat;background-size:300px;background-repeat:no-repeat;opacity:0.2;'">
          </div>
          <div class="col-md-6">
            <div class="col-md-12">
              <div class="form-group" v-if="message.customerChannel != null">
                <label for>Canal</label>
                <input type="text" class="form-control" :value="message.channel.name" readonly />
              </div>
            </div>

            <div class="col-md-12" v-if="message.targets && message.targets.length > 0">
              <div style="background-color: #EFF6FE;" class="form-group">
                <label for>Cible</label>
                <br />
                <span v-for="(target, key) in message.targets" :key="key" style="margin-left: 5px; margin-right: 5px"
                  class="badge badge-primary">
                  <div style="margin: 10px">{{ target.name }}</div>
                </span>
              </div>
            </div>

            <div class="col-md-12" v-if="message.from">
              <div class="form-group">
                <label for>Expéditeur</label>
                <input type="text" class="form-control" v-if="message.from" :value="message.from" readonly />
                <input type="text" class="form-control" v-else value="Moi même" readonly />
              </div>
            </div>

            <div class="col-md-12" v-if="message.subject">
              <div class="form-group">
                <label for>Sujet</label>
                <input type="text" class="form-control" :value="message.subject" readonly />
              </div>
            </div>
          </div>
          <div class="col-md-12 pb-3">
            <div v-if="message.customerChannel != undefined">
              <div class="col-md-12" v-if="
                message &&
                message.channel &&
                message.channel.id == 3 &&
                message.peopleCount !== null &&
                message.messageNumber !== null
              ">
                <div class="alert alert-danger text-center">
                  Le nombre total de message à envoyer est :
                  <span class="badge badge-secondary">{{
                    message.messageNumber +
                    " message(s)" +
                    " x " +
                    message.tab.length +
                    " invité(s) = " +
                    Number(message.messageNumber) * message.tab.length +
                    " message(s)"
                  }}</span>
                </div>
              </div>
            </div>
            <div v-if="message && message != null">
              <!-- <div v-if="message.channel != null && message.channel.id == 3">
                <div
                  style="border: 1px solid;padding: 20px;background-color: #fff;"
                >
                  {{ message.displayMessage }}
                </div>
              </div> -->
              <div>
                <div style="position: relative; text-align: center" v-if="imageSrc != null">
                  <img :src="imageSrc" style="width: 100%; height: auto" alt />
                </div>
              </div>
            </div>
            <div v-else>Chargement en cours ...</div>
          </div>

          <div class="col-md-12" v-if="message.customerChannel != undefined">
            <button class="secondary-button pull-left" @click="returnToChannelChoice">
              <i class="fa fa-chevron-left" style="color: #fff"></i> PRÉCÉDENT
            </button>

            <button v-if="
              message.file != null &&
              message.customerChannel.selling_price == 0
            " class="primary-button pull-right" data-toggle="modal" data-target="#exampleModalCenter">
              <i class="fa fa-check" style="color: #fff"></i> ENVOYER
            </button>
            <button v-else class="primary-button pull-right" @click="goToPayment">
              <i class="fa fa-cart" style="color: #fff"></i> Passer au paiement
            </button>
          </div>
          <div class="col-md-12" v-else>
            <button class="btn-previous pull-left" @click="returnToChannelChoice">
              <i class="fa fa-chevron-left" style="color: #fff"></i> PRÉCÉDENT
            </button>
            <button class="primary-button pull-right" @click="goToChannelChoice">
              SUIVANT <i class="fa fa-chevron-right" style="color: #fff"></i>
            </button>
          </div>
        </div>
        <div v-else>
          <spinner />
        </div>
      </div>

      <!-- Modal -->
      <div class="modal fade" id="exampleModalCenter" tabindex="-1" style="margin-top: 15%" role="dialog"
        aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">
                Quelle est la catégorie de votre joycard?
              </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="col-md-12">
                <div class="form-group">
                  <label for>Catégorie de message *</label>
                  <select v-model="message.category" class="form-control" required>
                    <option value>--</option>
                    <option v-for="(category, key) in categories" :key="key" :value="category">
                      {{ category.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <!-- <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button> -->
              <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenter1"
                data-dismiss="modal" v-if="
                  message.customerChannel &&
                  message.customerChannel.id == 1 &&
                  message.category != null
                ">
                Enregistrer
              </button>

              <button type="button" class="btn btn-primary" data-dismiss="modal" @click="sendJoycard" v-if="
                message.customerChannel &&
                message.customerChannel.id != 1 &&
                message.category != null
              ">
                Envoyer
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal -->
      <div class="modal fade" id="exampleModalCenter1" tabindex="-1" style="margin-top: 15%" role="dialog"
        aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">
                Message d'envoi (optionnel)
              </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="col-md-12">
                <!-- <div class="form-group">
                  <label for
                    >Voulez-vous écrire un texte en plus du message à vos
                    invités ?</label
                  >
                  <vue-toggle
                    :title="personnalise ? 'Oui' : 'Non'"
                    name="nom"
                    :toggled="false"
                    fontSize="12px"
                    v-model="personnalise"
                    @toggle="(value) => (personnalise = value)"
                  />
                </div> -->
                <div class="form-group row">
                  <div class="col-sm-10">
                    <textarea type="text" v-model="message.subject" class="form-control" id="inputPassword"
                      placeholder="Ex:Bonjour chers invités ..."></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer" v-if="message.customerChannel">
              <button v-if="
                (message.file == null &&
                  message.customerChannel.pivot.remaining_messages >=
                  Number(message.messageNumber) * message.tab.length) ||
                (message.file == null &&
                  message.customerChannel.selling_price == 0)
              " data-dismiss="modal" class="primary-button pull-right" @click="sendMessage">
                <i class="fa fa-check" style="color: #fff"></i> ENVOYER
              </button>
              <button v-else type="button" class="btn btn-primary" @click="sendJoycard" data-dismiss="modal">
                Envoyer
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isLoading == true && isSending == true && isSuccess == false">
      <big-title label="JOY CARDS" />
      <br />
      <br />
      <div class="message-summary-container p-3">
        <div class="row">
          <div class="col-md-12">
            <sub-title label="Patientez..." gender="F" />
          </div>
        </div>
        <div>
          <div class="">
            <h3 style="text-align: center">Nous envoyons votre joycard</h3>
          </div>
          <div class="d-flex align-items-center justify-content-center" style="position: relative; z-index: 1">
            <spinner />
          </div>
        </div>
      </div>
    </div>
    <div v-if="isLoading == false && isSending == true && isSuccess == true">
      <big-title label="JOY CARDS" />
      <br />
      <br />
      <div class="message-summary-container p-3">
        <div class="row">
          <div class="col-md-12">
            <sub-title label="Envoyé avec succès !" gender="F" />
          </div>
        </div>
        <div>
          <div class="">
            <h3 style="text-align: center">
              Découvrez nos meilleurs prestataires
            </h3>
          </div>
          <div class="d-flex align-items-center justify-content-center" style="position: relative; z-index: 1">
            <card-list-horizontal-component v-if="providers.data && providers.data.length > 0"
              @on-favorite-toggle="onFavorite" :data="providers.data" :is-list="isList"
              :addbudget="budgetId"></card-list-horizontal-component>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.dm-container {
  position: relative;
  text-align: center;
  /* padding: 100px; */
  /* color: white; */
}

/* Centered text */
.dm-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: cursive;
  font-size: 12px;
}

</style>

<script>
import { API_PREFIX, API_VERSION, COMMUNICATION } from "@/config";
import axios from "axios";
import VueToggle from "vue-toggle-component";
import { mapActions, mapState } from "vuex";

export default {
  name: "MessageSummaryComponent",
  data: function () {
    return {
      base_url: COMMUNICATION.DEV_URL,
      isLoading: false,
      displayMessage: null,
      clientcounter: 0,
      countDown: 1,
      message: this.$store.state.message,
      imageSrc: null,
      categories: null,
      personnalise: false,
      createdMessage: {},
      isSending: false,
      isSuccess: false,
      filters: {},
    };
  },
  watch: {},
  computed: {
    ...mapState("providers", {
      isLoading: "isLoading",
      providers: "providers",
      currentServiceTypeIdSelected: "currentServiceTypeIdSelected",
      currentTownIdSelected: "currentTownIdSelected",
    }),
    messageSellingPrice: {
      get: function () {
        if (this.message.customerChannel != undefined) {
          if (
            Number(this.message.customerChannel.pivot.remaining_messages) <
            Number(this.message.messageNumber) * this.clientcounter
          ) {
            return (
              Number(this.message.channel.selling_price) *
              (Number(this.message.messageNumber) * this.clientcounter -
                Number(this.message.customerChannel.pivot.remaining_messages))
            );
          } else {
            return 0;
          }
        }
      },
      set: function (message) {
        this.clientcounter = message.tab.length;
      },
    },
  },

  mounted() {
    console.log(this.message);
    if (this.message && this.message.file != null) {
      this.readFile(this.message.file);
      this.getMessageCategories();
    }
    if (this.message && this.message.file == null) {
      this.displayMessage = this.message.body
        .replace("DATE_ENTERREMENT_DE_VIE_DE_JEUNE_FILLE", "01/12/2020")
        .replace("HEURE_ENTERREMENT_DE_VIE_DE_JEUNE_FILLE", "20h00")
        .replace(
          "LIEU_ENTERREMENT_DE_VIE_DE_JEUNE_FILLE",
          "à Angré, Star 11, Abidjan, Côte d'ivoire"
        )

        .replace("DATE_ENTERREMENT_DE_VIE_DE_JEUNE_GARCON", "01/12/2020")
        .replace("HEURE_ENTERREMENT_DE_VIE_DE_JEUNE_GARCON", "20h00")
        .replace(
          "LIEU_ENTERREMENT_DE_VIE_DE_JEUNE_GARCON",
          "à Angré, Star 11, Abidjan, Côte d'ivoire"
        )

        .replace("DATE_RECEPTION_DE_MARIAGE_CIVIL", "01/12/2020")
        .replace("HEURE_RECEPTION_DE_MARIAGE_CIVIL", "08h00")
        .replace("LIEU_RECEPTION_DE_MARIAGE_CIVIL", "à la Mairie de Cocody")

        .replace("DATE_RECEPTION_DE_DOT", "01/12/2020")
        .replace("HEURE_RECEPTION_DE_DOT", "10h00")
        .replace(
          "LIEU_RECEPTION_DE_DOT",
          "à Angré, Star 11, Abidjan, Côte d'ivoire"
        )

        .replace("DATE_RECEPTION_DE_MARIAGE_RELIGIEUX", "01/12/2020")
        .replace("HEURE_RECEPTION_DE_MARIAGE_RELIGIEUX", "12h00")
        .replace(
          "LIEU_RECEPTION_DE_MARIAGE_RELIGIEUX",
          "à Angré, Star 14, Abidjan, Côte d'ivoire"
        )

        .replace("DATE_CEREMONIE_DE_MARIAGE_RELIGIEUX", "01/12/2020")
        .replace("HEURE_CEREMONIE_DE_MARIAGE_RELIGIEUX", "12h00")
        .replace(
          "LIEU_CEREMONIE_DE_MARIAGE_RELIGIEUX",
          "à la Paroisse Ste Cécile, Vallons"
        )

        .replace("DATE_CEREMONIE_DE_DOT", "01/12/2020")
        .replace("HEURE_CEREMONIE_DE_DOT", "08h30")
        .replace(
          "LIEU_CEREMONIE_DE_DOT",
          "à Angré, Star 14, Abidjan, Côte d'ivoire"
        )

        .replace("DATE_CEREMONIE_DE_MARIAGE_CIVIL", "01/12/2020")
        .replace("HEURE_CEREMONIE_DE_MARIAGE_CIVIL", "09h00")
        .replace("LIEU_CEREMONIE_DE_MARIAGE_CIVIL", "à la Mairie de Cocody")

        .replace("CIVILITE_INVITE", "M.")
        .replace("NOM_INVITE", "Guépié")
        .replace("PRENOMS_INVITE", "Freddy")
        .replace("EMAIL_INVITE", "freddy.guepie@gmail.com")
        .replace("CONTACT_1_INVITE", "(+225) 02 00 00 01")
        .replace("CONTACT_2_INVITE", "(+225) 02 00 00 0é")

        .replace("CIVILITE_PCO", "M.")
        .replace("NOM_PCO", "Doe")
        .replace("PRENOMS_PCO", "Cedric")
        .replace("EMAIL_PCO", "cedric.doe@gmail.com")
        .replace("CONTACT_1_PCO", "(+225) 01 00 00 01")
        .replace("CONTACT_2_PCO", "(+225) 01 00 00 01")

        .replace("CIVILITE_FIANCEE", "Mlle")
        .replace("NOM_FIANCEE", "Doe")
        .replace("PRENOMS_FIANCEE", "Jane")
        .replace("EMAIL_FIANCEE", "jane.doe@gmail.com")
        .replace("CONTACT_1_FIANCEE", "(+225) 01 00 10 01")
        .replace("CONTACT_2_FIANCEE", "(+225) 01 00 11 01")

        .replace("CIVILITE_FIANCE", "M")
        .replace("NOM_FIANCE", "Doe")
        .replace("PRENOMS_FIANCE", "John")
        .replace("EMAIL_FIANCE", "john.doe@gmail.com")
        .replace("CONTACT_1_FIANCE", "(+225) 01 10 10 01")
        .replace("CONTACT_2_FIANCE", "(+225) 01 01 11 01");

      this.countDownTimer();
    }
  },
  methods: {
    ...mapActions({
      getServiceTypes: "serviceTypes/getServiceTypes",
      getTowns: "towns/getTowns",
      getProviders: "providers/getProviders",
      getFavorites: "favorites/getFavorites",
      updateFavorite: "favorites/updateFavorite",
    }),
    getMessageCategories: function () {
      let app = this;
      axios
        .get(COMMUNICATION.DEV_URL + API_PREFIX + API_VERSION + "categories", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then(function (response) {
          app.categories = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    returnToTargetChoice: function () {
      this.$emit("return-to-target-choice");
    },
    returnToChannelChoice: function () {
      this.$emit("return-to-channel-choice");
    },
    goToPayment() {
      // let app = this;
      // let targetIds = [];
      // app.message.targets.forEach(target => {
      //   targetIds.push(target.id);
      // });

      // app.createdMessage = {
      //   channel_id: app.message.channel.id,
      //   label: app.message.label,
      //   message_category_id: app.message.category.id,
      //   message_model_id: app.message.model.id,
      //   message_design_id:
      //     app.message.layout != null ? app.message.layout.id : null,
      //   from: app.message.from,
      //   subject: app.message.subject,
      //   body: app.message.body,
      //   tab: app.message.tab,
      //   target: targetIds.join(";")
      // };
      // app.message ={
      //   channel_id: app.message.channel.id,
      //   label: app.message.label,
      //   message_category_id: app.message.category.id,
      //   message_model_id: app.message.model.id,
      //   message_design_id: app.message.layout != null ? app.message.layout.id : null,
      //   from: app.message.from,
      //   subject: app.message.subject,
      //   body: app.message.body,
      //   tab: app.message.tab,
      //   target: targetIds.join(";")
      // };
      this.message.sellingPrice = this.messageSellingPrice;
      this.$emit("go-to-payment", this.message);
    },
    readFile(file) {
      const reader = new FileReader();

      // Set up the onload event handler
      reader.onload = (event) => {
        this.imageSrc = event.target.result; // Set the data URL
      };

      // Start reading the file
      reader.readAsDataURL(file);
    },
    showPaymentModal() {
      this.$modal.show("payment-modal");
    },
    checkout() {
      this.$modal.hide("payment-modal");
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown += 1;
          this.countDownTimer();
        }, 1000);
        if (this.message) {
          this.clientcounter = this.message.tab.length;
          this.message.tab.length = this.clientcounter;
        }
      }
    },
    sendMessage: async function () {
      let app = this;
      app.isLoading = true;
      app.isSending = true;
      let targetIds = [];
      app.message.targets.forEach((target) => {
        targetIds.push(target.id);
      });
      app.createdMessage = {
        channel_id: app.message.channel.id,
        label: app.message.label,
        message_category_id: app.message.category.id,
        message_model_id: app.message.model.id,
        message_design_id:
          app.message.layout != null ? app.message.layout.id : null,
        from: app.message.from,
        subject:
          app.message.subject && app.message.subject.length > 0
            ? app.message.subject
            : null,
        body:
          app.message.channel.id == 3
            ? app.message.model.content_for_sms
            : app.message.body,
        tab: app.message.tab,
        target: targetIds.join(";"),
      };

      await axios
        .post(
          COMMUNICATION.DEV_URL + API_PREFIX + API_VERSION + "messages",
          app.createdMessage,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then(async (response) => {
          console.log("response", response);
          app.isLoading = false;
          app.isSuccess = true;
          let result = response.data;
          if (result.data.channel_id == 2) {
            let url =
              "https://joursdejoie.com/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/wedding-card/" +
              result.data.id;
            window.open(
              "https://www.facebook.com/sharer/sharer.php?app_id=548126212259891&sdk=joey&u=" +
              url,
              "_blank"
            );
          }
          // window.location.href =
          //   "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/carte-d-invitation-mariage";
          await this.getProviders(app.filters);

          this.$createToast(
            {
              title: "Succès !",
              description: "Message envoyé avec succès !",
            },
            {
              position: "top-right",
              type: "success",
              // timeout: 10000,
              // transition: 'bounce',
            }
          );
          // app.$moshaToast("Message envoyé avec succès !");
        })
        .catch((error) => {
          console.log(error);
          app.isLoading = false;
          app.isSuccess = false;
          // app.$Progress.fail();
          this.$createToast(
            {
              title: "Erreur !",
              description: "Erreur détectée lors de l'envoie du message !",
            },
            {
              position: "top-right",
              type: "error",
              // timeout: 10000,
              // transition: 'bounce',
            }
          );
        });
    },

    sendJoycard: async function () {
      let app = this;
      app.isLoading = true;
      app.isSending = true;
      let targetIds = [];
      if (app.message.customerChannel.id != 2) {
        app.message.targets.forEach((target) => {
          targetIds.push(target.id);
        });
      }
      app.createdMessage = {
        channel_id: app.message.channel.id,
        label: "My message",
        message_category_id: app.message.category.id,
        message_model_id: null,
        message_design_id:
          app.message.layout != null ? app.message.layout.id : null,
        from: "JoursdeJoie",
        subject:
          app.message.subject && app.message.subject.length > 0
            ? app.message.subject
            : null,
        body:
          app.message.channel.id == 3
            ? app.message.model.content_for_sms
            : app.message.body,
        tab:
          app.message.customerChannel.id == 2
            ? null
            : app.message.tab.join(";"),
        target: targetIds.join(";"),
        file: app.message.file,
      };

      const formData = new FormData();

      // Append each field to the FormData object
      formData.append("channel_id", app.createdMessage.channel_id);
      formData.append("label", app.createdMessage.label);
      formData.append(
        "message_category_id",
        app.createdMessage.message_category_id
      );
      formData.append("from", app.createdMessage.from);
      if (app.createdMessage.subject && app.createdMessage.subject.length > 0) {
        formData.append("subject", app.createdMessage.subject);
      }
      if (app.message.customerChannel.id != 2) {
        formData.append("target", app.createdMessage.target);
        formData.append("tab", app.createdMessage.tab);
      }

      // Append the file (if it exists)
      if (app.message.file) {
        formData.append("file", app.message.file);
      }

      // Log the FormData object (for debugging)
      for (let [key, value] of formData.entries()) {
        console.log(key, value);
      }

      console.log(app.createdMessage);

      await axios
        .post(
          COMMUNICATION.DEV_URL + API_PREFIX + API_VERSION + "messages/send",
          formData,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then(async (response) => {
          console.log("response", response);
          app.isLoading = false;
          app.isSuccess = true;
          let result = response.data;
          if (result.data.channel_id == 2) {
            let url =
              "https://joursdejoie.com/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/wedding-card/" +
              result.data.id;
            window.open(
              "https://www.facebook.com/sharer/sharer.php?app_id=548126212259891&sdk=joey&u=" +
              url,
              "_blank"
            );
          }
          // window.location.href =
          //   "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/carte-d-invitation-mariage";
          await this.getProviders(app.filters);

          this.$createToast(
            {
              title: "Succès !",
              description: "Message envoyé avec succès !",
            },
            {
              position: "top-right",
              type: "success",
              // timeout: 10000,
              // transition: 'bounce',
            }
          );
        })
        .catch((error) => {
          console.log(error);
          app.isLoading = false;
          app.isSuccess = false;

          // app.$Progress.fail();
          this.$createToast(
            {
              title: "Erreur !",
              description: "Erreur détectée lors de l'envoie du message !",
            },
            {
              position: "top-right",
              type: "error",
              // timeout: 10000,
              // transition: 'bounce',
            }
          );
          // app.$moshaToast("Erreur détectée lors de l'envoie du message !");
        });
    },
    goToChannelChoice: function () {
      console.log("goToChannelChoice");
      this.$emit("go-to-channel-choice");
    },
  },
  components: {
    VueToggle,
  },
};
</script>
