<template>
  <footer class="footer-area section-gap no-print" style="padding: 15px 0">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="footer-top flex-column align-items-center container">
          <ul class="footer-menu mb-20">
            <li>
              <router-link class="font-weight-bold" to="/">Accueil</router-link>
            </li>
            <li>
              <router-link
                class="font-weight-bold"
                to="/choisir-mairie-pour-organiser-son-mariage-cote-d-ivoire"
              >
                Mairies
              </router-link>
            </li>
            <li>
              <router-link
                class="font-weight-bold"
                to="/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/fiances"
              >
                EVENT PLANNER
              </router-link>
            </li>
            <li>
              <router-link
                class="font-weight-bold"
                to="/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/carte-d-invitation-mariage"
              >
                Joy Cards
              </router-link>
            </li>
            <li>
              <router-link
                class="font-weight-bold"
                to="/annuaires-des-prestataires-de-services-mariage-en-cote-divoire"
                >PRESTATAIRES (JOY SHOP)</router-link
              >
            </li>
            <li>
              <a
                class="font-weight-bold"
                target="_blank"
                href="https://blog.joursdejoie.com/"
              >
                Blog
              </a>
            </li>
            <li>
              <router-link
                class="font-weight-bold"
                to="/contacts-de-l-entreprise-d-organisation-de-mariage-cote-d-ivoire"
              >
                Contacts
              </router-link>
            </li>
          </ul>
          <div class="container text-center">
            Jours de Joie, votre Digital Event Planner, est l'assistant idéal
            pour organiser sereinement vos événements heureux ( anniversaire,
            mariages, cérémonies professionnelles et tout autre évènements). Plus
            besoin de vous déplacer grâce à nos services et nos conseils
            pratiques, nous répondons à vos besoins en un clic. Nous vous
            offrons en plus la possibilité de mise en relation des prestataires de
            qualité.
          </div>
          <ul class="footer-menu mt-20">
            <li>
              <router-link
                class="font-weight-bold"
                to="/cgu-organisation-de-mariage-cote-d-ivoire"
                >CGU
              </router-link>
            </li>
            <li>
              <router-link class="font-weight-bold" to="/privacy">
                Politique de confidentialité</router-link
              >
            </li>
            <li>
              <router-link
                class="font-weight-bold"
                to="/faq-organisation-de-mariage-cote-d-ivoire"
                >Foires Aux Questions (F.A.Q)</router-link
              >
            </li>
            <!-- <li>
              <router-link class="font-weight-bold" to="/joy-visibility"
                >Joy Visibility (Offre promotionnelle)</router-link
              >
            </li> -->
            <li>
              <router-link class="font-weight-bold" to="/onboarding"
                >Ouverture du compte de prestataire</router-link
              >
            </li>
            <li>
              <router-link
                class="font-weight-bold"
                to="/telecharger-application-mobile-joursdejoie"
              >
                Telecharger l'application mobile
              </router-link>
            </li>
          </ul>
          <div
            class="row no-padding justify-content-center mt-20"
            style="height: auto"
          >
            <div class="col-md-12">
              <div class="row text-center">
                <div class="col-md-3">
                  <i
                    class="fa fa-envelope fa-2x pt-2"
                    style="color: #b71c1c"
                  ></i>
                  &nbsp;&nbsp; info@joursdejoie.com
                </div>
                <div class="col-md-3">
                  <i class="fa fa-mobile fa-2x pt-2" style="color: #b71c1c"></i>
                  &nbsp;&nbsp; (+225) 05 75 44 06 06
                </div>
                <div class="col-md-3">
                  <a
                    href="https://web.facebook.com/joursdejoie.ci/"
                    target="_blank"
                    class="btn-social btn-facebook"
                  >
                    <i class="fa fa-facebook"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/joursdejoie_officiel"
                    target="_blank"
                    class="btn-social btn-instagram"
                  >
                    <i class="fa fa-instagram"></i>
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCtucAiXVjWmpgWfadPS8x1Q"
                    target="_blank"
                    class="btn-social btn-youtube"
                  >
                    <i class="fa fa-youtube"></i>
                  </a>
                  <a
                    href="https://api.whatsapp.com/send?phone=2250575440606"
                    target="_blank"
                    class="btn-social btn-wechat"
                  >
                    <i class="fa fa-whatsapp"></i>
                  </a>
                </div>
                <div class="col-md-3">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.joursdejoie.app"
                    target="_blank"
                  >
                    <img src="/images/google_play.png" width="40%" alt="" />
                  </a>
                  <a
                    href="https://apps.apple.com/ci/app/jours-de-joie/id6504404907"
                    target="_blank"
                  >
                    <img
                      src="/images/apple_logo_download.png"
                      width="50%"
                      alt=""
                    />
                  </a>
                </div>

                <!-- <div class="col-md-3">
                  <a href="/telecharger-application-mobile-joursdejoie"></a>
                </div> -->
              </div>
            </div>
          </div>
          <div class="row footer-bottom justify-content-center">
            <p class="col-lg-8 col-sm-12 footer-text">
              Copyright &copy; {{ new Date().getFullYear() }}. Tous droits
              reservés | Make with <i class="fa fa-heart"></i> by
              <a href="https://paraclet-ci.com" target="_blank">Paraclet</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<style scoped>
@media (min-width: 1025px) {
  .container {
    width: 70% !important;
  }
}
@import "./social-icon.css";
.router-link-active {
  color: #b71c1c;
}
.bg-container-footer {
  width: 100%;
  /* height: 300px; */
  /* border: 1px solid #000; */
  position: relative;
}

.bg-container-footer .content {
  position: absolute;
  z-index: 1;
  text-align: center;
  width: 100%;
  opacity: 1;
}

.bg-container-footer::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url("@/images/backgrounds/home/home.png");
  background-size: cover;
  opacity: 0.1;
}
</style>

<script>
export default {
  name: "Footer",
  data: function () {
    return {
      //
    };
  },
  beforeMount() {},
  methods: {
    socialButton: function () {
      alert("Yo bruuuh");
    },
  },
};
</script>
