<template>
  <div class="bg-container-summaries">
    <Bar />
    <div class="container p-3" style="padding: 25px;">
      <big-title label="EN BREF" />
      <br />
      <!-- <p class="text-center"></p> -->

      <!-- <p class="text-center">
        De quoi avez-vous besoin pour faire de votre mariage une réussite ?<br />
        La rubrique « En bref » vous permet, <br />
        de répondre aux questions pertinentes pour l’organisation de votre
        cérémonie <br />
        ( Le choix de la tenue, le choix des couleurs, du traiteur etc … )
      </p> -->
      <p class="text-center">
        Organiser son mariage n’est pas chose facile, <br />
        d’autant plus que pour la plupart c'est une toute nouvelle expérience.
        <br />
        Les sentiments et sensations s'entremêlent, <br />
        la peur et l'enthousiasme nous gagnent. Mais nous vous rassurons, <br />
      </p>
      <div class="text-center">
        <p class="collapse" id="collapseSM">
          Tous ses sentiments relèvent du normal dans cette période de
          préparation de votre jour de rêve, le jour tant attendu. <br />
          C'est en ayant pris toutes vos préoccupations de futurs mariés que
          nous vous accueillons dans l'univers En Bref de votre carnet nuptial.
          <br />
          Dans cette rubrique vous êtes invités à renseigner les informations
          sur votre mariage. <br />Cet exercice vous permettra de lister sans
          pression les étapes importantes de votre organisation de mariage.
          <br />
          A travers des questions pertinentes vous arriverez à une organisation
          de cérémonie de mariage réussi : <br />(Le choix de la tenue, le choix
          des couleurs, du traiteur etc …) avec l’outil digital qu’offre Jours
          de joie pour organiser votre mariage, <br />vous pouvez renseigner les
          informations à votre rythme. <br /><br />
          <strong>Le saviez-vous :</strong> <br />
          <strong>A l’accoutumée la famille de la mariée se charge de :</strong>
          <br />
          La robe de mariée et les accessoires, la décoration de l’église et de
          la réception, <br />
          la tenue des enfants d’honneur, les frais de réception des invités de
          la mariée (contribution traiteur), <br />
          le photographe, la location du véhicule pour le transport des mariés.
          <br />
          <strong>Et la famille du marié se charge:</strong> <br />
          du costume du marié et ses accessoires, des alliances, des frais
          administratifs, <br />
          des bouquet de la mariée , des frais de réception des invités du marié
          <br />
          (contribution traiteur), du voyage de noces
        </p>
        <a
          class="btn btn-link btn-sm"
          style="color: #b71c1c; text-decoration: none;"
          data-toggle="collapse"
          href="#collapseSM"
          role="button"
          aria-expanded="false"
          aria-controls="collapseSM"
          @click="textFullDisplay = !textFullDisplay"
        >
          <strong>{{
            textFullDisplay == false ? "+ VOIR PLUS" : "- VOIR MOINS"
          }}</strong>
        </a>
      </div>
      <br />
      <div v-if="isLoading == true">
        <spinner  /><br /><br />
      </div>
      <div
        class=""
        v-else-if="user.email_verified_at != null && customer.dob != null"
      >
        <div
          class="row"
          v-if="isLoading == false && summaries != null && customer != null"
        >
          <div class="col-md-12 alert alert-danger text-center"  v-if="summaries.length == 0">
            Il n'y a pas de parametre pour cet type d'évènement
          </div>
          <div class="col-md-6" v-for="(item, index) in summaries" :key="index">
            <summary-component
              :summaryProps="item"
              @update-summary="updateSummary"
              :index="index"
            />
          </div>
        </div>
        <div v-else>
          <spinner  />
          <br /><br />
        </div>
      </div>
      <saved-modal :user="user" @resendMailAccountVerification="resendMailAccountVerification" v-show="showModal" @close-modal="showModal = false" />
      
      <center>
        <button class="default-button" @click="goToPrevious">PRÉCÉDENT</button
        >&nbsp;
        <button class="default-button" @click="goToNext">SUIVANT</button>
      </center>
    </div>
  </div>
</template>

<style scoped>
.bg-container-summaries {
  width: 100%;
  /* height: 300px; */
  /* border: 1px solid #000; */
  position: relative;
}

.bg-container-summaries .content {
  position: absolute;
  z-index: 1;
  text-align: center;
  width: 100%;
  opacity: 1;
}

.bg-container-summaries::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url("@/images/backgrounds/nuptial-notebook/summaries.png");
  background-size: cover;
  opacity: 0.1;
}
</style>

<script>
import Bar from "@/components/nuptial-notebook/Bar.vue";
import SummaryComponent from "@/components/nuptial-notebook/SummaryComponent";
import { API_PREFIX, API_VERSION, NUPTIAL_NOTEBOOK } from "@/config";
import store from "@/store";
import axios from "axios";
import { useMeta } from 'vue-meta';


export default {
  name: "CustomerSummaries",
  setup () {
    useMeta({
      title:
        "Carnet Nuptial - En Bref - Les réponses aux questions pertinentes de l'organisation de mariage",
      titleTemplate: "%s | Jours de Joie",
      htmlAttrs: {
        lang: "fr",
        amp: true,
      },
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content: "Planifier son mariage en Côte d'Ivoire",
        },
        {
          name: "keywords",
          content:
            "Mariage en Côte d’Ivoire, trouver des prestataires pour son mariage, courses pour le mariage, traiteur, prestataire de mariage, prestataire de service, organiser son mariage,photographe, coiffure, robe de mariée, tenue du mariée, accessoires, alliance, maquilleuse, makeup artist, de planification, wedding planner, inspiration de mariage, salle de réception, tenue de demoiselle d’honneur.",
        },
      ],
    })
  },

  components: {
    SummaryComponent,
    Bar,
  },
  data: function() {
    return {
      showModal:false,
      isLoading: false,
      summaries: null,
      summaryForms: null,
      user: JSON.parse(store.state.user),
      customer: {dob:null},
      textFullDisplay: false,
    };
  },
  mounted: function() {
    this.getCustomerInfo()
      .then((customer) => {
        this.getSummaries(customer.current_event.id);
      })
      .catch(() => {});
    this.show();
  },
  methods: {
    getCustomerInfo: function() {
      return new Promise((resolve, reject) => {
        let app = this;
        app.isLoading = true;
        axios
          .get(
            NUPTIAL_NOTEBOOK.DEV_URL +
              API_PREFIX +
              API_VERSION +
              "customers-by-user/" +
              app.user.id,
            {
              headers: {
                Authorization: "Bearer " + store.state.token,
              },
            }
          )
          .then((response) => {
            this.customer = response.data;
            console.log('Customer : ',this.customer);
            // if (!this.customer.user.email_verified_at) {
            //     this.$router.push('/inscription-a-terminer/'+2);
            // }
            this.isLoading = false;
            resolve(this.customer);
          })
          .catch((error) => {
            console.log("Error", error);
            this.isLoading = false;
            reject(error);
          });
      });
    },
    goToPrevious: function() {
      this.$router.push(
        "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/fiances"
      );
    },
    goToNext: function() {
      this.$router.push(
        "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/comite-restreint"
      );
    },
    getSummaries: function(eventId) {
      this.isLoading = true;
      axios
        .get(
          NUPTIAL_NOTEBOOK.DEV_URL +
            API_PREFIX +
            API_VERSION +
            "summaries-by-event/" +
            eventId,
          {
            headers: {
              Authorization: "Bearer " + store.state.token,
            },
          }
        )
        .then((response) => {
          this.isLoading = false;
          this.summaries = response.data;
        })
        .catch((error) => {
          console.log("error", error.response.data);
          
          this.$createToast(
          {
          title: 'Attention !',
          description: "Erreur lors de la recuperation des informations !"
          },
          {
          position: 'top-center',
          type: 'error',
          timeout: 10000,
          transition: 'bounce',
          })
          this.isLoading = false;
        });
    },
    updateSummary: function(summary) {
      this.isLoading = true;
      axios
        .post(
          NUPTIAL_NOTEBOOK.DEV_URL +
            API_PREFIX +
            API_VERSION +
            "summaries/events",
          summary,
          {
            headers: {
              Authorization: "Bearer " + store.state.token,
            },
          }
        )
        .then((response) => {
          this.isLoading = false;
          this.$createToast(
            {
            title: 'Operation reussie !',
            description: "Informations enregistrées avec succès !  !"
            },
            {
            position: 'top-center',
            type: 'success',
            timeout: 10000,
            transition: 'bounce',
            });
          this.getSummaries(this.customer.current_event.id);
        })
        .catch((error) => {
          console.log("error", error.response.data);
          
          this.$createToast(
          {
          title: 'Attention !',
          description: "Erreur lors de l'enregistrement des informations !"
          },
          {
          position: 'top-center',
          type: 'error',
          timeout: 10000,
          transition: 'bounce',
          })
          this.isLoading = false;
        });
    },
    show() {
      if (!this.user.email_verified_at) {
        this.showModal= true
      }
    },
  },
};
</script>
