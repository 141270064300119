<template>
  <header style="position:static;">
    <div class="container no-print" style="position: relative;border: solid 1px white;">
      <div class="header-wrap" style="position: relative;z-index: 100000; ">
        <div
          class="header-top d-flex justify-content-between align-items-center no-gutters"
          style="vertical-align: middle; padding:0px;min-height:72px;"
        >
          <div class="col menu-left"  style="font-size: 12px;">
            <router-link to="/">ACCUEIL</router-link>
            <router-link
              to="/choisir-mairie-pour-organiser-son-mariage-cote-d-ivoire"
              >MAIRIES</router-link
            >
            <router-link
              to="/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/fiances"
              >EVENT PLANNER</router-link
            >
            <router-link to="/shopping">SHOPPING</router-link>
          </div>
          <div class="col-4 logo" id="titre">
            <a href="/">
              <img
                class="mx-auto"
                src="/images/logo.png"
                style="height:90px;"
                alt=""
              />
              <!-- <img class="mx-auto" src="/images/logo2.jpeg" style="height:90px;" alt=""> -->
              <!-- <img class="mx-auto" src="/images/old_img/logo.png" alt=""> -->
            </a>
          </div>
          <nav class="col navbar navbar-expand-md justify-content-end">
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#collapsibleNavbar"
            >
              <span class="lnr lnr-menu"></span>
            </button>
            <div
              class="collapse navbar-collapse menu-right"
              id="collapsibleNavbar"
              style="font-size: 12px;"
            >
              <ul class="navbar-nav">
                <li class="nav-item d-md-none">
                  <router-link class="nav-link" to="/">ACCUEIL</router-link>
                </li>
                <li class="nav-item d-md-none">
                  <router-link class="nav-link" 
                    to="/choisir-mairie-pour-organiser-son-mariage-cote-d-ivoire"
                    >MAIRIES</router-link
                  >
                </li>
                
                <li class="nav-item d-md-none">
                  <router-link class="nav-link" 
                    to="/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/fiances"
                    >EVENT PLANNER</router-link
                  >
                </li>
                <li class="nav-item d-md-none">
                  <router-link
                    to="/shopping"
                    >SHOPPING</router-link
                  >
                </li>
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    to="/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/carte-d-invitation-mariage"
                    >JOY CARDS</router-link
                  >
                </li>
                <li class="nav-item d-none d-xl-block">
                  <a class="nav-link" 
                    target="_blank"
                    href="https://blog.joursdejoie.com">
                    BLOG
                  </a>
                </li>
                <li class="nav-item">
                  <router-link class="nav-link" 
                  to="/contacts-de-l-entreprise-d-organisation-de-mariage-cote-d-ivoire"
                    >CONTACTS</router-link
                  >
                </li>
                <li v-if="user != null" class="nav-item dropdown">
                  <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fa fa-user-circle-o" style="font-size:17px;"></i
                    >&nbsp;
                    <span v-if="user.given_names.length <= 7">{{
                      user.given_names
                    }}</span>
                    <span v-else-if="user.given_names.length > 7">{{
                      user.given_names.substring(0, 7) + "..."
                    }}</span>
                    <span class="caret"></span>
                  </a>
                  <div
                    class="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0);"
                      @click="goToAccountPage"
                      >Mon compte</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0);"
                      @click="goToOrderPage"
                      >Mes commandes</a
                    >
                    <li
                      class="dropdown-divider"
                      style="border-top:1px solid #b71c1c;"
                    ></li>
                    <a
                      class="dropdown-item"
                      href="javascript:void(0);"
                      @click="logout"
                      >Déconnexion</a
                    >
                  </div>
                </li>
                <li v-else class="nav-item dropdown">
                  <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fa fa-user-circle-o" style="font-size:17px;"></i
                    >&nbsp;
                    <span> PROFIL</span>
                    <span class="caret"></span>
                  </a>
                  <div
                    class="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <a
                      class="dropdown-item"
                      @click="goToLoginPage"
                      >CONNEXION</a
                    >
                    <a
                      class="dropdown-item"
                      @click="goToRegisterPage"
                      >INSCRIPTION</a
                    >
                    <li
                      class="dropdown-divider"
                      style="border-top:1px solid #b71c1c;"
                    ></li>
                    <a class="dropdown-item" 
                      >TERMES ET CONDITIONS</a
                    >
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "header",
  data: function() {
    return {
      user: JSON.parse(this.$store.state.user)
    };
  },
  mounted: function() {
    // console.log(this.user);
  },
  computed: {
    userFullname: function() {
      let fullname = null;
      if (this.user != null) {
        fullname = this.user.last_name + " " + this.user.given_names;
      }
      return fullname;
    }
  },
  methods: {
    goToLoginPage: function() {
      this.$router.push("/login");
    },
    goToRegisterPage: function() {
      this.$router.push("/register");
    },
    goToAccountPage: function() {
      this.$router.push("/account/infos");
    },
    goToOrderPage: function() {
      this.$router.push("/shopping/order");
    },
    logout: function() {
      this.$store.dispatch("logout").then(() => {
        window.location.href = "/";
      });
    }
  }
};
</script>

<style scoped>
.router-link-exact-active {
  color: #b71c1c;
}

.menu-left {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.menu-left a {
  margin-right: 25px;
}

.nav-link {
  padding-right: 0 !important;
}
</style>
