<template>
  <div class="">
    <BannerPackage
      @search-package="filterPackages"
      :is-display-search-inputs="false"
    />
    <breadcrumb-component />
    <!-- <PackagesList/> -->
    <div v-if="bookingPackage != null && isLoading==false">
      <div class="container mt-2" v-if="bookingPackage.length > 0">
        <div data-v-74226d44="" class="col-md-12">
          <div
            data-v-0c353bd8=""
            data-v-74226d44=""
            class="accordion"
            id="accordionExample"
            style="padding-top: 15px; padding-bottom: 15px"
          >
            <div
              data-v-0c353bd8=""
              id="collapse0"
              class="collapse show"
              aria-labelledby="heading0"
              data-parent="#accordionExample"
            >
              <div data-v-0c353bd8="" class="table-responsive">
                <table data-v-0c353bd8="" class="table table-bordered table-sm">
                  <thead
                    data-v-0c353bd8=""
                    class="card-header text-white"
                    style="background-color: rgb(183, 28, 28)"
                  >
                    <th
                      data-v-0c353bd8=""
                      style="color: white; text-align: left"
                    >
                      PACKAGE
                    </th>
                    <th
                      data-v-0c353bd8=""
                      style="color: white; text-align: left"
                    >
                      PRIX
                    </th>
                    <th
                      data-v-0c353bd8=""
                      style="color: white; text-align: left"
                    >
                      PRESTATAIRE
                    </th>
                    <th
                      data-v-0c353bd8=""
                      style="color: white; text-align: left"
                    >
                      DATE
                    </th>
                    <th
                      data-v-0c353bd8=""
                      style="color: white; text-align: left"
                    >
                      ACTIONS
                    </th>
                  </thead>
                  <tbody data-v-0c353bd8="">
                    <tr data-v-0c353bd8="" v-for="bp in bookingPackage">
                      <td data-v-0c353bd8="" id="text">
                        {{ bp.package.name }}
                      </td>
                      <td data-v-0c353bd8="" id="min">
                        {{ bp.package.price }} FCFA
                      </td>
                      <td data-v-0c353bd8="" id="max">
                        {{ bp.package.provider.name }}
                      </td>
                      <td
                        data-v-0c353bd8=""
                        id="max"
                        
                      >
                      {{countdownTimer(bp.date_reservation)}} 
                      </td>
                      <!-- <td data-v-0c353bd8="" id="max" v-else>
                        {{ this.formatDate(bp.date_reservation) }}
                      </td> -->

                      <td data-v-0c353bd8="" class="" id="real">
                        <button
                          @click="goDetail(bp.package.id)"
                          data-toggle="modal"
                          data-target="#exampleModalCenter"
                          class="btn btn-primary mb-1 w-100"
                        >
                          Voir
                        </button>
                        <button
                          @click="deleteBookingPackage(bp.id)"
                          class="btn btn-light w-100"
                        >
                          Annuler
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <!-- Modal d'envoie de demande de devis -->
                <div
                  class="modal fade"
                  id="exampleModalCenter"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalCenterTitle"
                  aria-hidden="true"
                >
                  <div
                    class="modal-dialog modal-dialog-centered"
                    role="document"
                    style="margin-top: 250px"
                  >
                    <div class="modal-content">
                      <contact-form-component
                        :provider="provider"
                        :user="user"
                        :is-open-in-modal="true"
                        @quote-request-form="sendQuoteRequest"
                      ></contact-form-component>
                      <!-- <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLongTitle">
                        Modal title
                      </h5>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div> -->
                      <!-- <div class="modal-body">
                      
                    </div> -->
                      <!-- <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                      <button type="button" class="btn btn-primary">
                        Save changes
                      </button>
                    </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="bookingPackage.length == 0">
        Vous n'avez pas de package réservé
      </div>
    </div>
    <spinner v-if="isLoading==true" />
    <div v-if="bookingPackage == null && isLoading==false">
      <div>
        Vous n'avez pas de package réservé
      </div>
    </div>

  </div>
</template>
<script>
import { API_PREFIX, API_VERSION, PROVIDER } from "@/config";
import { QUOTE_REQUEST_TYPE } from "@/views/wedding-provider/utils";
import moment from "moment";
import { Countdown } from "vue3-flip-countdown";
import { mapActions, mapState } from "vuex";
import BannerPackage from "../components/banner-package.vue";
import ListPackage from "../components/card-package/card-package-list.vue";
import FilterPackage from "../components/filter/filter-package.vue";

export default {
  data: function () {
    return {
      requestTypes: QUOTE_REQUEST_TYPE,

      provider: null,
      star: 0,
      note: {
        wedding_provider_id: this.$route.params.id,
        recommendation_mark: 0,
        quality_price_ratio_mark: 0,
        professionalism_mark: 0,
      },
      paginate_index: {
        per_page: "12",
        page: "1",
      },
      filterPackage: null,
      bookingPackage: null,
    };
  },
  mounted() {
    this.getBookingsPackage();
  },
  components: {
    BannerPackage,
    ListPackage,
    FilterPackage,
    Countdown,
  },
  computed: {
    user() {
      // return false;
      return JSON.parse(this.$store.state.user);
    },
    ...mapState("packages", { package: "package" }),
  },
  watch: {
    // bookingPackage(next){
    //   console.log(next)
    // }
  },
  methods: {
    ...mapActions("packages", ["updatePackage"]),
    ...mapActions("quoteRequests", ["storeQuoteRequest"]),

    replace(url, params) {
      // Crée un objet URL à partir de l'URL de base
      const urlObj = new URL(url);

      // Ajoute ou remplace les paramètres dans l'URL
      for (const [key, value] of Object.entries(params)) {
        urlObj.searchParams.set(key, value);
      }

      // Retourne l'URL mise à jour
      return urlObj.toString();
    },
    formatDate(dateTime) {
      return moment(dateTime).format("DD MMMM YYYY");
    },
    deleteBookingPackage(id) {
      let app = this;
      const urlFinale = this.replace(
        PROVIDER.DEV_URL + API_PREFIX + API_VERSION + `booking-package/${id}`,
        { api_token: "Bearer " + localStorage.getItem("token") }
      );

      this.$http
        .delete(urlFinale)
        .then((response) => {
          // app.$notify({
          //   group: "bookingPackage",
          //   type: "success",
          //   text: "Réservation supprimée avec succès",
          // });
          app.getBookingsPackage();
        })
        .catch((error) => {
          // app.$notify({
          //   group: "bookingPackage",
          //   type: "error",
          //   text: "Erreur lors de la suppression de la réservation",
          // });
        });
    },
    async sendQuoteRequest(intent) {
      const quoteRequest = {
        ...intent,
        wedding_provider_id: this.package.provider.id,
        shop_id: this.package.shop_id,
        service: this.package.provider.service_type.find(
          (e) => e.id == this.package.service_type_id
        ).name,
        service_type_id: this.package.service_type_id,
        package_id: this.package != null ? this.package.id : null,
      };

      let number = this.provider.phone_number_1.replace(/[^a-zA-Z0-9]/g, "");

      await this.storeQuoteRequest(quoteRequest);
      if (intent.type === this.requestTypes.PHONE) {
        window.open("tel:" + this.provider.phone_number_1, "_blank");
      }
      if (intent.type === this.requestTypes.WHATSAPP) {
        window.open("https://api.whatsapp.com/send?phone=" + number, "_blank");
      }
      if (intent.type === this.requestTypes.WEBSITE) {
        window.open(this.provider.website, "_blank");
      }
    },
    goDetail(id) {
      this.$router.push(
        "/description-package-des-prestataires-de-services-en-cote-divoire/" +
          id
      );
    },
    countdownTimer(targetDate) {

    const now = new Date(); // Current date and time
    const target = new Date(targetDate); // Convert the input string to a Date object

    // Calculate the difference in milliseconds
    const timeDiff = target - now;

    // Convert milliseconds to days
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

    return timeDiff<0 ? this.formatDate(targetDate) : `Plus que ${daysDiff} jours...`;

    },

    getBookingsPackage() {
      this.isLoading = true;
      let app = this;
      const urlFinale = this.replace(
        PROVIDER.DEV_URL +
          API_PREFIX +
          API_VERSION +
          `get-customer-booking-packages`,
        { ...{ api_token: "Bearer " + localStorage.getItem("token") } }
      );

      this.$http
        .get(urlFinale)
        .then((response) => {
          this.bookingPackage = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log("error", error.response.data);
          this.isLoading = false;
        });
    },
    setData(provider, pack) {
      console.log(provider, pack);
      this.provider = provider;
      this.updatePackage(pack);
    },
  },
};
</script>
<style></style>
