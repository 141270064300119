<template>
  <div class="flex flex-col p-4 bg-white rounded-lg shadow-sm border border-gray-100 mb-4">
    <!-- Header Section with Avatar, Name and Date -->
    <div class="flex items-start mb-3">
      <div class="flex-shrink-0 mr-3">
        <img 
          :src="'https://ui-avatars.com/api/?name='+comment.customer.last_name+comment.customer.given_names" 
          class="h-12 w-12 rounded-full border border-gray-200" 
          alt="User avatar"
        />
      </div>
      <div class="flex flex-col">
        <h3 class="text-base font-semibold text-gray-800">
          {{ 'Par ' + comment.customer.last_name + ' ' + comment.customer.given_names }}
        </h3>
        <p class="text-sm text-gray-500">{{ dateFormatter(comment.created_at) }}</p>
        
        <!-- Delete Controls - Only visible to comment author -->
        <div v-if="user && user.id === comment.customer.id" class="mt-1">
          <button 
            v-if="!isConfirmed" 
            @click="onConfirmed" 
            class="text-xs font-medium text-gray-500 hover:text-gray-700 transition-colors"
          >
            Supprimer
          </button>
          <div v-else class="p-2 bg-gray-50 rounded mt-1 border border-gray-200">
            <p class="text-xs text-gray-600 mb-1">
              Êtes-vous sûr de vouloir supprimer ce commentaire?
            </p>
            <div class="flex gap-2">
              <button 
                @click="onDelete" 
                class="text-xs font-medium text-red-600 hover:text-red-800 transition-colors"
              >
                Oui
              </button>
              <button 
                @click="onCancel" 
                class="text-xs font-medium text-gray-500 hover:text-gray-700 transition-colors"
              >
                Annuler
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <!-- Rating and Discovery Channel Section -->
    <div class="flex flex-col sm:flex-row sm:items-center mb-3 pb-3 border-b border-gray-100">
      <div class="flex items-center mb-2 sm:mb-0">
        <review-stars-component 
          :count="0" 
          :stars="getStars(comment.global_mark)" 
          :isShownMode="true"
          class="text-amber-400"
        ></review-stars-component>
      </div>
      
      <div class="hidden sm:block mx-3 text-gray-300">|</div>
      
      <div class="text-sm italic text-gray-600">
        <span v-if="comment.discovery_channel === 'by_jdj'">
          J'ai connu ce prestataire sur <strong class="text-gray-700">JOURS DE JOIE</strong>
        </span>
        <span v-if="comment.discovery_channel === 'used_service'">
          Nous avons utilisé ce service pour notre mariage
        </span>
        <span v-if="comment.discovery_channel === 'potential_provider'">
          Nous l'envisageons comme prestataire potentiel
        </span>
        <span v-if="comment.discovery_channel === 'guest_in_wedding'">
          J'ai été invité·e à un mariage qui a fait appel à ses services
        </span>
        <span v-if="comment.discovery_channel === 'professional'">
          Je suis un professionnel qui connaît ce prestataire
        </span>
      </div>
    </div>
    
    <!-- Comment Content Section -->
    <div style="font-size: 15px; min-width: vh;"  class="text-gray-700 leading-relaxed pt-1">
      {{ comment.description }}
    </div>
  </div>
</template>

<script>
import {dateFormatter} from "@/views/wedding-provider/utils";
import {mapState} from "vuex";
import {COMPONENT_STAR_TYPES} from "@/views/wedding-provider/types";

export default {
  name: "comment-item-component",
  data: () => ({
    isConfirmed: false
  }),
  props: {
    comment: {
      type: Object,
      required: true,
    }
  },
  computed: {
    ...mapState('auth', {
      user: state => state.user,
    }),
  },
  methods: {
    dateFormatter: (date) => dateFormatter(date),
    onDelete() {
      this.$emit('comment-deleted', this.comment.id);
    },
    onConfirmed() { this.isConfirmed = true },
    onCancel() { this.isConfirmed = false },
    getStars(currentMark) {
      const stars = [];
      let roundMark = 0;
      if (currentMark) {
        roundMark = Number(currentMark);
      }
      for (let i = 1; i <= 5; i++) {
        if (i <= roundMark) {
          stars.push({ type: COMPONENT_STAR_TYPES.FULL });
        } else {
          stars.push({ type: COMPONENT_STAR_TYPES.EMPTY });
        }
      }
      return stars;
    },
  }
}
</script>

