// namespaced: true;

export const state = () => ({
    isLoading: false,
    errors: {},
    errorMessage: {},
    package: null,
    isSuccess: false
})

export const actions = {
    updatePackage({ commit },params) {
        commit('setPackage', params);
    }

}

export const mutations = {
    setLoading(state, value) {
        state.isLoading = value
    },
    setErrors(state, value) {
        state.errors = value
    },
    setErrorMessage(state, value) {
        state.errorMessage = value
    },
    setPackage(state, value) {
        state.package = value
    },
    setSuccess(state, value) {
        
        state.isSuccess = value
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
}