<template>
  <div class="flex w-full background-image" :class="{'h-[20rem]': isDisplaySearchInputs, 'h-[15rem]': !isDisplaySearchInputs}">
    <div class="overlay"></div>
    <div class="flex flex-col py-2 justify-center items-center w-full relative" v-if="isDisplaySearchInputs && !$route.query.type">
      <div class="flex flex-col py-2 items-center text-white">
        <div class="hidden md:flex uppercase">
          SÉLECTION DES MEILLEURS PRESTATAIRES EN Cote d'Ivoire
        </div>
        <div class="flex text-xl text-center font-semibold px-4 pt-8 md:px-0 md:pt-0">
          Trouvez les meilleurs prestataires pour vos évènements heureux
        </div>
      </div>
      <div class="flex flex-col text-lg px-4 rounded-full py-2 w-full md:flex-row md:bg-white md:w-1/2 md:py-0 self-center">
        <div class="w-full py-2 md:border-r md:pr-2 md:w-1/2">
          <div class="relative">
            <span class="absolute inset-y-0 left-0 flex items-center pl-2">
              <i class="fa fa-map-marker text-gray-500"></i>
            </span>
            <input
                @focus="toggleDisplaySearchResult(searchResultTypes.LOCATION)"
                type="search" placeholder="Où ? ex. Cocody" autocomplete="off"
                class="py-2 text-sm w-full rounded-full pl-10 focus:outline-none focus:bg-white focus:text-gray-900"
                v-model="searchQueryLocation"
            />
          </div>
        </div>

        <div class="w-full py-2 md:border-l md:pl-2 md:w-1/2">
          <div class="relative">
            <span class="absolute inset-y-0 left-0 flex items-center pl-2">
              <i class="fa fa-search text-gray-500"></i>
              <!-- <font-awesome-icon icon="fa-search" class="text-gray-500"></font-awesome-icon> -->
            </span>
            <input
                @focus="toggleDisplaySearchResult(searchResultTypes.SERVICE_TYPE)"
                class="py-2 text-sm w-full rounded-full pl-10 focus:outline-none focus:bg-white focus:text-gray-900"
                placeholder="Que recherchez-vous ?" autocomplete="off" type="search"
                v-model="searchQueryServiceType"
            />
          </div>
        </div>
      </div>
      <div class="absolute top-72 md:top-60 left-0 md:left-1/4 z-20 w-full" v-show="isDisplayLocationSearchResult">
        <div class="flex flex-col bg-white w-full md:w-1/2">
          <div class="flex">
            <div class="flex flex-col px-6" v-if="$store.state.storeWedding.towns.isTownLoading || !towns">
              <spinner-component></spinner-component>
            </div>
            <div class="flex flex-col px-2 py-3 w-full" v-else>
              <div class="flex justify-between bg-white">
                <button type="button" @click="toggleDisplaySearchResult(searchResultTypes.LOCATION)" class="flex px-4 pb-3 items-center bg-white text-gray-700 hover:text-red-800">
                  <!-- <font-awesome-icon icon="fa-plus-circle" /> -->
                  <i class="fa fa-plus-circle"></i>
                  &nbsp;
                  Toutes nos communes
                </button>
                <button type="button" @click="toggleDisplaySearchResult(searchResultTypes.LOCATION)" class="flex px-4 pb-3 items-center bg-white text-gray-700 hover:text-red-800">
                  <!-- <font-awesome-icon icon="fa-close" /> -->
                  <i class="fa fa-close"></i>
                </button>
              </div>
              <div class="flex flex-col md:flex-row">
                <div class="flex flex-col px-4 flex-1 bg-white" :class="{'border-l': key !== 0}" v-for="(types, key) in towns" :key="key">
                  <div class="flex flex-col" v-for="(type, index) in types" :key="index">
                    <button
                        type="button"
                        @click="onTownFilterSelected(type)"
                        class="flex py-1.5 text-gray-700 hover:text-red-800 bg-white"
                    >
                      {{ type.name }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="absolute top-72 md:top-60 left-0 md:left-1/4 z-20 w-full" v-show="isDisplayServiceTypeSearchResult">
        <div class="flex flex-col bg-white w-full md:w-1/2">
          <div class="flex">
            <div class="flex flex-col px-2 py-3 w-full" v-if="serviceTypes">
              <div class="flex justify-between">
                <button type="button" @click="toggleDisplaySearchResult(searchResultTypes.SERVICE_TYPE)" class="flex px-4 pb-3 items-center bg-white text-gray-700 hover:text-red-800">
                  <!-- <font-awesome-icon icon="fa-plus-circle" />&nbsp; -->
                  <i class="fa fa-plus-circle"></i> &nbsp;
                  Tous nos types de services
                </button>
                <button type="button" @click="toggleDisplaySearchResult(searchResultTypes.SERVICE_TYPE)" class="flex px-4 pb-3 items-center bg-white text-gray-700 hover:text-red-800">
                  <!-- <font-awesome-icon icon="fa-close" /> -->
                  <i class="fa fa-close"></i>
                </button>
              </div>
              <div class="flex flex-col md:flex-row">
                <div class="flex flex-col px-4 flex-1" :class="{'border-l': key !== 0}" v-for="(types, key) in serviceTypes" :key="key">
                  <div class="flex flex-col" v-for="(type, index) in types" :key="index">
                    <button
                        type="button"
                        @click="onServiceTypeFilterSelected(type)"
                        class="flex py-1.5 text-gray-700 bg-white hover:text-red-800 text-left">
                      {{ type.name }}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex flex-col px-6" v-else>
              <spinner-component></spinner-component>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col py-2 relative text-white px-10" v-else>
      <div class="flex text-xl md:text-3xl font-semibold pt-10" v-if="$route.query.service_type_id || $route.query.town_id || $route.params.id">
        {{ titleRender() }}
      </div>
      <div class="flex py-2 text-sm md:text-base">
        Trouvez les meilleurs prestataires pour vos évènements heureux et demandez-leur des informations sur leur disponibilité et leurs prix.
      </div>
    </div>
  </div>
</template>

<style scoped>
  .background-image {
    background-image: url('@/views/wedding-provider/assets/images/top-background.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
  }
  .overlay {
    width: 100%;
    height:100%;
    top: 0;
    left:0;
    background:rgba(0, 0, 0, 0.4);
    position: absolute;
  }
</style>

<script>
import { arraySlice } from "@/views/wedding-provider/utils";

export default {
  name: 'banner-component',
  props: ['isDisplaySearchInputs'],
  data: () => ({
    isDisplayLocationSearchResult: false,
    isDisplayServiceTypeSearchResult: false,
    searchResultTypes: {
      LOCATION: 'location',
      SERVICE_TYPE: 'serviceType',
      LOCATION_SERVICE_TYPE: 'location_serviceType'
    },
    searchQuery: null,
    serviceTypes: [],
    towns: [],
    searchQueryServiceType: null,
    searchQueryLocation: null,
  }),
  watch: {
    '$store.state.storeWedding.serviceTypes.serviceTypes'(next) {
      if (next) {
        this.serviceTypes = arraySlice(next, 5)
      }
    },
    '$store.state.storeWedding.towns.towns'(next) {
      if (next) {
        this.towns = arraySlice(next, 5)
      }
    },
    searchQueryLocation(next) {
      
      if(next && next.length > 3) {
        const types = this.$store.state.storeWedding.towns.towns.filter(town => town.name.toLowerCase().includes(next.toLowerCase()))
        this.towns = arraySlice(types, 5)

      } else {
        this.towns = arraySlice(this.$store.state.storeWedding.towns.towns, 5)
        
        this.onClearFilters('town')
      }
    },
    searchQueryServiceType(next) {
      if(next && next.length > 3) {
        const types = this.$store.state.storeWedding.serviceTypes.serviceTypes.filter(type => type.name.toLowerCase().includes(next.toLowerCase()))
        this.serviceTypes = arraySlice(types, 5)
      } else {
        this.serviceTypes = arraySlice(this.$store.state.storeWedding.serviceTypes.serviceTypes, 5)
        this.onClearFilters('serviceType')
      }
    }
  },
  mounted() {
    if(!this.$store.state.storeWedding.serviceTypes.serviceTypes) {
      // getServiceTypes Action
      this.$store.dispatch('serviceTypes/getServiceTypes')
    }
    if(!this.$store.state.storeWedding.towns.towns) {
      // getTowns Action
      this.$store.dispatch('towns/getTowns')
    }
  },
  methods: {
    toggleDisplaySearchResult(resultType) {
      
      if (resultType === this.searchResultTypes.LOCATION) {
        this.isDisplayLocationSearchResult = !this.isDisplayLocationSearchResult;
        this.isDisplayServiceTypeSearchResult = false;
        
      }
      if (resultType === this.searchResultTypes.SERVICE_TYPE) {
        this.isDisplayServiceTypeSearchResult = !this.isDisplayServiceTypeSearchResult;
        this.isDisplayLocationSearchResult = false;
      }
    },
    onSearchSubmit() {
      
    },
    onTownFilterSelected(town) {
      this.$emit('town-filter-selected-on-banner', town.id)
      this.isDisplayLocationSearchResult = false
      this.searchQueryLocation = town.name
    },
    onServiceTypeFilterSelected(serviceType) {
      this.$emit('service-type-filter-selected-on-banner', serviceType.id)
      this.isDisplayServiceTypeSearchResult = false
      this.searchQueryServiceType = serviceType.name
    },
    async getTownName() {
      await this.$store.dispatch('towns/getTown', this.$route.query.town_id)
      return `Commune - ${this.$store.state.towns.town}`
    },
    titleRender2() {
      switch (this.$route.query.type) {
        case this.searchResultTypes.LOCATION:
          return this.getTownName()
        case this.searchResultTypes.SERVICE_TYPE:
          return `Type de service - ${this.$route.query.service_type_id}`
        case this.searchResultTypes.LOCATION_SERVICE_TYPE:
          return `Type de service - ${this.$route.query.service_type_id} | Commune - ${this.$route.query.town_id}`
        default:
          return;
      }
    },
    titleRender3() {
      if (!this.$store.state.storeWedding.providers.provider) {
        this.$store.dispatch('providers/getProvider', Number(this.$route.params.id))
      }
      return this.$store.state.storeWedding.providers.provider && this.$store.state.storeWedding.providers.provider.name
    },
    titleRender() {
      if(this.$route.query && this.$route.query.type) {
        return this.titleRender2()
      }
      if(this.$route && this.$route.params && this.$route.params.id) {
        return this.titleRender3()
      }
    },
    onClearFilters(type) {
      if (type) {
        this.$emit('clear-filters-on-banner', type)
      } else {
        this.$emit('clear-filters-on-banner')
      }
    }
  }
}
</script>