<template>
    <div class="w-full max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden">
      <div class="p-6">
        <h2 class="text-xl font-semibold text-center mb-4">{{ title }}</h2>
        
        <!-- Timer display -->
        <div class="flex flex-col items-center mb-6">
          <div class="text-5xl font-mono font-bold mb-2">{{ formattedTime }}</div>
          
          <!-- Progress bar -->
          <div class="w-full h-3 bg-gray-200 rounded-full overflow-hidden">
            <div 
              :class="[progressBarColor, 'h-full transition-all duration-1000 ease-linear']" 
              :style="{ width: `${progressPercentage}%` }"
            ></div>
          </div>
        </div>
        
        <!-- Controls -->
        <div class="flex justify-center space-x-4">
          <!-- <button 
            @click="startTimer" 
            class="p-3 bg-red-500 text-white rounded-full hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 disabled:opacity-50 transition-colors"
            :disabled="isRunning || timeRemaining <= 0"
            aria-label="Start timer"
          >
            <i class="w-5 h-5">▶</i>
          </button> -->
          
          <!-- <button 
            @click="stopTimer" 
            class="p-3 bg-amber-500 text-white rounded-full hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-amber-400 disabled:opacity-50 transition-colors"
            :disabled="!isRunning"
            aria-label="Pause timer"
          >
            <i class="w-5 h-5">⏸</i>
          </button> -->
          
          <!-- <button 
            @click="resetTimer" 
            class="p-3 bg-gray-500 text-white rounded-full hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400 transition-colors"
            aria-label="Reset timer"
          >
            <i class="w-5 h-5">↺</i>
          </button> -->
        </div>
        
        <!-- Time up indicator -->
        <!-- <div v-if="timeRemaining <= 0" class="mt-4 flex items-center justify-center text-red-500">
          <i class="w-5 h-5 mr-2">🔔</i>
          <span>Time's up!</span>
        </div> -->
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'CountdownTimer',
    
    props: {
      initialTime: {
        type: Number,
        default: 120,
        validator: function(value) {
          return value >= 0
        }
      },
      autoStart: {
        type: Boolean,
        default: true
      },
      title: {
        type: String,
        default: 'Temps restant'
      }
    },
    
    data() {
      return {
        timeRemaining: this.initialTime,
        isRunning: false,
        timerInterval: null,
        originalTime: this.initialTime
      }
    },
    
    computed: {
      formattedTime() {
        const minutes = Math.floor(this.timeRemaining / 60)
        const seconds = this.timeRemaining % 60
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
      },
      
      progressPercentage() {
        return (this.timeRemaining / this.originalTime) * 100
      },
      
      progressBarColor() {
        if (this.progressPercentage > 60) return 'bg-red-500'
        if (this.progressPercentage > 30) return 'bg-red-500'
        return 'bg-red-500'
      }
    },
    
    watch: {
      initialTime: {
        handler(newValue) {
          this.originalTime = newValue
          if (!this.isRunning) {
            this.timeRemaining = newValue
          }
        }
      }
    },
    
    methods: {
      startTimer() {
        if (this.isRunning || this.timeRemaining <= 0) return
        
        this.isRunning = true
        this.timerInterval = setInterval(() => {
          if (this.timeRemaining > 0) {
            this.timeRemaining--
          } else {
            this.stopTimer()
            this.$emit('timeUp')
          }
        }, 1000)
      },
      
      stopTimer() {
        this.isRunning = false
        clearInterval(this.timerInterval)
      },
      
      resetTimer() {
        this.stopTimer()
        this.timeRemaining = this.originalTime
      }
    },
    
    mounted() {
      if (this.autoStart) {
        this.startTimer()
      }
    },
    
    beforeDestroy() {
      clearInterval(this.timerInterval)
    }
  }
  </script>