<template>
  <div>
    <Breadcrumb bgClass="bg-image--welcome" titre="BIENVENUE"></Breadcrumb>
    <!-- <div class="page-wrapper"> -->
    <div class="container" style="padding-bottom:20px;">
      <div class="row" style="padding-top:20px;padding-bottom:20px;">
        <div class="col-md-6">
          <img
            src="@/images/old_img/nuptial-notebook/icones.png"
            style="width:30%;"
          />
          <br />
          <p
            style="font-size:14px;font-weight:500;padding-bottom:20px;padding-top:20px;text-align:justify;"
          >
            Nous sommes ravis de vous rencontrer !<br />
            Jours de joie est l’assistant idéal pour organiser sereinement votre
            mariage.<br />
            Lorsque vous créez votre compte sur Jours de joie, vous bénéficiez,
            automatiquement, de la garantie d'un assistant digital qui vous suit
            partout et vous aide dans la planification et l'organisation de
            votre mariage ; <br />
            en vous donnant des conseils et des pistes de qualités.<br />
            L’espace dédié à la planification de votre « jour de joie » est le
            carnet nuptial. Chacune de ses rubriques viendra vous guider et vous
            épauler dans ce long processus. <br /><br />
            La rubrique
            <router-link to="/nuptial-notebook/marrieds"
              >« Le(s) célébré(s) »</router-link
            >
            sert à renseigner toutes les informations relatives aux futurs
            mariés.
          </p>
        </div>
        <div class="col-md-6">
          <img src="@/images/welcome/bloc-1.jpg" alt="" />
        </div>
      </div>
      <br />
      <div class="row" style="padding-top:20px;padding-bottom:20px;">
        <div class="col-md-6">
          <img src="@/images/welcome/bloc-2.jpg" alt="" />
        </div>
        <div class="col-md-6">
          <p style="font-size:14px;font-weight:300;text-align:justify;">
            <router-link to="/nuptial-notebook/summaries"
              >« En bref »</router-link
            >
            vous permet de répondre aux questions pertinentes pour
            l’organisation (le choix des couleurs, des tenues, du traiteur etc).
            <br />
            <router-link to="/nuptial-notebook/restricted-committees"
              >« Comité Restreint »</router-link
            >
            vous permet de renseigner les informations concernant les témoins,
            le bestman et la dame de compagnie. <br />
            Grâce à notre modèle de
            <router-link to="/nuptial-notebook/budgets">« Budget »</router-link
            >, vous pourrez évaluer toutes les dépenses de votre mariage. <br />
            La rubrique
            <router-link to="/nuptial-notebook/plannings"
              >« Planning »</router-link
            >
            comme son nom l’indique vous aide à planifier les actions
            importantes de votre mariage. <br />
            Au niveau de la rubrique
            <router-link to="/nuptial-notebook/dates-places"
              >« Date et lieux »</router-link
            >
            vous renseignez les dates et lieux de votre mariage (coutumier,
            civil et religieux). <br />
            La rubrique
            <router-link to="/nuptial-notebook/guests">« Invités »</router-link>
            vous permet d’ajouter la liste de tous vos invités. <br />
            Enfin, les
            <router-link to="/nuptial-notebook/joy-cards"
              >« Joy Cards »</router-link
            >
            sont des faire-parts digitaux, orignaux et modernes qui vous
            permettront d’informer rapidement et facilement vos invités.<br /><br />

            Plus besoin de stresser, vous pouvez désormais organiser votre
            mariage en toute sérénité.
            Avec sa principale rubrique le carnet nuptial qui comprend les rubriques suivantes :<br>
                            <router-link to="/nuptial-notebook/marrieds">• Fiancés : Ici vous renseignez toutes les informations relatives aux futurs mariés. </router-link><br>
                            <router-link to="/nuptial-notebook/summaries">• En Bref : Vous permet de répondre aux questions pertinentes pour l’organisation de votre mariage. (Le choix des couleurs, des tenues, des traiteurs, salle ou espace.)</router-link><br>
                            <router-link to="/nuptial-notebook/restricted-committees">• Le comité restreint: Vous permet de renseigner les informations concernant les témoins, le bestman et la dame de compagnie.</router-link><br>
                            <router-link to="/nuptial-notebook/budgets">• Budget : Nous vous proposons un modèle de budget qui vous permettra d’évaluer dans les moindres détails les dépenses de votre mariage.</router-link><br>
                            <router-link to="/nuptial-notebook/plannings">• Planning : Vous aide à planifier les actions importantes de votre mariage, étape par étape.</router-link><br>
                            <router-link to="/nuptial-notebook/dates-places">• Dates et lieux: Vous permet de renseigner les dates et lieux de votre mariage (coutumier, civil, religieux)</router-link><br>
                            <router-link to="/nuptial-notebook/guests">• Invités :  Ici vous dressez la liste de vos invités.</router-link><br>
                            <router-link to="/nuptial-notebook/joy-cards">• Joy Cards : Nous vous proposons des faire-parts originaux et modernes à envoyer facilement et à moindre coût à vos invités.</router-link><br>
          </p>
        </div>
      </div>
    </div>
    <div class="backgroundStyleBloc3">
      <div class="container">
        <p class="font-semibold" style="font-size:19px;color:#fff;">
          Inscrivez-vous gratuitement sur www.joursdejoie.com et préparez votre
          mariage comme vous l'avez rêvé. ♥️♥️♥️
        </p>
        <p class="font-semibold" style="font-size:19px;color:#fff;">
          Avec joursdejoie.com, organiser son mariage devient passionnant ! 😍
        </p>
        <div style="padding-top:25px;">
          <button
            v-if="$store.state.token != null && $store.state.token != ''"
            class="default-button"
            @click="goToNuptialNotebook"
          >
            ACCÉDER AU CARNET NUPTIAL
          </button>
          <button v-else class="default-button" @click="goToRegister">
            CRÉER MON COMPTE
          </button>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>

<style scoped>
.backgroundStyleBloc3 {
  background: url(@/images/welcome/bloc-3.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  text-align: center;
  padding-top: 120px;
  padding-bottom: 120px;
}



</style>

<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
export default {
  name: "Welcome",
  metaInfo: {
    title: "Bienvenue",
    titleTemplate: "%s | Jours de Joie",
    htmlAttrs: {
      lang: "fr",
      amp: true
    },
    meta: [
      { charset: "utf-8" },
      {
        name: "keywords",
        content:
          "Organiser facilement votre mariage en Côte d’Ivoire avec Jours de joie:  choix de mairies, définition du budget de mariage en Côte d'Ivoire, carnet nuptial, prestataire de mariage, alliance de mariage"
      },
      {
        name: "description",
        content: "Organisation de mariage en Côte d’Ivoire"
      }
    ]
  },
  components: {
    Breadcrumb
  },
  data: function() {
    return {};
  },
  mounted: function() {
    this.$ua.trackView("Welcome");
  },
  methods: {
    goToRegister: function() {
      // this.$router.push('/register');
      window.location.href = "/inscription";
    },
    goToNuptialNotebook: function() {
      this.$router.push("/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/fiances");
    }
  }
};
</script>
