<template>
  <div >
    <div class="container p-3">
      <big-title label="JOY CARDS" />
      <br /><br />
      <div class="design-maker-container p-3">
        <div class="row">
          <div class="col-md-12">
            <sub-title label="CHOISISSEZ VOTRE DESIGN" gender="F" />
          </div>
        </div>
        <div class="row py-4 px-2 my-4" style="padding: 6px;position: relative; z-index: 1;box-shadow: 10px 12px 71px -28px rgba(176,176,176,0.5);
;" v-if="layouts">
         
          <div class="col-md-7">
            <div class="form-group">
              <div style=" padding: 4px;" class="row text-center">
                <div 
                  class="col-md-3 col-sm-3"
                  v-for="(layout, key) in paginatedLayouts"
                  :key="key"
                >
                  <label :for="key">
                    <img
                      :for="key"
                      :src="base_url + layout.image"
                      class="rounded"
                      alt=""
                    />
                    <input
                      @change="onLayoutsChange(layout)"
                      :value="layout"
                      type="radio"
                      name="layout"
                      :id="key"
                    />&nbsp;
                    <label :for="key" style="font-size: 10px">{{
                      layout.name
                    }}</label>
                  </label>
                </div>
              </div>
            </div>
            <div class="personal-card-footer pb-0 pt-3">
              <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <li class="page-item" :class="{ disabled: currentPage === 1 }">
                    <a class="page-link" href="#" @click.prevent="prevPage">Précédent</a>
                  </li>
                  <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: currentPage === page }">
                    <a class="page-link" href="#" @click.prevent="changePage(page)">{{ page }}</a>
                  </li>
                  <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                    <a class="page-link" href="#" @click.prevent="nextPage">Suivant</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div style="" class="col-md-5" v-if="message && message != null">
            <div
              v-if="message.layout && message.layout != null"
              class="form-group"
            >
              <div
                v-if="
                  message.layout.image == null || message.layout.image == ''
                "
                class="alert alert-info"
              >
                <div class="text-center">Ce design n'a pas de preview.</div>
              </div>
              <div 
              style="box-shadow: #00000030 0px 0px 10px 0px;"
              v-else>
                <img
                  :src="base_url + message.layout.image"
                  style="width: 100%; height: auto"
                  alt=""
                />
              </div>
            </div>
            <div class="form-group" v-else>
              <div class="alert alert-info text-center">
                Aucun design selectionné !
              </div>
            </div>
          </div>

        </div>
        <div class="row" v-else>Chargement en cours ..</div>
      </div>
      <div class="col-md-12 my-4">
            <button
            style="background-color: #fff; color: #000;border:1px solid gray;"
              class="secondary-button pull-left"
              @click="returnToMessageBuilder"
            >
              <i  class="fa fa-chevron-left" style="color: #000;padding-right: 7px;"></i> PRÉCÉDENT
            </button>

            <div v-if="layout != null && message != null">
              <div v-if="message.channel && message.channel != null">
                <button
                
                  class="primary-button pull-right"
                  @click="goToMessageSummary"
                >
                  SUIVANT
                  <i class="fa fa-chevron-right" style="color: #fff; padding-left: 7px;"></i>
                </button>
              </div>
            </div>
          </div>
    </div>
  </div>
</template>

<script>
import { API_PREFIX, API_VERSION, COMMUNICATION } from "@/config";
import axios from "axios";

export default {
  name: "DesignMakerComponent",
  data() {
    return {
      layouts: null,
      base_url: COMMUNICATION.DEV_URL,
      message: this.$store.state.message,
      currentPage: 1,
      itemsPerPage: 10, // Nombre de designs par page
    };
  },
  computed: {
    paginatedLayouts() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.layouts.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.layouts.length / this.itemsPerPage);
    },
  },
  methods: {
    returnToMessageBuilder() {
      this.$emit("return-to-message-builder");
    },
    goToMessageSummary() {
      this.message.targets = [];
      this.message.peopleCount = 0;
      this.message.tab = [];
      this.$store.dispatch("updateMessage", this.message);
      this.$emit("go-to-message-summary");
    },
    getLayouts() {
      let app = this;
      axios
        .get(COMMUNICATION.DEV_URL + API_PREFIX + API_VERSION + "designs", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then(function (response) {
          app.layouts = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    onLayoutsChange(layout) {
      this.layout = layout;
      this.message.layout = layout;
    },
    changePage(page) {
      this.currentPage = page;
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
  },
  mounted() {
    this.getLayouts();
  },
};
</script>

<style scoped>

.pagination li a {
  color: #b71c1c;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination li a {
  color: #b71c1c;
  padding: 5px 10px;
  border: 1px solid #ddd;
  margin: 0 2px;
  text-decoration: none;
}

.pagination li.active a {
  background-color: #b71c1c;
  color: white;
  border-color: #b71c1c;
}

.pagination li.disabled a {
  color: #ddd;
  pointer-events: none;
}

.personal-card-footer{
  padding: .75rem 1.25rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  border-top: 1px solid rgba(0, 0, 0, .125);
}
</style>