<template>
  <div class="bg-container-dates-places">
        <Bar />  
   
    <div class="container p-3" style="padding: 25px;">
      <big-title label="DATES ET LIEUX" />
      <br />
      <!-- <p class="text-center">
        Où et à quelle heure se dérouleront les différentes étapes de votre
        mariage? <br />
        Cette rubrique sert à renseigner la date et le lieu de ces cérémonies
        (coutumier, civil, religieux) Bravo, vous y êtes presque !
      </p> -->
      <p class="text-center">
        De façon générale, la célébration du mariage est une succession de<br />
        cérémonies qui se tiennent les unes après les autres à une date
        précise,<br />
        soit dans une salle, un espace en plein air <br />
        ou encore à un endroit insolite (une île, un bateau etc…).<br />
      </p>

      <div class="text-center">
        <p class="collapse" id="collapseDP">
          Face à ces différents programmes de célébration, il peut arriver aux
          invités de s’embrouiller dans les informations. <br />
          Les mariés eux-même sont vite submergés par toute les informations à
          enregistrer entre les cérémonies de fiançailles, les listes
          d’actions<br />
          de dote et toutes les informations de l’organisation du mariage en
          général. L’information de la date et du lieu restent alors des<br />
          éléments essentiels à sécuriser, noter et à se le rappeler dans
          l’avancement des choses ;<br />
          car tout le reste en dépend. <br />La rubrique “Date et lieux” est la
          fonctionnalité dans laquelle vous viendrez renseigner ces
          informations<br />
          et votre digital Event Planner Jours de Joie prendra le soin de vous
          le rappeler.<br />
          Bravo, et bon courage, vous y êtes presque ! <br /><br />
          <strong>Le saviez-vous :</strong><br />
          Qui met-on à la table d’honneur ? En premier lieu et bien évidemment,
          les mariés. <br />Comme dans le cortège, la mariée s’assied à gauche
          et le marié à droite. <br />Ensuite, on place à côté d’eux les
          personnes que l’on veut mettre à l’honneur.<br />
          Traditionnellement on retrouve donc les témoins et leurs conjoints
          selon l’ordre suivant :<br />
          témoins de la mariée à sa gauche et témoins du marié à sa droite.
          <br />Vous pouvez également placer à la table d’honneur vos frères,
          sœurs et amis proches.
        </p>
        <a
          class="btn btn-link btn-sm"
          style="color: #b71c1c; text-decoration: none;"
          data-toggle="collapse"
          href="#collapseDP"
          role="button"
          aria-expanded="false"
          aria-controls="collapseDP"
          @click="textFullDisplay = !textFullDisplay"
        >
          <strong>{{
            textFullDisplay == false ? "+ VOIR PLUS" : "- VOIR MOINS"
          }}</strong>
        </a>
      </div>
      <br />

      <div v-if="isLoading == true">
        <spinner  /><br /><br />
      </div>
      <div
        class=""
        v-else-if="user.email_verified_at != null && customer.dob != null"
      >
        <div class="dates-places-container p-3">
          <sub-title label="DIFFERENTES CELEBRATIONS :" />
          <div
            v-if="
              isLoading == false &&
                customer != null &&
                columns.length > 0 &&
                rows.length > 0
            "
            class="table-responsive"
          >
            <!-- <mdb-container responsive>
              <mdb-datatable responsive :data="data" hover bordered />
            </mdb-container> -->
            <table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Cérémonie</th>
                  <th scope="col">Date</th>
                  <th scope="col">Heure</th>
                  <th scope="col">Lieu</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(ceremonie,index) in rows" :key="index">
                  <th scope="row">{{ ceremonie.name }}</th>
                  <td>{{ ceremonie.date }}</td>
                  <td>{{ ceremonie.time }}</td>
                  <td>{{ ceremonie.location }}</td>
                  <td v-if="ceremonie.status"><span class="badge badge-success">Prévu</span></td>
                  <td v-else><span class="badge badge-danger">Non prévu</span></td>
                  <td><a :href="'/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/modification-dates-et-lieux-de-mariage/' +
                ceremonie.action.event_id +
                '/' + ceremonie.action.date_place_id " class="btn btn-link"><i class="fa fa-edit"></i></a></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else-if="rows.length == 0 && isLoading == true ">
            <spinner  />
            
          </div>
          <div v-else>
            <div class="col-md-12 alert alert-danger text-center"  >
              Il n'y a pas de parametre pour cet type d'évènement
            </div>
          </div>
        </div>
        <br />
      </div>
      <saved-modal :user="user" @resendMailAccountVerification="resendMailAccountVerification" v-show="showModal" @close-modal="showModal = false" />
      <center>
        <button class="default-button" @click="goToPrevious">PRECEDENT</button
        >&nbsp;
        <button class="default-button" @click="goToNext">SUIVANT</button>
      </center>
    </div>
  </div>
</template>

<script>
import Bar from "@/components/nuptial-notebook/Bar.vue";
import { API_PREFIX, API_VERSION, AUTH, NUPTIAL_NOTEBOOK } from "@/config";
// import { mdbDatatable, mdbContainer } from "mdbvue";
import axios from "axios";
import DataTablesCore from 'datatables.net';
import DataTable from 'datatables.net-vue3';
 
DataTable.use(DataTablesCore);

import store from '@/store';
import { useMeta } from 'vue-meta';
export default {
  name: "DatesPlacesShow",
  setup () {
    useMeta({
      title:
      "Carnet Nuptial - Dates et lieux - Fixer les dates et lieux pour la célébration de votre mariage",
      titleTemplate: "%s | Jours de Joie",
      htmlAttrs: {
        lang: "fr",
        amp: true,
      },
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content: "Comment choisir le lieu de célébration de son mariage",
        },
        {
          name: "keywords",
          content:
            "Mariage en Côte d’Ivoire,trouver les prestataires de mariage, comment choisir le lieu de célébration de son mariage, salle de réception en Côte d’Ivoire, espace événementiel à Abidjan pas cher, organisation de mariage en Côte d’Ivoire, carnet nuptial, outil de planification, wedding planner, inspiration de mariage",
        },
      ],
    })
  },

  components: {
    Bar,
    // mdbDatatable,
    // mdbContainer,
  },
  data() {
    return {
      showModal:false,
      datesPlaces: null,
      isLoading: false,
      user: JSON.parse(store.state.user),
      customer: {dob:null},
      textFullDisplay: false,
      datatest :[
        [1, 2],
        [3, 4],
      ],
      columns: [
        { label: "Cérémonie", field: "name", sort: "desc" },
        { label: "Date", field: "date", sort: "desc" },
        { label: "Heure", field: "time", sort: "desc" },
        { label: "Lieu", field: "location", sort: "desc" },
        {
          label: "Statut",
          field: "status",
          sort: "desc",
          format: (value) =>
            value == 1
              ? '<span class="badge badge-success">Prévu</span>'
              : '<span class="badge badge-danger">Non prévu</span>',
        },
        {
          label: "Action",
          field: "action",
          sort: "desc",
        },
      ],
      rows: [],
    };
  },
  computed: {
    data() {
      return {
        columns: this.columns,
        rows: this.rows,
      };
    },
  },
  mounted() {
    this.getCustomerInfo()
      .then((customer) => {
        this.getDatesPlaces(customer.current_event.id);
        this.getGuestsCount(customer.current_event.id);
      })
      .catch((error) => {
        console.log("Mounted error", error);
      });
    this.show();
  },
  methods: {
    getCustomerInfo: function() {
      return new Promise((resolve, reject) => {
        let app = this;
        app.isLoading = true;
        axios
          .get(
            NUPTIAL_NOTEBOOK.DEV_URL +
              API_PREFIX +
              API_VERSION +
              "customers-by-user/" +
              app.user.id,
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then((response) => {
            this.customer = response.data;
            this.isLoading = false;
            resolve(this.customer);
          })
          .catch((error) => {
            console.log("Error", error);
            this.isLoading = false;
            reject(error);
          });
      });
    },
    getGuestsCount: function(eventId) {
      this.isLoading = true;
      axios
        .get(
          NUPTIAL_NOTEBOOK.DEV_URL +
            API_PREFIX +
            API_VERSION +
            "guests-by-event-get/" +
            eventId,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          this.guestsCount = response.data.length;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error.response.data);
        });
    },
    goToPrevious: function() {
      this.$router.push(
        "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/planning-de-mariage"
      );
    },
    goToNext: function() {
      this.$router.push(
        "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/invites-pour-le-mariage"
      );
    },
    edit: function(eventId, datePlaceId) {
      let app = this;
      app.$router.push(
        "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/modification-dates-et-lieux-de-mariage/" +
          eventId +
          "/" +
          datePlaceId
      );
    },
    getDatesPlaces: function(eventId) {
      this.isLoading = true;
      axios
        .get(
          NUPTIAL_NOTEBOOK.DEV_URL +
            API_PREFIX +
            API_VERSION +
            "dates-places-by-event/" +
            eventId,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data)
          response.data.map((datePlace) =>
            this.rows.push({
              name: datePlace.name,
              date: datePlace.pivot.wedding_date,
              time: datePlace.pivot.wedding_time,
              location:
                datePlace.pivot.wedding_location_prefix +
                " " +
                datePlace.pivot.wedding_location,
              status: Number(datePlace.pivot.is_activate),
              action:datePlace.pivot
            })
          );
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error.response.data);
        });
    },
    resendMailAccountVerification: function(customerEmail) {
      this.isLoading = true;
      axios
        .get(
          AUTH.DEV_URL +
            API_PREFIX +
            API_VERSION +
            "send-email-verification/" +
            customerEmail,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          this.$createToast(
                    {
                    title: 'Operation reussie !',
                    description: "Mail envoyé avec succès !"
                    },
                    {
                    position: 'top-center',
                    type: 'success',
                    timeout: 10000,
                    transition: 'bounce',
                    }
                )
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error.response.data);
          this.$createToast(
                  {
                  title: 'Attention !',
                  description: "Erreur lors de l'envoie de mail !"
                  },
                  {
                  position: 'top-center',
                  type: 'error',
                  timeout: 10000,
                  transition: 'bounce',
                  })
          this.isLoading = false;
        });
    },
    show() {
      if (!this.user.email_verified_at) {
        this.showModal= true
      }
    },
  },
};
</script>

<style scoped>
.dates-places-container {
  -webkit-box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
  -moz-box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
  box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
  background: #fff;
}
.icon {
  color: #fff;
}

.bg-container-dates-places {
  width: 100%;
  /* height: 300px; */
  /* border: 1px solid #000; */
  position: relative;
}

.bg-container-dates-places .content {
  position: absolute;
  z-index: 1;
  text-align: center;
  width: 100%;
  opacity: 1;
}

.bg-container-dates-places::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url("@/images/backgrounds/nuptial-notebook/dates-places.png");
  background-size: cover;
  opacity: 0.1;
}
</style>
