<template>
  <div>
    <div class="container p-3 mt-4">
      <big-title label="JOY CARDS" />

      <div
        style="margin-top: 45px; margin-bottom: 10px; padding: 40px 45px"
        class="target-choice-container"
      >
        <h2 class="recipient-header mb-8">CHOISISSEZ VOS DESTINATAIRES</h2>

        <!-- Total Recipients Info Box -->
        <div style="margin: 30px 0" class="recipient-count-box">
          <p class="recipient-count-text">
            Nombre total de destinataire: {{ tabsend.length }}
          </p>
        </div>

        <div style="padding: 25px 0" class="selection-container items-center">
          <p class="categories-label">Catégories</p>

          <div class="row mb-4 items-center">
            <div class="col-md-6">
              <!-- Category Selection with Multiselect -->
              <multiselect
                v-model="targets"
                :multiple="true"
                :close-on-select="false"
                :group-select="true"
                placeholder="Veuillez selectionner la cible svp"
                select-group-label="Cliquez pour tout selectionner"
                selectedLabel="selectionné"
                selectLabel="Cliquez pour selectionner"
                deselectLabel="Cliquez pour supprimer"
                :options="guests"
                @select="getPeopleCount"
                @remove="handleRemove"
                group-label="name"
                group-values="groupe"
                label="name"
                track-by="id"
                class="category-multiselect"
              ></multiselect>
            </div>
            <div class="col-md-6">
              <!-- Search Bar -->
              <div class="search-container">
                <input
                  type="text"
                  v-model="searchQuery"
                  class="form-control search-input"
                  placeholder="Rechercher un nom..."
                  @input="filterRecipients"
                />
                <i class="fa fa-search search-icon"></i>
              </div>
            </div>
          </div>

          <!-- Category Tabs -->
          <div v-if="targets.length > 0" class="category-tabs">
            <button
              v-for="(target, index) in targets"
              :key="target.id"
              class="category-tab"
              :class="{
                active: currentCategory && currentCategory.id === target.id,
              }"
              @click="switchCategory(target)"
            >
              {{ target.name }}
            </button>
          </div>

          <!-- Recipients List -->
          <div class="recipients-list-container" v-if="targets.length > 0">
            <div style="background-color: #f9fafb" class="recipient-item">
              <label class="checkbox-container">
                <input
                  type="checkbox"
                  :checked="isAllSelected"
                  @click="
                    selectAll(
                      currentCategory ? currentCategory.name : '',
                      false
                    )
                  "
                />
                <span class="checkmark"></span>
                <span class="recipient-name">Tout sélectionner</span>
              </label>
            </div>

            <div
              v-for="(guest, index) in filteredGuests"
              :key="index"
              class="recipient-item"
            >
              <label class="checkbox-container">
                <input
                  type="checkbox"
                  v-model="tabsend"
                  :value="guest.pivot.id"
                  @click="deselectOne(guest.name, guest.pivot.id)"
                />
                <span class="checkmark"></span>
                <span class="recipient-name"
                  >{{ guest.pivot.last_name }}
                  {{ guest.pivot.given_names }}</span
                >
              </label>
            </div>

            <div
              v-if="filteredGuests.length === 0 && !loading"
              class="no-results"
            >
              Aucun résultat trouvé
            </div>

            <div v-if="loading" class="text-center mt-3">
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Chargement...</span>
              </div>
            </div>
          </div>

          <div v-else class="alert alert-warning text-center">
            Aucune catégorie sélectionnée !
          </div>

          <!-- Navigation Buttons -->
          <div class="navigation-buttons">
            <button
              class="btn-previous"
              @click="returnToChannelChoice"
              aria-label="Retour à l'étape précédente"
            >
              <i
                class="fa fa-chevron-left"
                style="color: #000"
                aria-hidden="true"
              ></i>
              PRÉCÉDENT
            </button>

            <button
              class="btn-next"
              @click="goToMessageSummary"
              :disabled="tabsend.length === 0"
              aria-label="Passer à l'étape suivante"
            >
              SUIVANT
              <i
                class="fa fa-chevron-right"
                style="color: #fff"
                aria-hidden="true"
              ></i>
            </button>
          </div>
        </div>
      </div>

      <!-- Modal -->
      <div
        class="modal fade pt--100"
        id="exampleModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body text-center" v-if="nextStep == 0">
              <h5 class="modal-title">Selectionnez vos destinataires</h5>
            </div>
            <div class="modal-body text-center" v-else>
              <h3>
                Votre nombre de {{ message.channel.name }} est insuffisant pour
                envoyer ce message.
              </h3>
              <p>
                Veuillez contacter notre service commercial au (+225) 74 50 16
                02
              </p>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
                @click="ToBeContinue"
              >
                Fermer
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.target-choice-container {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.recipient-header {
  color: #e63946;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 1.5rem;
  position: relative;
  padding-bottom: 0.5rem;
}

.recipient-header::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 400px;
  height: 2px;
  background-color: #e63946;
}

.recipient-count-box {
  background-color: #f1f8ff;
  border-radius: 6px;
  padding: 15px;
  margin-bottom: 20px;
}

.recipient-count-text {
  color: #0066cc;
  font-size: 16px;
  text-align: center;
  margin: 0;
}

.categories-label {
  font-weight: 600;
  margin-bottom: 10px;
}

.search-container {
  position: relative;
}

.search-input {
  width: 100%;
  padding: 10px 15px;
  padding-left: 40px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.search-icon {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #6c757d;
}

/* Category tabs styling */
.category-tabs {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 15px;
}

.category-tab {
  padding: 8px 16px;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s;
}

.category-tab:hover {
  background-color: #e9ecef;
}

.category-tab.active {
  background-color: #0066cc;
  color: white;
  border-color: #0066cc;
}

.recipients-list-container {
  border: 1px solid #e9ecef;
  border-radius: 6px;
  max-height: 400px;
  overflow-y: auto;
  margin-top: 15px;
}

.recipient-item {
  padding: 12px 15px;
  border-bottom: 1px solid #e9ecef;
}

.recipient-item:last-child {
  border-bottom: none;
}

.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 16px;
  user-select: none;
  width: 100%;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #ced4da;
  border-radius: 3px;
  background-color: #fff;
}

.checkbox-container:hover input ~ .checkmark {
  border-color: #0066cc;
}

.checkbox-container input:checked ~ .checkmark {
  background-color: #fff;
  border-color: #0066cc;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-container .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid #0066cc;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.recipient-name {
  font-weight: 400;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.btn-previous {
  background-color: #fff;
  border: 1px solid #ced4da;
  color: #212529;
  padding: 10px 20px;
  border-radius: 4px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s;
}

.btn-previous:hover {
  background-color: #e9ecef;
}

.btn-next {
  background-color: #d00000;
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s;
}

.btn-next:hover:not(:disabled) {
  background-color: #d62f3d;
}

.btn-next:disabled {
  background-color: #f8d7da;
  cursor: not-allowed;
}

.no-results {
  padding: 20px;
  text-align: center;
  color: #6c757d;
}

/* Multiselect custom styling */
.category-multiselect {
  width: 100%;
}

/* Override vue-multiselect default styles to match design */
.multiselect {
  min-height: 44px;
}

.multiselect__tags {
  min-height: 44px;
  padding: 8px 40px 0 8px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.multiselect__select {
  height: 44px;
}

.multiselect__input,
.multiselect__single {
  font-size: 16px;
  padding: 0;
  margin-bottom: 8px;
}

.multiselect__tag {
  background: #0066cc;
  padding: 5px 26px 5px 10px;
  border-radius: 4px;
}

.multiselect__tag-icon:after {
  color: #ffffff;
}

.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: #004c99;
}

.multiselect__option--highlight {
  background: #0066cc;
}

.multiselect__option--selected.multiselect__option--highlight {
  background: #e63946;
}

@media (max-width: 768px) {
  .navigation-buttons {
    flex-direction: column;
    gap: 10px;
  }

  .btn-previous,
  .btn-next {
    width: 100%;
    justify-content: center;
  }

  .category-tabs {
    flex-direction: column;
  }
}
</style>

<script>
import {
  API_PREFIX,
  API_VERSION,
  COMMUNICATION,
  NUPTIAL_NOTEBOOK,
} from "@/config";
import axios from "axios";
import multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";

export default {
  name: "TargetChoiceComponent",
  components: {
    multiselect,
  },
  data: function () {
    return {
      guests: [
        {
          name: "Tout selectionner",
          groupe: [],
        },
      ],
      targets: [],
      targetsContent: [],
      peopleCount: 0,
      customerChannels: null,
      customerChannel: null,
      guestSelects: [],
      tabsend: [],
      tabGuest: [],
      verif: true,
      allSelected: false,
      currentKey: 0,
      loading: false,
      message: this.$store.state.message,
      nextStep: 0,
      searchQuery: "",
      filteredGuests: [],
      currentCategory: null,
      // Store guests by category for quick access
      guestsByCategory: {},
      // Track which categories have been loaded
      loadedCategories: [],
    };
  },
  computed: {
    isAllSelected() {
      if (!this.currentCategory || !this.filteredGuests.length) return false;

      // Get all guests for the current category
      const categoryGuests = this.filteredGuests.filter(
        (guest) => guest.name === this.currentCategory.name
      );

      if (!categoryGuests.length) return false;

      // Check if all guests in the current category are selected
      return categoryGuests.every((guest) =>
        this.tabsend.includes(guest.pivot.id)
      );
    },
  },
  watch: {
    message: function () {
      if (this.message != null && this.message.channel != null) {
        this.getChannels();
      }
    },
    targets: function () {
      if (this.targets.length > 0) {
        // If we don't have a current category or it was removed, set the first one
        if (
          !this.currentCategory ||
          !this.targets.find((t) => t.id === this.currentCategory.id)
        ) {
          this.switchCategory(this.targets[0]);
        } else {
          // Refresh the current category view
          this.filterRecipients();
        }
      } else {
        this.currentCategory = null;
        this.filteredGuests = [];
      }
    },
  },
  mounted: function () {
    this.getPeoples();
  },
  methods: {
    ToBeContinue: function () {
      this.nextStep = 1;
    },
    getPeoples: function () {
      let app = this;
      app.loading = true;
      axios
        .get(NUPTIAL_NOTEBOOK.DEV_URL + API_PREFIX + API_VERSION + "guests", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then(function (response) {
          app.guests[0].groupe = response.data;
          app.loading = false;
        })
        .catch(function (error) {
          console.log(error);
          app.loading = false;
        });
    },
    // Switch to a different category
    switchCategory(category) {
      this.currentCategory = category;
      this.searchQuery = ""; // Reset search when switching categories

      // If we've already loaded this category, just filter the guests
      if (this.loadedCategories.includes(category.id)) {
        this.filterRecipients();
      } else {
        // Otherwise, load the guests for this category
        this.setCurrentKey(0, category.id, category.name);
      }
    },
    // Handle removing a category
    handleRemove(removedCategory) {
      // If the removed category is the current one, switch to another if available
      if (
        this.currentCategory &&
        removedCategory.id === this.currentCategory.id
      ) {
        if (this.targets.length > 0) {
          this.switchCategory(this.targets[0]);
        } else {
          this.currentCategory = null;
          this.filteredGuests = [];
        }
      }

      // Update people count after removing a category
      this.getPeopleCount();
    },
    filterRecipients() {
      if (!this.currentCategory) {
        this.filteredGuests = [];
        return;
      }

      // Get guests for the current category
      const categoryGuests = this.guestSelects.filter(
        (guest) => guest.name === this.currentCategory.name
      );

      if (this.searchQuery.trim() === "") {
        this.filteredGuests = categoryGuests;
      } else {
        const query = this.searchQuery.toLowerCase().trim();
        this.filteredGuests = categoryGuests.filter((guest) => {
          const fullName =
            `${guest.pivot.last_name} ${guest.pivot.given_names}`.toLowerCase();
          return fullName.includes(query);
        });
      }
    },
    returnToChannelChoice() {
      this.$emit("return-to-channel-choice");
    },
    returnToDesignMaker() {
      this.$emit("return-to-design-maker");
    },
    returnToMessageBuilder() {
      this.$emit("return-to-message-builder");
    },
    goToMessageSummary() {
      this.message.targets = this.targets;
      this.message.peopleCount = this.tabsend.length;
      this.message.tab = this.tabsend;
      this.$store.dispatch("updateMessage", this.message);
      this.$emit("go-to-message-summary");
    },
    setCurrentKey(index, id, name) {
      let app = this;
      app.loading = true;
      this.currentKey = index;

      // Only fetch if we haven't already loaded this category
      if (!this.loadedCategories.includes(id)) {
        axios
          .get(
            COMMUNICATION.DEV_URL +
              API_PREFIX +
              API_VERSION +
              "get-single-guest/" +
              id,
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then(function (response) {
            if (response.data.length > 0) {
              if (!app.tabGuest.includes(response.data[0].name)) {
                app.tabGuest.push(name);

                // Store guests by category for quick access
                app.guestsByCategory[id] = response.data;

                response.data.forEach((element) => {
                  var index = app.guestSelects.findIndex(
                    (s) => s.pivot.id == element.pivot.id
                  );
                  if (index === -1) {
                    app.guestSelects.push(element);
                  }
                });
              }
            }

            // Mark this category as loaded
            if (!app.loadedCategories.includes(id)) {
              app.loadedCategories.push(id);
            }

            app.filterRecipients();
            app.loading = false;
          })
          .catch(function (error) {
            app.loading = false;
            console.log(error);
          });
      } else {
        // If already loaded, just filter
        app.filterRecipients();
        app.loading = false;
      }
    },
    getPeopleCount() {
      this.loading = true;
      this.targetsContent = this.targets;
      let targetsIds = [];
      let targetsName = [];
      this.peopleCount = null;

      if (this.targets.length > 0) {
        // If we don't have a current category, set the first one
        if (!this.currentCategory) {
          this.currentCategory = this.targets[0];
        }
      }

      this.targets.forEach((item, index) => {
        // Only load data for categories we haven't loaded yet
        if (!this.loadedCategories.includes(item.id)) {
          this.setCurrentKey(index, item.id, item.name);
        }

        targetsIds.push({
          guestId: item.id,
        });
        targetsName.push(item.name);
      });

      // Clean up removed categories
      for (let index = 0; index < this.tabGuest.length; index++) {
        if (!targetsName.includes(this.tabGuest[index])) {
          const indexChose = this.tabGuest.indexOf(this.tabGuest[index]);
          this.tabGuest.splice(indexChose, 1);
        }
      }

      // Keep selections consistent with selected categories
      if (this.targets.length !== 0) {
        // We only remove selections if their category is no longer selected
        const selectedCategoryNames = this.targets.map((t) => t.name);

        // Create a temporary array to track which IDs to remove
        const idsToRemove = [];

        this.guestSelects.forEach((element) => {
          if (
            this.tabsend.includes(element.pivot.id) &&
            !selectedCategoryNames.includes(element.name)
          ) {
            idsToRemove.push(element.pivot.id);
          }
        });

        // Remove the IDs from tabsend
        this.tabsend = this.tabsend.filter((id) => !idsToRemove.includes(id));
      } else {
        this.tabsend = [];
        this.filteredGuests = [];
      }

      // Update the people count from the API
      if (targetsIds.length > 0) {
        axios
          .post(
            NUPTIAL_NOTEBOOK.DEV_URL +
              API_PREFIX +
              API_VERSION +
              "guests-people-count",
            targetsIds,
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then((response) => {
            this.peopleCount = response.data;
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
          });
      } else {
        this.loading = false;
      }

      // Update filtered guests for the current category
      this.filterRecipients();
    },
    getChannels() {
      let app = this;
      axios
        .get(
          COMMUNICATION.DEV_URL +
            API_PREFIX +
            API_VERSION +
            "customer-channels",
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then(function (response) {
          app.customerChannels = response.data;
          if (app.message) {
            app.customerChannels.forEach((item) => {
              if (item.id == app.message.channel.id) {
                app.customerChannel = item;
              }
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    deselectOne: function (name, id) {
      // This function is called when a checkbox is clicked
      // We don't need to modify tabsend here as v-model handles it

      // Update the status of the category
      this.targets.forEach((element) => {
        if (element.name === name) {
          // Check if all guests in this category are selected
          const categoryGuests = this.guestSelects.filter(
            (g) => g.name === name
          );
          const allSelected = categoryGuests.every((g) =>
            this.tabsend.includes(g.pivot.id)
          );
          element.status = allSelected;
        }
      });

      // Update the message tab
      this.message.tab = this.tabsend;
    },
    selectAll: function (name, check) {
      if (!name && this.currentCategory) {
        name = this.currentCategory.name;
      }

      this.targets.forEach((element) => {
        if (element.name == name) {
          if (check === true) {
            element.status = false;
          }

          // Get all guests for this category
          const categoryGuests = this.guestSelects.filter(
            (guest) => guest.name === name
          );

          if (element.status == false || element.status == undefined) {
            // Select all guests in this category
            categoryGuests.forEach((guest) => {
              if (!this.tabsend.includes(guest.pivot.id)) {
                this.tabsend.push(guest.pivot.id);
              }
            });
            element.status = true;
          } else {
            // Deselect all guests in this category
            categoryGuests.forEach((guest) => {
              const indexChose = this.tabsend.indexOf(guest.pivot.id);
              if (indexChose > -1) {
                this.tabsend.splice(indexChose, 1);
              }
            });
            element.status = false;
          }
        }
      });
    },
  },
};
</script>
