import { NUPTIAL_NOTEBOOK } from "@/config";
import store from "@/store";
import axios from 'axios';

export async function getEvents(params) {
    try {
        const response = await axios.get(`${NUPTIAL_NOTEBOOK.DEV_URL}/api/v1/geteventbycustomer/${JSON.parse(store.state.customer_id)}`, { params });
        console.log(response)
        return response.data;
    } catch (error) {
        console.log("Event error", error);
    }
}

