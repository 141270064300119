import addresses from './addresses'
import auth from './auth'
import comments from './comments'
import events from './events'
import favorites from './favorites'
import medias from './medias'
import providers from './providers'
import quoteRequests from './quoteRequests'
import serviceTypes from './serviceTypes'
import towns from './towns'
import packages from './package'

const storeWedding = {
    modules: {
        namespaced: true,
        providers,
        addresses,
        medias,
        serviceTypes,
        towns,
        comments,
        auth,
        quoteRequests,
        favorites,
        events,
        packages,
        // eventSelect
    }
}
export default storeWedding;

// export default {
//     namespaced: true,
//     providers,
//     addresses,
//     medias,
//     serviceTypes,
//     towns,
//     comments,
//     auth,
//     quoteRequests,
//     favorites,
// }