<template>
  <div>
    <div class="container p-3">
      <big-title label="JOY CARDS" />
      <br />
      <br />
      <div class="channel-choice-container">
        <div class="card-container">
          <div class="header-text">
            <h2>ENVOYER VOTRE IMAGE PAR</h2>
          </div>
          
          <div v-if="remainingMessages !== null" class="message-alert">
            <div v-if="remainingMessages > 0" class="alert alert-success">
              <div class="text-center">Vous disposez de {{ remainingMessages }} messages.</div>
            </div>
            <div v-else-if="remainingMessages == 0" class="alert alert-danger">
              <div class="text-center">Vous disposez de {{ remainingMessages }} messages.</div>
            </div>
            <div v-else-if="remainingMessages < 0 && this.message.customerChannel.sellingPrice > 0" class="alert alert-danger">
              <div class="text-center">Veuillez immédiatement contacter l'administrateur svp au 0709525460 !!!!</div>
            </div>
          </div>
          <div v-else class="alert alert-info">
            <div class="text-center">Veuillez s'il vous plaît choisir le canal d'envoi !</div>
          </div>
          
          <div v-if="channels != null && customerChannels != null" class="channel-cards">
            <div 
              v-for="(channel, key) in channels" 
              :key="key" 
              class="channel-card"
              :class="{ 'selected': channel === this.channel }"
              @click="onChannelChange(channel)"
            >
              <img :src="channel.image" alt="" class="channel-icon" />
              <div class="channel-name">{{ channel.name }}</div>
              <input
                type="radio"
                name="channel"
                :id="'channel'+key"
                :value="channel"
                :checked="channel === this.channel"
                class="channel-radio"
              />
            </div>
          </div>
          <div v-else class="loading">
            <p class="text-center">Chargement en cours ...</p>
          </div>
          
          <div class="navigation-buttons">
            <button
              class="btn-previous"
              @click="returnToMessageSummary"
            >
              <i class="fa fa-chevron-left" style="color: #000; padding-right: 14px;"></i> Précédent
            </button>

            <button
              class="btn-next"
              @click="goToNext" 
              :disabled="channel == null"
            >
              Suivant <i class="fa fa-chevron-right" style="color: #FFF; padding-left: 14px; "></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.channel-choice-container {
  max-width: 1000px;
  margin: 0 auto;
  width: 100%;
}

.card-container {
  background-color: #fff;
  border-radius: 8px;
  padding: 30px;
  box-shadow: 10px 12px 71px -23px rgba(176,176,176,1);margin: 15px 0 45px 0;
}

.header-text {
  text-align: center;
  margin-bottom: 20px;
}

.header-text h2 {
  color: #e30613;
  font-size: 24px;
  font-weight: bold;
}

.message-alert {
  margin: 20px 0;
}

.channel-cards {
  padding: 20px 15px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin: 50px 0;
  width: 100%;
}

.channel-card {
  width: 180px;
  height: 180px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 15px;
  transition: all 0.3s ease;
  flex: 0 0 calc(33.333% - 20px);
  max-width: calc(33.333% - 20px);
}

.channel-card:hover {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.channel-card.selected {
  border: 2px solid #e30613;
  box-shadow: 0px 0px 15px rgba(227, 6, 19, 0.2);
}

.channel-icon {
  width: 80px;
  height: 80px;
  object-fit: contain;
  margin-bottom: 15px;
}

.channel-name {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

.channel-radio {
  position: absolute;
  opacity: 0;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  padding-left: 20px;
  padding-right: 20px;

}

.btn-previous {
  font-size: 17px;
  background-color: #fff;
  color: #000000;
  border: 1px solid #000;
  padding: 10px 25px;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.btn-previous:hover {
  background-color: #f5f5f5;
}

.btn-next {
  font-size: 17px;

  background-color: #d00000;
  color: white;
  border: none;
  padding: 10px 30px;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.btn-next:hover {
  border: 1px solid #c50512;
  background-color: white;
  color: #c50512;
}

.btn-next:disabled {
  background-color: #f5a5a5;
  cursor: not-allowed;
}

.loading {
  text-align: center;
  padding: 20px;
}

/* Media queries for responsiveness */
@media (max-width: 992px) {
  .channel-card {
    flex: 0 0 calc(50% - 20px);
    max-width: calc(50% - 20px);
  }
}

@media (max-width: 576px) {
  .channel-card {
    flex: 0 0 100%;
    max-width: 100%;
  }
  
  .card-container {
    padding: 20px 15px;
  }
}
</style>

<script>
import { API_PREFIX, API_VERSION, COMMUNICATION } from "@/config";
import axios from "axios";

export default {
  name: "ChannelChoiceComponent",
  components: {},
  data: function() {
    return {
      channels: null,
      customerChannels: null,
      channel: null,
      remainingMessages: null,
      message: this.$store.state.message
    };
  },
  mounted: function() {
    this.getChannels().then(() => {
      this.getCustomerChannels();
    });
    
    // If a channel was previously selected, restore it
    if (this.message.channel) {
      this.channel = this.message.channel;
    }
  },
  methods: {
    getChannels: function() {
      return new Promise((resolve, reject) => {
        let app = this;
        axios
          .get(COMMUNICATION.DEV_URL + API_PREFIX + API_VERSION + "channels", {
            headers: {
              Authorization: "Bearer " + this.$store.state.token
            }
          })
          .then(response => {
            if (this.message.file != null) {
              app.channels = response.data.filter((ap) => ap.id != 3);
            } else {
              app.channels = response.data;
            }
            resolve(app.channels);
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
      });
    },
    goToNext: function() {
      if (this.channel) {
        let app = this;
        this.$store.dispatch('updateMessage', app.message);
        app.$emit("go-to-next", app.channel);
      }
    },
    getCustomerChannels() {
      let app = this;
      axios
        .get(
          COMMUNICATION.DEV_URL +
            API_PREFIX +
            API_VERSION +
            "customer-channels",
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token
            }
          }
        )
        .then(function(response) {
          app.customerChannels = response.data;
          
          // If a channel was previously selected, update the remaining messages
          if (app.message.channel && app.message.customerChannel) {
            app.channel = app.message.channel;
            app.remainingMessages = app.message.customerChannel.pivot.remaining_messages;
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    onChannelChange: function(channel) {
      this.channel = channel;
      this.message.channel = channel;
      
      this.customerChannels.forEach(item => {
        if (channel.id == item.id) {
          this.remainingMessages = item.pivot.remaining_messages;
          this.customerChannel = item;
          this.message.customerChannel = this.customerChannel;
        }
      });
    },
    returnToMessageSummary: function() {
      this.$emit("return-to-message-summary");
    }
  }
};
</script>

