<template>
  <div style="width: 100%; background-color: #fff; padding: 0 20px">
    <form
      style="
        margin: 0 auto;
        height: 100vh;
        background-image: url('/images/backgrounds/auth/auth.png');
        background-size: contain;
        z-index: -999;
      "
      novalidate
      class="p-5"
      @submit.prevent="onSubmit"
    >
      <div style="background-color: #fff; z-index: 999" class="pb-3">
        <div class="col-md-12">
          <div style="text-align: center" class="pt-5 pb-4">
            <h3 class="font-weight-light">Connexion</h3>
          </div>
        </div>
        <div class="col-md-12" v-if="error == true">
          <div class="alert alert-danger text-center">
            <strong>Oups...</strong> Adresse email et/ou Mot de passe
            incorrecte(s).
          </div>
        </div>
        <div class="col-md-12" v-if="messageError.status == false">
          <div class="alert alert-danger text-center">
            <strong>Oups...</strong> {{ messageError.message }}
          </div>
        </div>
        <div class="col-md-12">
          <ul
            class="nav nav-pills mb-3 justify-content-center"
            id="pills-tab"
            style="color: gray"
            role="tablist"
          >
            <div
              class="d-flex"
              id="pills-tab"
              role="tablist"
              style="  background-color: #f4f4f5; border-radius:5px"
            >
              <li class="nav-item">
                <a
                  @click="changeLoginType('email')"
                  class="nav-link active"
                  id="pills-home-tab"
                  data-toggle="pill"
                  href="#pills-home"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  <i class="fa fa-envelope" aria-hidden="true"></i>
                  Email</a
                >
              </li>
              <li class="nav-item">
                <a
                  @click="changeLoginType('telephone')"
                  class="nav-link"
                  id="pills-profile-tab"
                  data-toggle="pill"
                  href="#pills-profile"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  <i class="fa fa-mobile " aria-hidden="true"></i>
                  Telephone</a
                >
              </li>
            </div>
          </ul>
          <div class="tab-content" id="pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
            >
              <div class="">
                <div class="form-group">
                  <input
                    type="email"
                    class="auth-input col-12"
                    placeholder="Adresse email"
                    v-model="user.email"
                    name="adresse_email"
                  />
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              <div class="d-flex col-md-12">
                <label for=""></label>
                <multiselect
                  :multiple="false"
                  v-model="codeselected"
                  :close-on-select="true"
                  placeholder="-- Pays --"
                  :options="codes"
                  track-by="name"
                  label="name"
                  class="col-md-6"
                  :show-labels="false"
                  :allow-empty="true"
                >
                  <template>
                    <img :src="option.flag" width="5%" />
                    <span>
                      {{ option.name }}
                    </span>
                  </template>
                </multiselect>
                <div>
                  <masked-input
                    label=""
                    :name="'phone_number_1' + key"
                    type="text"
                    :rules="'min:8|max:10'"
                    v-model="user.phone_number_1"
                    placeholder="No de telephone"
                    :mask="'00 00 00 00 00'"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 pb-3">
          <div class="form-group">
            <input
              :type="showPassword ? 'text' : 'password'"
              class="auth-input col-11"
              placeholder="Mot de passe"
              v-model="user.password"
              required
            />
            <button
              class="col-1 btn btn-sm btn-link"
              type="button"
              @click="passwordInputManagement"
            >
              <i v-if="showPassword" class="fa fa-eye-slash"></i>
              <i v-else class="fa fa-eye"></i>
            </button>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group form-check text-right pb-2">
            <a
              @click="goToResetPassword"
              href="javascript:void(0);"
              style="text-decoration: none"
            >
              <i class="fa fa-lock text-primary" aria-hidden="true"></i>&nbsp;
              <span class="link font-weight-light">Mot de passe oublié ?</span>
            </a>
          </div>
        </div>

        <div class="col-md-12 text-center">
          <div class="form-group">
            <button
              type="submit"
              class="btn btn-primary btn-block"
              style="border-radius: 5px; padding: 10px"
              v-if="isLoading == false"
            >
              CONNEXION
            </button>
            <div class="text-center" v-else>
              <a href="javascript:void(0);" class="btn-social">
                <moonloader :size="'35px'" />
              </a>
            </div>
          </div>
        </div>
        <div class="col-md-12 text-center">
          <a
            href="javascript:void(0);"
            @click="connectFacebook"
            class="btn-social btn-facebook"
            ><i class="fa fa-facebook"></i>
          </a>
        </div>
        <div class="col-md-12 pt-2 text-center">
          Pas encore de compte ?
          <a
            href="javascript:void(0);"
            @click="goToRegister"
            class="text-primary m-l-5"
            ><b>Inscrivez-vous</b></a
          >
        </div>
        <div class="col-md-12 pt-1 text-center" style="font-size: 11px">
          Ou retourner à la
          <a href="javascript:void(0);" @click="$router.push('/')">
            <span class="text-primary" style="font-weight: 900">
              Page d'accueil
            </span>
          </a>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import multiselect from "vue-multiselect";

import { IMaskDirective } from "vue-imask";

import axios from "axios";

export default {
  name: "Login",
  data: function () {
    return {
      user: {
        email: null,
        password: null,
      },
      messageError: {
        status: true,
        message: null,
      },
      error: false,
      isLoading: false,
      status: null,
      social: {
        grant_type: "social",
        client_id: 2,
        client_secret: "jHgvL5xHIGvxU9ltY5dewowAuLTxTnpODbw08AYX",
        provider: "facebook",
        access_token: null,
      },
      loginType: "email",
      showPassword: false,
      codes: [],
      codeselected: {
        name: "Côte d'Ivoire",
        flag: "https://flagcdn.com/ci.svg",
        callingCodes: "225",
      },
    };
  },
  components: {
    //
    multiselect,
  },
  mounted: function () {
    // FB.getLoginStatus(function(response) {
    // });

    this.codeCountries();
  },
  watch: {
    loginType(value) {
      if (value == "email") {
        this.user.phone_number_1 = "";
      }
      if (value == "telephone") {
        this.user.email = "";
      }
    },
  },
  directives: {
    imask: IMaskDirective,
  },
  methods: {
    passwordInputManagement() {
      this.showPassword = !this.showPassword;
    },
    // connectFacebook: function() {
    //   var app = this;
    //   FB.login(
    //     function(response) {

    //       if (response.status == "connected") {
    //         app.loginFacebook(
    //           response.authResponse.accessToken,
    //           response.authResponse.userID
    //         );
    //       }
    //     },
    //     {
    //       scope: "public_profile,email",
    //     }
    //   );
    // },
    codeCountries() {
      let app = this;
      axios
        .get("https://restcountries.com/v3.1/all")
        .then((response) => {
          response.data.forEach((element) => {
            element.name = element.name.common;
            element.flag = element.flags.svg;
            if (element.idd.suffixes) {
              element.callingCodes = element.idd.root + element.idd.suffixes[0];
            } else {
              element.callingCodes = element.idd.root + element.idd;
            }
            // element.callingCodes =element.idd.root+element.idd.suffixes[0]
          });

          app.codes = response.data;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },

    changeLoginType(type) {
      this.loginType = type;
    },

    goToRegister() {
      this.$router.push("/inscription");
    },
    onSubmit() {
      this.isLoading = true;
      if (
        this.loginType === "email" &&
        (!this.user.email || !this.user.email.includes("@"))
      ) {
        this.messageError = {
          status: false,
          message: "Veuillez entrer une adresse email valide",
        };
        this.isLoading = false;
        return;
      }

      if (this.loginType === "telephone" && !this.user.phone_number_1) {
        this.messageError = {
          status: false,
          message: "Veuillez entrer un numéro de téléphone",
        };
        this.isLoading = false;
        return;
      }

      if (this.loginType != "email") {
        this.user.phone_number =
          "+" +
          this.codeselected.callingCodes +
          "" +
          this.user.phone_number_1.replace(/\s/g, "");
        delete this.user.email;
      } else {
        delete this.user.phone_number;
      }
      this.$store
        .dispatch("login", this.user)
        .then((dd) => {
          console.log(dd);
          this.$store
            .dispatch("get_customer_info")
            .then(() => {
              this.isLoading = false;
              this.error = false;
              if (
                document.referrer.indexOf(
                  location.protocol + "//" + location.host
                ) === 0
              ) {
                window.location.href = document.referrer;
              } else {
                window.location.href =
                  "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/fiances";
              }
            })
            .catch(() => {
              this.isLoading = false;
              this.error = true;
            });
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;

          if (error.response) {
            if (error.response.data.success == false) {
              this.error = true;
            }
          } else {
            this.messageError.status = error.success;
            this.messageError.message = error.message;
          }
        });
    },
    goToResetPassword: function () {
      this.$router.push("/mot-de-passe-oublie");
    },
    redirectProvider() {
      window.location.href =
        "https://wedding-provider-space.paraclet-bk.com/connexion";
      // this.$router.replace("/https://wedding-provider-space.paraclet-bk.com/connexion")
    },
  },
};
</script>
<style scoped>
.nav-pills .nav-link.active {
  background-color: rgba(212, 53, 53, 0.9);
  color: #fff;
}

.nav-item {
  padding: 5px;
  background-color: #f4f4f5;
  border-radius: 5px;
}
.nav-item i.active {
  color: #fff;
}


</style>
