import { BASE_URL, requestConfig } from "@/views/wedding-provider/utils";
import axios from 'axios';
import { v4 as uuidv4 } from "uuid";

export async function getQuoteRequestsAPI(params = {}) {
    const config = requestConfig(params);
    try {
        const response = await axios.get(`${BASE_URL}/api/v1/wedding-provider-quote-requests-client`, config);
        return response.data;
    } catch (error) {
        console.log("===> getQuoteRequests error", error);
    }
}

export async function storeQuoteRequestAPI(data, params = {}) {
    const config = requestConfig(params);
    try {
        const response = await axios.post(`${BASE_URL}/api/v1/wedding-provider-quote-requests`, data, config);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export async function uploadFileQuoteAPI(file, quoteId, status, params) {
    const config = requestConfig(params);
    try {
      let params = {
        api_token: "Bearer " + localStorage.getItem("token"),
      };
      config.params = params;
  
      let formData = new FormData();
      formData.append("status", status);
      formData.append("file", new Blob([file], { type: file.type }), file.name);
      formData.append("quoteId", quoteId);
      formData.append("uuid", uuidv4());
  
      let url =
        `${BASE_URL}/api/provider/store-quote?api_token=` + params.api_token;
      console.log("Config ", config, "File", file, "Status", status);
  
      let response = await axios.post(url, formData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        }
      });
  
      return response.data;
    } catch (error) {
      console.log("===> uploadFileQuoteAPI error", error);
      throw error.response;
    }
  }