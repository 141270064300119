<template>
  <div>
    <Bar />
    <div class="container-fluid pr-5 pl-5" style="padding: 20px">
      <big-title label="Mes demandes de prestatations" />

      <br />
      <!-- <p class="text-center">
        Les choses se précisent ! Comme son nom l’indique, la rubrique «
        Planning » vous aide à planifier <br />
        les actions importantes de votre mariage. Mettez à jour votre liste de
        tâches, plus rien ne vous échappera !
      </p> -->
      <p class="text-center">
        Vous êtes bien lancés dans l’organisation de votre mariage, l’un des
        plus beaux jours de votre vie.<br />
        Vous n’avez négligé aucun détail. Vous avez sélectionné avec minutie,
        sur JOY SHOP de joursdejoie.com, les prestataires qui vont rendre réel
        ce rêve. <br />
        Grâce à JOY SHOP de joursdejoie.com, vous les avez contactés des wedding
        planners, les stylistes, des décorateurs, des joailliers, des
        pâtissiers, des lieux de réception, des hôtels, des services traiteurs,
        etc<br />
        Vous leur avez demandé des devis et le détail de leurs services et
        produits. <br />
        Pour ne manquer aucun détail, joursdejoie.com retrace toutes vos
        échanges de l’acceptation du service jusqu’à sa réalisation complète<br />
        Relax, joursdejoie.com s’occupe de tout et organiser son mariage
        devient passionnant.<br />
      </p>
      <br />

      <div class="text-center">
        <div class="collapse" id="collapseP">
          <p>
            A quels prestataires avez-vous décidé de confiez la réalisation de
            votre mariage de rêve ? <br />
            Qui est votre Wedding/Event Planner ? Avec qui allez-vous commander
            vos robes et costumes de mariage ? <br />
            Qui a confectionné les magnifiques alliances que vous porterez à vie
            ? Quel service traiteur égayera les papilles de vos convives ?
            <br />
            Qui exécutera le splendide gâteau de mariage auquel vous avez
            toujours rêvé… <br />
          </p>
          <br />
          <p>
            Avec JOY SHOP de joursdejoie.com, la question ne se pose plus.
            <br />
            Parcourez JOY SHOP, notre annuaire des professionnels des évènements
            heureux et <br />
            sélectionner les prestataires qui répondront au mieux à vos attentes
            (qualité/prix).
          </p>
          <br />
          <p>
            Mettez des cœurs sur vos prestataires favoris et vous verrez leurs
            boutiques s’afficher dans votre carnet nuptial. <br />
            Waouh ! JOY SHOP de joursdejoie.com c’est trop Top...
          </p>
        </div>

        <a
          class="btn btn-link btn-sm"
          style="color: #b71c1c; text-decoration: none"
          data-toggle="collapse"
          href="#collapseP"
          role="button"
          aria-expanded="false"
          aria-controls="collapseP"
          @click="textFullDisplay = !textFullDisplay"
        >
          <strong>{{
            textFullDisplay == false ? "+ VOIR PLUS" : "- VOIR MOINS"
          }}</strong>
        </a>
      </div>
      <div
        style="
          border-top: 2px solid #eaeaea;
          margin-top: 50px;
          margin-bottom: 50px;
          box-shadow: -1px -2px 78px -23px rgba(105, 105, 105, 0.5);
        "
        class="container p-0"
        v-if="!isLoading"
      >
        <div class="row clearfix">
          <div class="col-lg-12 m-0" style="position: relative">
            <div
              style="border-right: 1px solid #eaeaea"
              class="card chat-app row mx-0"
            >
              <div id="plist" class="people-list">
                <ul class="list-unstyled chat-list mt-2 mb-0">
                  <li
                    class="clearfix"
                    v-for="(object, index) in favoritesListe"
                    @click="conversationselect(object, index)"
                    :class="indexSelected == index ? 'active' : null"
                  >
                    <!-- <img src="https://bootdey.com/img/Content/avatar/avatar1.png" alt="avatar"> -->
                    <img
                      :src="
                        'https://ui-avatars.com/api/?name=' +
                        object.provider.name +
                        '&rounded=true&bold=true&background=b71c1c&color=ffffff'
                      "
                    />
                    <div class="about">
                      <div class="name">
                        {{ object.provider.name.slice(0, 13) + "..." }}
                      </div>
                      <div class="status">
                        <i class="fa fa-circle online"></i>
                        {{ sendStatus(object.status) }}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="chat" v-if="conversationselected">
                <div class="chat-header clearfix sticky" id="myHeader">
                  <div class="row">
                    <div class="col-lg-6">
                      <a
                        href="javascript:void(0);"
                        data-toggle="modal"
                        data-target="#view_info"
                      >
                        <img
                          :src="
                            'https://ui-avatars.com/api/?name=' +
                            conversationselected.provider.name +
                            '&rounded=true&bold=true&background=b71c1c&color=ffffff'
                          "
                        />
                      </a>
                      <div class="chat-about">
                        <h6 class="m-b-0">
                          {{ conversationselected.provider.name }}
                        </h6>
                        {{ conversationselected.service }} <br />
                        <small
                          >Date de demande:
                          {{
                            moment(conversationselected.created_at).calendar()
                          }}</small
                        >
                        <!-- {{ moment(conversationselected.created_at).calendar()}} -->
                      </div>
                    </div>
                    <div class="col-lg-6 text-right">
                      <span
                        style="
                          border-radius: 10px;
                          padding: 3px 13px 3px 13px;
                          font-size: 14px;
                          border: 1px solid #c90000;
                          color: #c90000;
                        "
                        class=" "
                        >{{ sendStatus(conversationselected.status) }}</span
                      >
                    </div>
                  </div>
                </div>
                <div
                  class="modal fade"
                  style="margin-top: 15%"
                  id="exampleModal"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                          Voulez vous annuler la commande?
                        </h5>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        En annulant la commande vous arrêtez la conversation...
                      </div>
                      <div class="modal-footer">
                        <button
                          type="button"
                          class="btn btn-secondary"
                          data-dismiss="modal"
                        >
                          Annuler
                        </button>
                        <button
                          type="button"
                          @click="
                            editQuoteStatus(
                              'CANCEL',
                              conversationselected.id,
                              'Prestation annulée'
                            )
                          "
                          class="btn btn-primary"
                          data-dismiss="modal"
                        >
                          Confirmer
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="chat-history">
                  <!-- HTML !-->
                  <!-- <button class="button-30" role="button">Button 30</button> -->

                  <div
                    v-if="conversationselected.package != null"
                    class="text-center mb-5"
                  >
                    <button
                      class="btn btn-danger"
                      @click="goDetail(conversationselected.package.id)"
                    >
                      {{ conversationselected.package.name }}
                    </button>
                  </div>
                  <ul
                    style="margin-top: 50px"
                    class="m-b-0"
                    v-if="!loadMessage"
                  >
                    <li
                      class="clearfix"
                      v-for="(
                        message, index
                      ) in conversationselected.conversations"
                      :key="index"
                    >
                      <div
                        :class="
                          message.user_id == JSON.parse($store.state.user).id
                            ? ' float-right'
                            : ''
                        "
                      >
                        <div
                          v-if="Number(message.is_file)"
                          class="message"
                          :class="
                            message.user_id == JSON.parse($store.state.user).id
                              ? ' other-message'
                              : 'my-message'
                          "
                        >
                          <img
                            :class="{
                              'rounded-t-lg mx-auto h-[15rem]': !isList,
                              'w-full rounded-tl-lg rounded-bl-lg': isList,
                            }"
                            v-if="
                              message.message.includes('jpg') == true ||
                              message.message.includes('png') == true ||
                              message.message.includes('webp') == true
                            "
                            class="h-full w-full object-cover"
                            :src="storageBaseUrl + message.message"
                          />

                          <div
                            style="
                              width: auto;
                              text-align: right;
                              border: 1px solid #0b5ed7;
                              border-radius: 0.375rem;
                            "
                            v-if="
                              message.message.includes('pdf') == true ||
                              message.message.includes('txt') == true ||
                              message.message.includes('docx') == true
                            "
                          >
                            <button
                              type="button"
                              class="btn btn-primary text-white border"
                            >
                              <a
                                :href="storageBaseUrl + message.message"
                                class="text-white"
                              >
                                <i
                                  class="fa fa-download text-white"
                                  aria-hidden="true"
                                ></i>
                                Fichier téléversé
                              </a>
                            </button>
                          </div>
                        </div>
                        <div
                          v-else
                          class="message"
                          :class="
                            message.user_id == JSON.parse($store.state.user).id
                              ? ' other-message'
                              : 'my-message'
                          "
                        >
                          <div style="display: flex; flex-direction: row">
                            <div
                              style="
                                margin-right: 5px;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                color: black;
                              "
                              v-if="
                                message.user_id !=
                                JSON.parse($store.state.user).id
                              "
                            ></div>
                            <div>
                              <span style="font-weight: 600">{{
                                message.user_id !=
                                JSON.parse($store.state.user).id
                                  ? conversationselected.provider.name
                                  : conversationselected.given_names
                              }}</span
                              ><br />
                              <span style="display: inline-block"
                                >{{ message.message }}
                              </span>
                            </div>
                            <div
                              class=""
                              style="
                                margin-left: 5px;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                color: black;
                              "
                              v-if="
                                message.user_id ==
                                JSON.parse($store.state.user).id
                              "
                            >
                              <svg
                                v-if="message.status != 'REFUSED'"
                                style="color: white; margin-left: 25px"
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                fill="currentColor"
                                class="bi bi-check-all"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486z"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                        <br />

                        <div
                          style="
                            display: flex;
                            flex-direction: row;
                            margin-top: 10px;
                          "
                        >
                          <div
                            :class="
                              message.user_id ==
                              JSON.parse($store.state.user).id
                                ? 'text-right'
                                : ''
                            "
                          >
                            <span
                              style="
                                color: #0b5ed7;
                                border-radius: 22px;
                                padding: 3px 13px 3px 13px;
                                font-size: 15px;
                                margin-left: 14px;
                              "
                              class="font-semibold"
                              >{{ sendStatus(message.status) }}</span
                            >
                          </div>
                          <div
                            class="message-data"
                            :class="
                              message.user_id ==
                              JSON.parse($store.state.user).id
                                ? 'text-right'
                                : ''
                            "
                          >
                            <span class="message-data-time"
                              ><small>
                                {{
                                  moment(message.created_at).format(
                                    "DD MMMM YYYY HH:mm"
                                  )
                                }}</small>
                              </span>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div v-else class="text-center">
                    <moonloader :size="'35px'" />
                  </div>
                </div>
                <div
                  class="flex flex-col w-full py-2 px-2"
                  v-if="
                    [
                      'TO_BE_TREATED',
                      'ACCEPTED',
                      'IN_PROGRESS',
                      'QUOTE_SEND',
                      'QUOTE_ACCEPTED',
                    ].includes(conversationselected.status)
                  "
                >
                  <!-- <div class="chat-message clearfix">
                    <div class="input-group mb-0">
                      <textarea
                        v-model="conversationselected.newmessage"
                        class="form-control"
                        placeholder="Votre message ici..."
                        name=""
                        id=""
                        cols="30"
                        rows="2"
                      ></textarea>
                      <button
                        v-if="conversationselected.newmessage"
                        class="flex justify-center items-center text-red-800 py-2 px-2 text-sm"
                        @click="
                          editQuoteRequestStatus(
                            conversationselected.id,
                            conversationselected.status,
                            conversationselected.newmessage
                          )
                        "
                      >
                        Envoyer
                        <i class="fa fa-send"></i>
                      </button>
                      <button
                        v-else
                        disabled
                        class="flex justify-center items-center text-red-800 py-2 px-2 text-sm"
                      >
                        Envoyer
                        <i class="fa fa-send"></i>
                      </button>
                    </div>
                  </div> -->

                  <!-- <div class="text-muted d-flex justify-content-start align-items-center pe-3 pt-3 mt-2">
                  <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava6-bg.webp"
                    alt="avatar 3" style="width: 40px; height: 100%;">
                  <input type="text" class="form-control form-control-lg" id="exampleFormControlInput2"
                    placeholder="Type message">
                  <a class="ms-1 text-muted" href="#!"><i class="fas fa-paperclip"></i></a>
                  <a class="ms-3 text-muted" href="#!"><i class="fas fa-smile"></i></a>
                  <a class="ms-3" href="#!"><i class="fas fa-paper-plane"></i></a>
                </div> -->
                  <div
                    class="d-flex justify-content-around col-md-12"
                    v-if="conversationselected.status == 'QUOTE_SEND'"
                  >
                    <button
                      class="flex justify-end items-center btn btn-danger py-2 text-sm"
                      @click="
                        editQuoteStatus(
                          'QUOTE_REFUSED',
                          conversationselected.id,
                          'Devis refusé'
                        )
                      "
                    >
                      <i class="fa fa-close" style="color: white"></i> &nbsp;
                      Refuser le devis
                    </button>
                    <button
                      class="flex justify-end items-center py-2 btn btn-success text-sm"
                      @click="
                        editQuoteStatus(
                          'QUOTE_ACCEPTED',
                          conversationselected.id,
                          'Devis accepté'
                        )
                      "
                    >
                      &nbsp;
                      <i class="fa fa-check" style="color: white"></i>&nbsp;
                      Accepter devis
                    </button>
                  </div>
                  <div
                    class="d-flex justify-content-around col-md-12 p-4"
                    v-if="
                      [
                        'DONE',
                        'REFUSED',
                        'QUOTE_REFUSED',
                        'DONE_REFUSED',
                        'DONE_ACCEPTED',
                      ].includes(conversationselected.status)
                    "
                  >
                    <div
                      class="d-flex justify-content-around col-md-12"
                      v-if="conversationselected.status == 'DONE'"
                    >
                      <button
                        class="flex justify-end items-center btn btn-danger py-2 text-sm"
                        @click="
                          editQuoteStatus(
                            'DONE_REFUSED',
                            conversationselected.id,
                            'Prestation non executée'
                          )
                        "
                      >
                        <i class="fa fa-close" style="color: white"></i> &nbsp;
                        Prestation non executée
                      </button>
                      <button
                        class="flex justify-end items-center py-2 btn btn-success text-sm"
                        @click="
                          editQuoteStatus(
                            'DONE_ACCEPTED',
                            conversationselected.id,
                            'Je confirme'
                          )
                        "
                      >
                        &nbsp;
                        <i class="fa fa-check" style="color: white"></i>&nbsp;
                        Je confirme
                      </button>
                    </div>
                    <button
                      v-else
                      class="flex justify-end items-center py-2 btn btn-success text-sm"
                      @click="
                        $router.push(
                          '/prestataires-de-mariage-details/' +
                            conversationselected.shop_id +
                            '#noterprestataire'
                        )
                      "
                    >
                      &nbsp;
                      <i class="fa fa-edit" style="color: white"></i>&nbsp;
                      Noter le prestataire
                    </button>
                  </div>
                  <div
                    class="text-muted d-flex justify-content-start align-items-center p-3"
                  >
                    <!-- <img
                      src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava5-bg.webp"
                      alt="avatar 3"
                      style="width: 40px; height: 100%"
                    /> -->
                    <!-- <input
                      type="text"
                      class="form-control form-control"
                      id="exampleFormControlInput3"
                      placeholder="Tapez pour démarrer le chat"
                    /> -->
                    <textarea
                      v-model="conversationselected.newmessage"
                      class="form-control"
                      placeholder="Tapez pour démarrer le chat"
                      name=""
                      id=""
                      cols="30"
                      rows="2"
                    ></textarea>
                    <button
                      @click="importData"
                      class="ms-1 mx-3 text-muted"
                      href="#!"
                    >
                      <i
                        class="fa fa-paperclip fa-xl"
                        style="font-size: 20px"
                      ></i>
                    </button>
                    <!-- <a class="ms-3 text-muted" href="#!"><i class="fa fa-smile"></i></a> -->
                    <button
                      class="ms-3 mx-3 link-info"
                      v-if="conversationselected.newmessage"
                      @click="
                        editQuoteRequestStatus(
                          conversationselected.id,
                          conversationselected.status,
                          conversationselected.newmessage
                        )
                      "
                    >
                      <i
                        class="fa fa-paper-plane fa-xl"
                        style="font-size: 20px"
                      ></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="container">
        <div class="text-center">
          <moonloader :size="'35px'" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Pusher from "pusher-js";

import Bar from "@/components/nuptial-notebook/Bar.vue";
import { BASE_URL, STORAGE_BASE_URL } from "@/views/wedding-provider/utils";
import axios from "axios";
import moment from "moment";
import { mapActions, mapState } from "vuex";

moment.locale();

var pusher = new Pusher("c283b23cd087a7cdbc4f", {
  cluster: "eu",
  app_id: "1000918",
  key: "c283b23cd087a7cdbc4f",
  secret: "ee50e718ff97fe4dc46c",
});

Pusher.logToConsole = true;

export default {
  components: {
    Bar,
  },
  data() {
    return {
      storageBaseUrl: STORAGE_BASE_URL,
      moment: moment,
      favoritesObject: [],
      favoritesValues: [],
      allStatus: [
        { id: "TO_BE_TREATED", order: 1, name: "À traiter", next: "ACCEPTED" },
        { id: "ACCEPTED", order: 2, name: "Accepté", next: "QUOTE_SEND" },
        { id: "REFUSED", order: 6, name: "Devis refuser " },
        {
          id: "QUOTE_SEND",
          order: 3,
          name: "Devis Envoyé",
          next: "IN_PROGRESS",
        },
        {
          id: "QUOTE_ACCEPTED",
          order: 3,
          name: "Devis Accepté",
          next: "IN_PROGRESS",
        },
        { id: "QUOTE_REFUSED", order: "badge-danger", name: "Devis refusé " },
        {
          id: "IN_PROGRESS",
          order: 4,
          name: "En cours de traitement",
          next: "DONE",
        },
        { id: "DONE", order: 5, name: "Prestation exécutée" },
        {
          id: "DONE_REFUSED",
          order: "badge-danger",
          name: "Prestation non executée ",
        },
        {
          id: "DONE_ACCEPTED",
          order: "badge-danger",
          name: "Prestation confirmée ",
        },
        {
          id: "CANCEL",
          order: "badge-danger",
          name: "Prestation annulée ",
        },
      ],
      click: 0,
      textFullDisplay: false,
      oldStatus: [],
      indexSelected: 0,
      conversationselected: null,
      newstatus: null,
      loadMessage: false,
      user: this.$store.state.user,
      isSending: false,
    };
  },
  computed: {
    ...mapState("quoteRequests", {
      isLoading: "isLoading",
      favoritesListe: "quoteRequests",
    }),
  },
  async created() {},
  async mounted() {
    await this.favoritesDisplay();

    let channel_name_client;
    await this.conversationselected.conversations.forEach((message) => {
      if (message.user_id != JSON.parse(this.user).id) {
      } else {
        channel_name_client = "my-channel-" + message.user.email;
      }
    });
    await console.log("Channel name client ", channel_name_client);
    let channel_client = pusher.subscribe(channel_name_client);

    channel_client.bind("my-event", async (data) => {
      console.log(data);
      this.favoritesDisplay();
    });

    setInterval(async () => {
      pusher = new Pusher("c283b23cd087a7cdbc4f", {
        cluster: "eu",
        app_id: "1000918",
        key: "c283b23cd087a7cdbc4f",
        secret: "ee50e718ff97fe4dc46c",
      });

      Pusher.logToConsole = true;

      channel_client = await pusher.subscribe(channel_name_client);

      channel_client.bind("my-event", async (data) => {
        console.log(data);
        this.favoritesDisplay();
      });
    }, "10000");
  },
  // async mounted() {
  //   await this.favoritesDisplay();
  // },
  methods: {
    ...mapActions("quoteRequests", ["getQuoteRequests"], "uploadFileQuote"),
    sendStatus(status) {
      console.log(status);
      let name = null;
      this.allStatus.forEach((element) => {
        if (element.id == status) {
          name = element.name;
        }
      });
      return name;
    },
    importData: async function () {
      let input = document.createElement("input");
      input.type = "file";
      input.onchange = async (_) => {
        // you can use this method to get file and perform respective operations
        let files = Array.from(input.files);
        console.log(files);
        const file = files[0];
        const formData = new FormData();
        formData.append("file", file);
        console.log(formData);
        await this.$store.dispatch("quoteRequests/uploadFileQuote", {
          file: file,
          quoteRequestId: this.conversationselected.id,
          status: this.allStatus.find(
            (status) => status.id === this.conversationselected.status
          ).id,
        });
      };
      input.click();

      const { id } = this.$route.params;
      // if (response.data.success) {
      //   await this.favoritesDisplay();
      // }
      await this.favoritesDisplay();
      // await this.$store.dispatch("quoteRequests/getQuoteRequest", id);
    },
    async subscribePusher() {
      let channel_name_client;
      await this.conversationselected.conversations.forEach((message) => {
        if (message.user_id != JSON.parse(this.user).id) {
        } else {
          channel_name_client = "my-channel-" + message.user.email;
        }
      });
      await console.log("Channel name client ", channel_name_client);
      let channel_client = pusher.subscribe(channel_name_client);

      channel_client.bind("my-event", async (data) => {
        console.log(data);
        this.favoritesDisplay();
      });
    },
    goDetail(PackId) {
      this.$router.push(
        "/description-package-des-prestataires-de-services-en-cote-divoire/" +
          PackId
      );
    },
    async favoritesDisplay() {
      await this.getQuoteRequests();
      console.log(this.favoritesListe);
      this.favoritesListe.forEach((element, index) => {
        if (index == this.indexSelected) {
          this.conversationselected = element;
        }
      });
      // this.subscribePusher();
    },
    async editQuoteRequestStatus(quoteId, status, message) {
      this.isSending = true;
      const response = await axios.put(
        `${BASE_URL}/api/v1/wedding-provider-quote-requests/${quoteId}`,
        {
          quoteRequestId: quoteId,
          status: status,
          message: message,
        }
      );
      console.log(response);
      // if (response.data.success) {
      //   await this.favoritesDisplay();
      // }
      this.isSending = false;
      await this.favoritesDisplay();
    },
    async editQuoteStatus(status, id, message) {
      this.loadMessage = true;
      this.favoritesListe.forEach((element) => {
        if (element.id == id) {
          element.status = status;
          this.newstatus = status;
        }
      });

      this.conversationselected.status = status;
      this.newstatus = status;
      this.loadMessage = false;
      this.editQuoteRequestStatus(id, status, message);
    },
    conversationselect(conversation, index) {
      this.conversationselected = conversation;
      this.indexSelected = index;
    },
  },
};
</script>
<style scoped>
/* CSS */
.button-30 {
  align-items: center;
  appearance: none;
  background-color: #fcfcfd;
  border-radius: 4px;
  border-width: 0;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
  box-sizing: border-box;
  color: #36395a;
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono", monospace;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow 0.15s, transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 18px;
}

.button-30:focus {
  box-shadow: #d6d6e7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
}

.button-30:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
  transform: translateY(-2px);
}

.button-30:active {
  box-shadow: #d6d6e7 0 3px 7px inset;
  transform: translateY(2px);
}

/* CHATS DESIGN */

.card {
  background: #fff;
  transition: 0.5s;
  border: 0;
  margin-right: 0;
  margin-bottom: 30px;
  border-radius: 0.55rem;
  position: relative;
  width: 100%;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
  flex-direction: row;
}

.sticky {
  position: absolute;
  /* top: 0; */
  width: 925px;
  background: #fff;
  z-index: 100;
  border-right: 1px solid #e4e4e4;
  box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%);
}

.chat-app .people-list {
  /* width: 280px; */
  position: relative;
  left: 0;
  top: 0;
  padding: 10px;
  z-index: 7;
  overflow-y: auto;
  height: 600px;
  border-left: 2px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  border-right: 1px solid #e4e4e4;
}

.chat-app .chat {
  /* margin-left: 280px; */
  border-right: 1px solid #eaeaea;
  width: 900px;
  height: 600px;
  overflow-y: auto;
  background: #ffffff;
}

.people-list {
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.people-list .chat-list li {
  padding: 10px 15px;
  list-style: none;
  border-radius: 3px;
  border-bottom: 1px solid #eaeaea;
}

.people-list .chat-list li:hover {
  background: #efefef;
  cursor: pointer;
}

.people-list .chat-list li.active {
  background: #efefef;
}

.people-list .chat-list li .name {
  font-size: 15px;
  text-overflow: ellipsis;
}

.people-list .chat-list img {
  width: 45px;
  border-radius: 50%;
}

.people-list img {
  float: left;
  border-radius: 50%;
}

.people-list .about {
  float: left;
  padding-left: 8px;
}

.people-list .status {
  color: #999;
  font-size: 13px;
}

.chat {
  display: flex;
  flex-direction: column;
}

.chat .chat-header {
  padding: 15px 20px;
  border-bottom: 1px solid #cccccc;
}

.chat .chat-header img {
  float: left;
  border-radius: 40px;
  width: 40px;
}

.chat .chat-header .chat-about {
  float: left;
  padding-left: 10px;
}

.chat .chat-history {
  margin-top: 110px;
}

.chat .chat-history ul {
  padding: 0;
}

.chat .chat-history ul li {
  list-style: none;
  margin-bottom: 30px;
}

.chat .chat-history ul li:last-child {
  margin-bottom: 0px;
}

.chat .chat-history .message-data {
  margin-bottom: 15px;
}

.chat .chat-history .message-data img {
  border-radius: 40px;
  width: 40px;
}

.chat .chat-history .message-data-time {
  color: #000000;
  font-size: 17px;
  padding-left: 16px;
}

.chat .chat-history .message {
  color: #444;
  padding: 18px 20px;
  line-height: 26px;
  font-size: 16px;
  border-radius: 7px;
  display: inline-block;
  position: relative;
  justify-content: space-between;
  width: 100%;
}

/* .chat .chat-history .message:after {
    bottom: 100%;
    left: 7%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: relative;
    pointer-events: none;
    border-bottom-color: #fff;
    border-width: 10px;
    margin-left: -10px
} */

.chat .chat-history .my-message {
  background: #dce8ff;
  color: black;
  border-radius: 10px 10px 10px 10px;
  margin-left: 15px;
  width: 37vh;
}

/* .chat .chat-history .my-message:after {
  bottom: 100%;
  left: 30px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #b71c1c;
  border-width: 10px;
  margin-left: -10px;
} */

.chat .chat-history .other-message {
  background: #c90000;
  color: white;
  text-align: right;
  border-radius: 10px 10px 10px 10px;
  width: 37vh;
  text-align: left;
  border: 1px solid #eaeaea;
}

/* .chat .chat-history .other-message:after {
    border-bottom-color: rgb(248 249 250);
} */

.chat .chat-message {
  padding: 20px;

  /* position: absolute;
  bottom:0px;
  width:100%; */
}

.online,
.offline,
.me {
  margin-right: 2px;
  font-size: 8px;
  vertical-align: middle;
}

.online {
  color: #86c541;
}

.offline {
  color: #e47297;
}

.me {
  color: #1d8ecd;
}

.float-right {
  float: right;
  margin-right: 15px;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

@media only screen and (max-width: 767px) {
  .chat-app .people-list {
    height: 250px;
    width: 100%;

    overflow-x: auto;
    background: #fff;
    overflow-y: auto;
    /* left: -400px; */
    /* display: none */
    display: table-row;
    /* border: #999 1px solid ; */
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 20%);
    border-radius: 0.55rem 0.55rem 0 0;
  }

  .sticky {
    position: absolute;
    /* top: 0; */
    width: 100%;
    background: #fff;
  }

  .chat-app .people-list.open {
    left: 0;
  }

  .chat-app .chat {
    margin: 0;
  }

  .chat-app .chat .chat-header {
    border-radius: 0.55rem 0.55rem 0 0;
  }

  .chat-app .chat-history {
    margin-top: 130px;
    height: 300px;
    overflow-x: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .chat-app .chat-list {
    height: 650px;
    overflow-x: auto;
  }

  .chat-app .chat-history {
    height: 600px;
    overflow-x: auto;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .chat-app .chat-list {
    height: 480px;
    overflow-x: auto;
  }

  .chat-app .chat-history {
    height: calc(100vh - 350px);
    overflow-x: auto;
  }
}
.chat-input-container {
  display: flex;
  align-items: flex-end;
  background-color: #ffffff;
  border-radius: 12px;
  border: 1px solid #e2e8f0;
  padding: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease;
}

.chat-input-container:focus-within {
  border-color: #3b82f6;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.1);
}

.chat-input {
  flex: 1;
  min-height: 80px;
  max-height: 150px;
  padding: 10px;
  border: none;
  border-radius: 8px;
  background-color: #f8fafc;
  resize: none;
  font-family: inherit;
  font-size: 0.95rem;
  line-height: 1.5;
  outline: none;
  transition: background-color 0.2s ease;
}

.chat-input:hover {
  background-color: #f1f5f9;
}

.chat-input:focus {
  background-color: #ffffff;
}

.chat-actions {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-left: 12px;
}

.attachment-button,
.send-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  background: transparent;
  cursor: pointer;
  transition: all 0.2s ease;
}

.attachment-button {
  color: #64748b;
}

.attachment-button:hover {
  background-color: #f1f5f9;
  color: #334155;
}

.send-button {
  color: #3b82f6;
}

.send-button:hover:not(:disabled) {
  background-color: rgba(59, 130, 246, 0.1);
}

.send-button-disabled {
  color: #94a3b8;
  cursor: not-allowed;
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .chat-input-container {
    padding: 8px;
  }

  .chat-input {
    min-height: 60px;
    font-size: 0.9rem;
  }

  .attachment-button,
  .send-button {
    width: 36px;
    height: 36px;
  }
}
</style>
