<template>
  <div>
    <vue-progress-bar></vue-progress-bar>
    <Breadcrumb bgClass="bg-image--contact-1" titre="RSVP"></Breadcrumb>

    <div class="container p-3" style="padding: 25px;">
      <big-title label="RSVP" />
      <br /><br />
      <div v-if="isLoading == false && response != null">
        <div class="card-container p-3">
          <div v-if="response.success == true">
            <div class="alert alert-info">
              <p>
                Votre présence a bien été confirmé !
              </p>
            </div>
          </div>
          <div v-else>
            <div class="alert alert-danger">
              <p>
                Oups.. Un erreur s'est produite lors de la confirmation de votre
                présence. Veuillez réessayer s'il vous plaît.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <spinner size="medium" line-fg-color="#b71c1c" /><br /><br />
      </div>
    </div>
    <NewsletterBox></NewsletterBox>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
import NewsletterBox from "@/components/NewsletterBox.vue";
import {
API_PREFIX,
API_VERSION,
COMMUNICATION
} from "@/config";
import axios from "axios";
import { useMeta } from 'vue-meta';
export default {
  name: "RSVP",
  setup () {
    useMeta({
      title: "Joy Cards, Faire-Parts Numériques de Mariage",
      titleTemplate: "%s | Jours de Joie",
      htmlAttrs: {
        lang: "fr",
        amp: true,
      },
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content: "Commander des faire-parts en Côte d’Ivoire",
        },
        {
          name: "keywords",
          content:
            "Mariage en Côte d’Ivoire, commander des faire-parts en Côte d’Ivoire, prestataire de mariage,organiser son mariage, comment choisir le design de ses faire-parts, envoyer ses faire-parts par mail, faire-parts digital, invitation de mariage",
        },
      ],
    })
  },
  components: {
    NewsletterBox,
    Breadcrumb,
  },
  data() {
    return {
      user: JSON.parse(this.$store.state.user),
      customer: null,
      isLoading: false,
      response: null,
    };
  },
  mounted() {
    this.confirm();
  },
  computed: {
    //
  },
  methods: {
    confirm() {
      this.isLoading = true;
      axios
        .get(
          COMMUNICATION.DEV_URL +
            API_PREFIX +
            API_VERSION +
            "rsvp/" +
            this.$route.params.eventGuestId
        )
        .then((response) => {
          this.response = response.data;
          this.isLoading = false;
          this.$createToast(
          {
          title: 'Attention !',
          description: "Votre présence a bien été confirmé !"
          },
          {
          position: 'top-center',
          type: 'success',
          timeout: 10000,
          transition: 'bounce',
          })
          this.$router.push("/");
        })
        .catch((error) => {
          this.isLoading = false;
        
          this.$createToast(
          {
          title: 'Attention !',
          description: "Un erreur s'est produite lors de la confirmation de votre présence. Veuillez réessayer s'il vous plaît."
          },
          {
          position: 'top-center',
          type: 'error',
          timeout: 10000,
          transition: 'bounce',
          })
        });
    },
  },
};
</script>
