<template>
  <div class="bg-container-account">
    <!-- <vue-progress-bar></vue-progress-bar> -->
    <Breadcrumb bgClass="bg-image--contact-1" titre="MON COMPTE"></Breadcrumb>
    <div class="container p-3" style="padding: 25px; background-color: #fff">
      <big-title label="INFOS DU COMPTE" />
      <br /><br />
      <div v-if="isLoading == false && customer != null">
        <div class="card-container">
          <div class="row">
            <div class="col-md-4">
              <img
                v-if="customer.civility == 'Monsieur'"
                style="width: 100%; height: 100%"
                src="/images/nuptial_notebook/married-2.jpg"
                alt=""
              />
              <img
                v-else
                style="width: 100%; height: 100%"
                src="/images/nuptial_notebook/married-1.jpg"
                alt=""
              />
            </div>
            <div class="col-md-8">
              <div class="col-md-12">
                <div class="row pt-3">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="">Civilité</label>
                      <input
                        type="text"
                        v-model="customer.civility"
                        class="form-control"
                        readonly
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="">Nom</label>
                      <input
                        type="text"
                        v-model="customer.last_name"
                        class="form-control"
                        readonly
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="">Prénoms</label>
                      <input
                        type="text"
                        v-model="customer.given_names"
                        class="form-control"
                        readonly
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="">Email</label>
                      <input
                        type="text"
                        v-model="customer.email"
                        class="form-control"
                        readonly
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="">Contacts</label>
                      <input
                        type="text"
                        :value="
                          customer.phone_number_2
                            ? customer.phone_number_1 +
                              ' / ' +
                              customer.phone_number_2
                            : customer.phone_number_1
                        "
                        class="form-control"
                        readonly
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="">Date de naissance</label>
                      <input
                        type="text"
                        v-model="dobFormatted"
                        class="form-control"
                        readonly
                      />
                    </div>
                  </div>
                </div>
                <center
                  v-if="user.email_verified_at == null || customer.dob == null"
                >
                  <button
                    v-if="customer.dob == null"
                    @click="goToUpdateAccount()"
                    class="secondary-button"
                  >
                    Mettre à jour mon compte
                  </button>
                  <button
                    v-if="
                      user.email_verified_at == null && customer.dob != null
                    "
                    class="primary-button"
                    @click="resendMailAccountVerification(customer.email)"
                  >
                    Activer mon compte</button
                  >&nbsp;
                </center>
                <center v-else>
                  <!-- <button style="background-color:#1877F2;" class="primary-button" @click="editAccount">
                                        <i class="fa fa-facebook" style="color: #fff;"></i> Connecter mon compte Facebook
                                    </button>
                                    <br><br> -->
                  <button class="primary-button" @click="editAccount">
                    <i class="fa fa-edit" style="color: #fff"></i>
                    Modifier</button
                  >&nbsp;
                  <!-- <button class="secondary-button">
                                        <i class="fa fa-trash" style="color: #fff;"></i> Supprimer
                                    </button> -->
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else><spinner /><br /><br /></div>
    </div>
  </div>
</template>

<style>
.bg-container-account {
  width: 100%;
  /* height: 300px; */
  /* border: 1px solid #000; */
  position: relative;
}

.bg-container-account .content {
  position: absolute;
  z-index: 1;
  text-align: center;
  width: 100%;
  opacity: 1;
}

.bg-container-account::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url("@/images/backgrounds/account/account1.png");
  background-size: cover;
  opacity: 0.3;
}
</style>

<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
import { API_PREFIX, API_VERSION, AUTH, NUPTIAL_NOTEBOOK } from "@/config";
import axios from "axios";
import moment from "moment";
import { useMeta } from "vue-meta";
export default {
  name: "AccountCustomerInfoShow",
  setup() {
    useMeta({
      title:
        "Informations de compte, Assistant digital d'organisation de mariage",
      titleTemplate: "%s | Jours de Joie",
      htmlAttrs: {
        lang: "fr",
        amp: true,
      },
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content:
            "Organisation de mariage en Côte d’Ivoire, Robe de mariage, alliance de mariage ",
        },
        {
          name: "keywords",
          content:
            "Agence d’organisation de mariage en Côte d’Ivoire - Agence d’organisation de mariage en Côte d’Ivoire, wedding planner, choix de mairies, définition du budget de mariage en Côte d'Ivoire, carnet nuptial, prestataire de mariage",
        },
      ],
    });
  },

  components: {
    Breadcrumb,
  },
  data: function () {
    return {
      customer: null,
      isLoading: false,
      user: JSON.parse(this.$store.state.user),
    };
  },

  mounted: function () {
    this.getAccount();
  },
  computed: {
    dobFormatted: function () {
      if (this.customer.dob != null) {
        return moment(this.customer.dob).format("DD/MM/YYYY");
      } else {
        return "";
      }
    },

    customer_id() {
      return this.$store.state.customer_id;
    },
  },
  methods: {
    getAccount: function () {
      let app = this;
      this.isLoading = true;
      axios
        .get(
          NUPTIAL_NOTEBOOK.DEV_URL +
            API_PREFIX +
            API_VERSION +
            "customers/" +
            app.$store.state.customer_id,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          this.customer = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error.response.data);
          this.isLoading = false;
        });
    },
    editAccount: function () {
      this.$router.push("/modification-infos-compte-de-mariage-cote-d-ivoire");
    },
    createAddress: function () {
      this.$router.push("/account/address/create");
    },
    editAddress: function (addressId) {
      this.$router.push("/account/address/" + addressId + "/edit");
    },
    resendMailAccountVerification: function (customerEmail) {
      this.isLoading = true;
      axios
        .get(
          AUTH.DEV_URL +
            API_PREFIX +
            API_VERSION +
            "send-email-verification/" +
            customerEmail,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          this.$createToast(
            {
              title: "Operation reussie !",
              description: "Mail envoyé avec succès !",
            },
            {
              position: "top-center",
              type: "success",
              timeout: 10000,
              transition: "bounce",
            }
          );
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.$toasted.error("Erreur lors de l'envoie de mail !");
          this.isLoading = false;
        });
    },
    goToUpdateAccount: function () {
      this.$router.push("/modification-infos-compte-de-mariage-cote-d-ivoire");
    },
  },
};
</script>
