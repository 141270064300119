<template>
    <section class="bg-image--accueil-2 section-gap relative" id="reservation">
        <form class="form-inline" >
            <div class="container">
                <div class="d-flex justify-content-center flex-column flex-md-row align-items-center">  
                    <div class="test col-md-3">
                        <select v-model="countryId" @change="onCountryChange(countryId)" placeholder="-- Selectionnez un pays">
                            <option value="" selected>-- Selectionnez un pays</option>
                            <option v-for="country in countries" :key="country.id" :value="country.id">
                                {{ country.name }}
                            </option>
                        </select>
                    </div>
                    <div class="test col-md-3" style="margin: 28px">
                        <select v-model="districtId" @change="onDistrictChange(districtId)" placeholder="-- Selectionnez un district">
                            <option value="" selected>-- Selectionnez un district</option>
                            <option v-for="district in districts" :key="district.id" :value="district.id">
                                {{ district.name }}
                            </option>
                        </select>
                    </div>
                    <div class="test col-md-3" >
                        <select v-model="townId" @change="getTown(townId)" placeholder="-- Selectionnez une mairie">
                            <option value="" selected>-- Selectionnez une mairie</option>
                            <option v-for="town in towns" :key="town.id" :value="town.id">
                                {{ town.name }}
                            </option>
                        </select>
                    </div><br><br><br>
                </div>
            </div>
        </form> 
    </section>
</template>

<script>
    import { API_PREFIX, API_VERSION, TOWN_HALL } from '@/config';
import { useProgress } from '@marcoschulte/vue3-progress';
import axios from 'axios';
    export default {
        name: 'home',
        data: function() {
            return {
                countries: null,
                districts: null,
                towns: null,
                countryId: 1,
                districtId: 1,
                townId: ''
            }
        },
        mounted: function(){
            this.getAllCountries();
        },
        methods: {
            getAllCountries: function() {
                var self = this;
                var progress = useProgress().start();
                axios.get(TOWN_HALL.DEV_URL+API_PREFIX+API_VERSION + 'countries')
                    .then( function(res){
                        self.countries = res.data.filter(country => country.show == 1);
                        
                        axios.get(TOWN_HALL.DEV_URL+API_PREFIX+API_VERSION + 'countries/' + self.countryId)
                            .then(function(res) {
                                self.districts = res.data.districts.filter(district => district.show == 1);
                                self.onDistrictChange(self.districtId)
                            })
                            .catch(function(error) {
                                console.log('Error', error.response.data);
                            });
                            progress.finish();
                    }).catch(function(error) {
                        console.log('Error', error.response.data);
                    });
            },
            onCountryChange(countryId) {
                var self = this;
                if (countryId) {
                    axios.get(TOWN_HALL.DEV_URL+API_PREFIX+API_VERSION + 'countries/' + countryId)
                    .then(function(res) {
                        self.districts = res.data.districts.filter(district => district.show == 1);
                        self.onDistrictChange(self.districtId)
                    })
                    .catch(function(error) {
                        console.log('Error', error.response.data);
                    });
                }else{
                    self.districtId = "";
                    self.districts = ""
                    self.towns = ""
                }
                
            },
            onDistrictChange(districtId){
                var self = this;
                axios.get(TOWN_HALL.DEV_URL+API_PREFIX+API_VERSION + 'districts/' + districtId)
                .then(function(res) {
                    self.towns = res.data.town_halls.filter(town_hall => town_hall.show == 1);
                }).catch(function(error) {
                    console.log('Error', error.response.data);
                });
            },
            getTown(townId) {
                window.location.href="/details-mairie-pour-organiser-son-mariage-cote-d-ivoire/" + townId;
            }
        },
    }
</script>