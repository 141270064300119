<template>
  <div class="flex flex-col bg-white w-full ">
    <div class="flex w-full ">
      <div class="px-3 py-1">
        <price-item label="Prix Min." :value="provider.min_price"></price-item>
      </div>
      <div class="px-3 py-1">
        <price-item label="Prix Max." :value="provider.max_price"></price-item>
      </div>
    </div>
    <div style="font-size: 14px; text-align:justify ; " class="pt-4 pb-2  text-left" v-html="provider.description">
    </div>
  </div>
</template>

<script>
import PriceItem from "@/views/wedding-provider/components/price-item";

export default {
  name: "description-component",
  components: {PriceItem},
  props: ['provider','description'],
}
</script>

<style scoped>

</style>